import React from "react";
import "./styles.scss";

const Header = React.memo(() => {
  //const [loading, setLoading] = useState(false);
  //const [searchOptions, setSearchOptions] = useState([]);

  return (
    <header className="stores-dashboard-header">
      <h1>Stores</h1>
      <p>Dashboards & Reports</p>
      {/*       <SearchBox
        items={searchOptions}
        disabled={loading}
        onChange={value => console.log(value)}
      /> */}
    </header>
  );
});

export default Header;
