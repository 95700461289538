import {
  SAVE_RESERVATIONS,
  SET_RESERVATIONS_COUNT,
  SET_RESERVATION_STATUS,
  SET_PRESSED_HEADER_TAB,
  SET_WS_MESSAGE_RECEIVED_STATUS,
  SET_PAGE_OFFSET,
  SET_PROPERTY,
} from "./actionTypes";
import { RESERVATIONS_INITIAL_STATE } from "./initialState";

export function reservationsReducer(
  state = RESERVATIONS_INITIAL_STATE,
  action
) {
  switch (action.type) {
    case SAVE_RESERVATIONS:
      return {
        ...state,
        data: action.payload,
      };

    case SET_RESERVATIONS_COUNT:
      const updatedState = JSON.parse(JSON.stringify(state));
      // eslint-disable-next-line
      const { reservationsCount, data } = updatedState;
      reservationsCount[action.payload.status] = action.payload.count;
      return {
        ...updatedState,
        reservationsCount,
      };

    case SET_PAGE_OFFSET:
      const updatedGlobalState = JSON.parse(JSON.stringify(state));
      const { reservationsPageNumber } = updatedGlobalState;
      reservationsPageNumber[action.payload.tab] = action.payload.pageNumber;
      return {
        ...updatedGlobalState,
        reservationsPageNumber,
      };

    case SET_RESERVATION_STATUS:
      return {
        ...state,
        reservationStatus: action.payload.status,
      };
    case SET_PRESSED_HEADER_TAB:
      return { ...state, pressedHeaderTab: action.payload };

    case SET_WS_MESSAGE_RECEIVED_STATUS:
      return {
        ...state,
        isWsMessageReceived: action.payload.status,
      };
    case SET_PROPERTY:
      return { ...state, selectedProperty: action.payload };
    default:
      return state;
  }
}
