import React from "react";
import Loader from "react-loader-spinner";
import "./styles.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export const Spinner = ({ loaderStatus }) => {
  const className = loaderStatus ? "active" : "";
  return (
    <div className={`loader-wrap resend-loader flex-property ${className}`}>
      <Loader type="Oval" color="#030303" height={70} width={70} />
      <div className="margin">{loaderStatus}</div>
    </div>
  );
};
