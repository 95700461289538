export const SERVICES = {
  createImportReservations: {
    apiEndpoint: "reservations/createImportReservations",
  },
};

export const DATATABLE_COLUMNS = [
  {
    name: "Reservation Number",
    selector: (row) => row.reservation_id,
    sortable: true,
  },
  {
    name: "Name",
    selector: (row) => row.guest_name,
    sortable: true,
  },

  {
    name: "Check-In",
    selector: (row) => row.checkin_date,
    sortable: true,
  },
  {
    name: "Check-Out",
    selector: (row) => row.checkout_date,
    sortable: true,
  },
];
