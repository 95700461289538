import { v1 as uuid } from "uuid";
// eslint-disable-next-line
export default [
  {
    id: uuid(),
    visible: true,
    name: "Starbucks",
    discount: "10%",
    position: {
      lat: 34.10542468799239,
      lng: -118.33688503653315,
    },
  },
  {
    id: uuid(),
    visible: true,
    name: "Buffalo Wild Wings",
    discount: "20%",
    position: {
      lat: 34.101266820254196,
      lng: -118.34371208787745,
    },
  },
  {
    id: uuid(),
    visible: true,
    name: "Pink Pepper",
    discount: "7%",
    position: {
      lat: 34.1008546157215,
      lng: -118.34475346703375,
    },
  },
  {
    id: uuid(),
    visible: true,
    name: "SP+ Parking",
    discount: "12%",
    position: {
      lat: 34.10244484280105,
      lng: -118.34269353062467,
    },
  },
  {
    id: uuid(),
    visible: true,
    name: "Ross Dress for Less",
    discount: "25%",
    position: {
      lat: 34.09749597931791,
      lng: -118.34338606209411,
    },
  },
  {
    id: uuid(),
    visible: true,
    name: "ZARA",
    discount: "5%",
    position: {
      lat: 34.101794214453086,
      lng: -118.34045921251294,
    },
  },
  {
    id: uuid(),
    visible: true,
    name: "Rich & Rotten",
    discount: "10%",
    position: {
      lat: 34.102195677373636,
      lng: -118.3318087411108,
    },
  },
  {
    id: uuid(),
    visible: true,
    name: "McDonald's",
    discount: "7.5%",
    position: {
      lat: 34.091976562628666,
      lng: -118.34447519177711,
    },
  },
  {
    id: uuid(),
    visible: true,
    name: "Hollywood Pantages Theatre",
    discount: "2%",
    position: {
      lat: 34.102441528252854,
      lng: -118.32549668249034,
    },
  },
  {
    id: uuid(),
    visible: true,
    name: "Hollywood Wax Museum",
    discount: "17%",
    position: {
      lat: 34.102265636503176,
      lng: -118.33787774054719,
    },
  },
];
