export const SERVICES = {
  createNote: {
    apiEndpoint: "notes/createNote",
  },
  updateNote: {
    apiEndpoint: "notes/updateNote",
  },
  getNotes: {
    apiEndpoint: "notes/getNotes",
  },
  deleteNote: {
    apiEndpoint: "notes/deleteNote",
  },
  createAdminLog: {
    apiEndpoint: "admin/createAdminLog",
  },
};
