import sortUp from "src/app/assets/images/sort-up.svg";

export const RESERVATION_STATUS = {
  Booking: "Booking",
  Confimed: "Confirmed",
  CheckedIn: "CheckedIn",
  CheckedOut: "CheckedOut",
  Canceled: "Canceled",
  NotConfirmed: "Not-confirmed",
  NoShow: "No-show",
};

export const RESERVATIONS_TABS = [
  { label: "Bookings", status: RESERVATION_STATUS.Booking },
  { label: "Arrivals", status: RESERVATION_STATUS.Confimed },
  { label: "Checked-in", status: RESERVATION_STATUS.CheckedIn },
  { label: "Checked-out", status: RESERVATION_STATUS.CheckedOut },
  { label: "Cancelled", status: RESERVATION_STATUS.Canceled },
  { label: "No show", status: RESERVATION_STATUS.NoShow },
];

export const RESERVATIONS_TABLE_COLUMNS_CONFIG = [
  { key: "guest_name", title: "Guest", className: "guest", icon: sortUp },
  {
    key: "reservation_id",
    title: "Booking ID/Nights",
    className: "booking-id",
    icon: sortUp,
  },
  {
    key: "checkin_date",
    title: "Check-in",
    className: "check-time",
    icon: sortUp,
  },
  {
    key: "checkout_date",
    title: "Check-out",
    className: "out-time",
    icon: sortUp,
  },
  {
    key: "status",
    title: "Status",
    className: "status-width",
  },
  {
    key: "room_no",
    title: "Room Number",
    className: "room-width",
    icon: sortUp,
  },
];

export const WEB_CHECKIN_STATUS = {
  bookingConfirmed: "booking confirmed",
  smsDelivered: "Sms Delivered",
  checkinProgress: "checkin progress",
};

export const SERVICES = {
  getReservations: {
    key: "reservations",
    apiEndpoint: "reservations",
  },
  sendKey: {
    apiEndpoint: "reservationKey/resendRoomKey",
  },
  updateAdminCheckInStatus: {
    apiEndpoint: "reservations/updateReservationAdminCheckInStatus",
  },
  expireKey: {
    apiEndpoint: "reservationKey/expireRoomCard",
  },
  sendSms: {
    apiEndpoint: "reservations/sendSMS",
  },
  getCloudBedsRooms: {
    apiEndpoint: "reservations/getCloudBedsRooms",
  },
  createReservation: {
    apiEndpoint: "reservations/createReservation",
  },
  getPropertyDetails: {
    apiEndpoint: "reservations/getPropertyDetails",
  },
};

export const TOAST_MESSAGES = {
  sendKey: {
    success: "SMS sent to ",
    error: "Failed to send SMS.",
  },
  expireKey: {
    success: "Key expired successfully! ",
    error: "Failed to expire key.",
  },
  somethingWentWrong: "Something went wrong. Please try again later.",
  smsSent: {
    success: "SMS sent successfully",
    error: "Failed to send SMS",
  },
  adminProperty: { error: "Sorry, You have not been mapped to any property." },
};

export const LOADING_STATUS = {
  sendingKey: "Sending Key.....",
  updatingStatus: "Key Sent.....",
  refetching: "Fetching reservations....",
  expireKey: "Expiring Key.....",
};

export const CALENDAR_TYPES = {
  from: "FROM",
  to: "TO",
};

export const PAGINATION_COMPONENT_STRINGS = {
  previous: "Previous",
  next: "Next",
  break: "...",
};

export const PAGINATION = {
  limit: 20,
  pageRangeDisplayed: 5,
  marginPagesDisplayed: 2,
};
