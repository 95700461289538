import React from "react";
import "./styles.scss";
import PropTypes from "prop-types";

export const TextField = ({
  label,
  extraStyles,
  type,
  value,
  setValue,
  showError,
  disabled,
  errorMessage,
  placeHolder,
  onFocus,
}) => {
  return (
    <div className={`form-field ${extraStyles}`}>
      <label className="field-title">{label}</label>
      <div className="field-wrap">
        <input
          type={type ? type : "text"}
          className={disabled ? "input-field disabled" : "input-field"}
          value={value}
          onChange={(event) => setValue(event.target.value)}
          min="0"
          placeholder={placeHolder ? placeHolder : ""}
          disabled={disabled}
          onFocus={onFocus}
        />
        {showError && <div className="error-wrap">{errorMessage}</div>}
      </div>
    </div>
  );
};

TextField.propTypes = {
  label: PropTypes.string.isRequired,
  extraStyles: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  setValue: PropTypes.func,
  onFocus: PropTypes.func,
  showError: PropTypes.any,
  disabled: PropTypes.any,
  errorMessage: PropTypes.any,
};
