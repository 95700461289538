import React from "react";
import { NoInternet } from "src/app/components/NoInternet";

export const NetworkDetector = ({ children }) => {
  const [showNetworkToast, setShowNetworkToast] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener("online", () => setShowNetworkToast(false));
    window.addEventListener("offline", () => setShowNetworkToast(true));
    return () => {
      window.removeEventListener("online", () => setShowNetworkToast(false));
      window.removeEventListener("offline", () => setShowNetworkToast(false));
    };
  }, []);

  return (
    <div>
      {children}
      {showNetworkToast && <NoInternet />}
    </div>
  );
};
