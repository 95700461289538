export const SERVICES = {
  createIncidental: {
    apiEndpoint: "incidentals/createIncidental",
  },
  getIncidentals: {
    apiEndpoint: "incidentals/getIncidentals",
  },
  deleteIncidental: {
    apiEndpoint: "incidentals/deleteIncidental",
  },
  getReservationAndIncidentalsDetails: {
    apiEndpoint: "incidentals/getReservationAndIncidentalsDetails",
  },
  getReservationDetails: {
    apiEndpoint: "reservations/getReservationDetails",
  },
  createAdminLog: {
    apiEndpoint: "admin/createAdminLog",
  },
};
