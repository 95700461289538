import React from "react";
import "./styles.scss";
import PropTypes from "prop-types";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input/input";

export const PhoneField = ({
  label,
  extraStyles,
  type,
  value,
  setValue,
  showError,
  disabled,
  errorMessage,
  placeHolder,
  defaultCountry,
}) => {
  return (
    <div className={`form-field ${extraStyles}`}>
      <label className="field-title">{label}</label>
      <div className="field-wrap">
        <PhoneInput
          defaultCountry={defaultCountry ? defaultCountry : "US"}
          type={type ? type : "text"}
          className={disabled ? "input-field disabled" : "input-field"}
          value={value}
          onChange={setValue}
          placeholder={placeHolder ? placeHolder : ""}
          disabled={disabled}
        />
        {showError && <div className="error-wrap">{errorMessage}</div>}
      </div>
    </div>
  );
};

PhoneField.propTypes = {
  label: PropTypes.string.isRequired,
  extraStyles: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func,
  showError: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  defaultCountry: PropTypes.string,
};
