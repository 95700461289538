import { ADMIN_CHECKIN_STATUS_TYPES_TO_GET } from "src/app/common/constants/AppConstants";
import { RESERVATION_STATUS } from "../../../constants";

export const StatusMapper = ({ adminStatus, status, onClick, keyEnabled }) => {
  switch (adminStatus) {
    case ADMIN_CHECKIN_STATUS_TYPES_TO_GET.DOC_VERIFIED:
      if (keyEnabled) {
        return <DocVerified onClick={onClick} />;
      } else {
        return <Completed />;
      }
    case ADMIN_CHECKIN_STATUS_TYPES_TO_GET.KEY_SENT:
      return <SmsDelivered />;
    case ADMIN_CHECKIN_STATUS_TYPES_TO_GET.DOC_UNVERIFIED:
      return <CheckinProgress onClick={onClick} />;
    case ADMIN_CHECKIN_STATUS_TYPES_TO_GET.KEY_EXPIRED:
      if (keyEnabled) {
        return <KeyExpired onClick={onClick} />;
      } else {
        return <Completed />;
      }

    default:
      if (status === RESERVATION_STATUS.NotConfirmed) {
        return <BookingNotConfirmed onClick={onClick} />;
      }
      return <BookingConfirmed onClick={onClick} />;
  }
};

const BookingNotConfirmed = ({ onClick }) => {
  return (
    <div className="status-wrapper">
      <div
        className="status-click-wrap"
        style={{ cursor: "pointer" }}
        onClick={onClick}
      >
        <div className="booking-status"></div>
        <div className="status-check">
          <span className="confirmation">Booking unconfirmed</span>
          <span className="informatory">Waiting confirmation</span>
        </div>
      </div>
    </div>
  );
};

const BookingConfirmed = ({ onClick }) => {
  return (
    <div className="status-wrapper">
      <div
        className="status-click-wrap"
        style={{ cursor: "pointer" }}
        onClick={onClick}
      >
        <div className="booking-status"></div>
        <div className="status-check">
          <span className="confirmation">Booking confirmed</span>
          <span className="informatory">Resend SMS</span>
        </div>
      </div>
    </div>
  );
};

const CheckinProgress = ({ onClick }) => {
  return (
    <div className="status-wrapper">
      <div
        className="status-click-wrap"
        style={{ cursor: "pointer" }}
        onClick={onClick}
      >
        <div className="check-progress"></div>
        <div className="status-check">
          <span className="confirmation progress-text">Check-in Progress</span>
          <span className="informatory">Verify Documents</span>
        </div>
      </div>
    </div>
  );
};

const DocVerified = ({ onClick }) => {
  return (
    <div className="status-wrapper">
      <div
        className="status-click-wrap"
        style={{ cursor: "pointer" }}
        onClick={onClick}
      >
        <div className="sms-delivered"></div>
        <div className="status-check">
          <span className="confirmation delivered-text">
            Documents verified
          </span>
          <span className="informatory">Send key</span>
        </div>
      </div>
    </div>
  );
};

const SmsDelivered = () => {
  return (
    <div className="status-wrapper">
      <div className="status-click-wrap">
        <div className="check-progress"></div>
        <div className="status-check key-sent">
          <span className="confirmation progress-text">Key sent</span>
        </div>
      </div>
    </div>
  );
};

const KeyExpired = ({ onClick }) => {
  return (
    <div className="status-wrapper">
      <div
        className="status-click-wrap"
        style={{ cursor: "pointer" }}
        onClick={onClick}
      >
        <div className="booking-status"></div>
        <div className="status-check">
          <span className="confirmation">Key-expired</span>
          <span className="informatory">Send new key</span>
        </div>
      </div>
    </div>
  );
};

const Completed = () => {
  return (
    <div className="status-wrapper">
      <div className="status-click-wrap">
        <div className="check-progress"></div>
        <div className="status-check">
          <span className="confirmation progress-text">Completed</span>
          <span className="informatory">Check-in completed</span>
        </div>
      </div>
    </div>
  );
};
