import { Auth } from "aws-amplify";
import { getItemFromLocalStorage } from "./localStorageUtils";

export const getJwtToken = async () => {
  try {
    return (await Auth.currentSession()).getIdToken().getJwtToken();
  } catch (error) {}
};

export const getCognitoUserPoolId = async () => {
  try {
    return (await Auth.currentAuthenticatedUser()).username;
  } catch (error) {}
};

export const getCognitoUser = async () => {
  try {
    return await Auth.currentUserInfo();
  } catch (error) {}
};

export const isValidSession = () => {
  try {
    const cache = getItemFromLocalStorage("amplify-signin-with-hostedUI");
    const user = getItemFromLocalStorage(
      `CognitoIdentityServiceProvider.${process.env.REACT_APP_USER_POOL_WEBCLIENT_ID}.LastAuthUser`
    );
    return cache && user;
  } catch (error) {}
};
