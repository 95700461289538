import React from "react";
import { Text, Image, View, StyleSheet } from "@react-pdf/renderer";
import logo from "src/app/assets/images/bright-logo.png";

const styles = StyleSheet.create({
  reportTitle: {
    color: "#3778C2",
    letterSpacing: 4,
    fontSize: 25,
    textAlign: "center",
    textTransform: "uppercase",
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1px solid #000",
    paddingBottom: 20,
  },
  titleContainer: {
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  propertyContainer: {
    flexDirection: "column",
    textAlign: "right",
  },
  titleText: {
    fontSize: 14,
    fontWeight: 600,
  },
  guestName: {
    fontSize: 18,
    fontWeight: 800,
  },
  logo: {
    width: 40,
    height: 40,
    marginLeft: "auto",
    marginRight: "auto",
  },
  contactContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1px solid #000",
    paddingBottom: 20,
    paddingTop: 20,
  },
  reservationContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    borderBottom: "1px solid #000",
    paddingBottom: 20,
    paddingTop: 20,
  },
});

const FolioTitle = ({ reservation }) => {
  return (
    <>
      <View>
        <Image style={styles.logo} src={logo} />
      </View>
      <View style={styles.headerContainer}>
        <View style={styles.titleContainer}>
          <Text style={styles.guestName}>{reservation.guest_name}</Text>
          <Text>{reservation.reservation_id}</Text>
        </View>

        <View style={styles.propertyContainer}>
          <Text style={styles.titleText}>Hollywood Hills Hotel</Text>
          <Text>1999 N Sycamore Ave</Text>
          <Text>Los Angeles California 90068</Text>
          <Text>+1 323 999 1539</Text>
          <Text>info@hollywoodhillshotel.com</Text>
        </View>
      </View>

      <View style={styles.contactContainer}>
        <View style={styles.titleContainer}>
          <Text style={styles.titleText}>Email: {reservation.guest_email}</Text>
          <Text style={styles.titleText}>
            Phone: {reservation.guest_phone_no}
          </Text>
        </View>
      </View>

      <View style={styles.reservationContainer}>
        <View style={styles.titleContainer}>
          <Text style={styles.titleText}>CHECK-IN</Text>
          <Text style={styles.titleText}>{reservation.checkin_date}</Text>
        </View>

        <View style={{ marginLeft: 20 }}>
          <Text style={styles.titleText}>CHECK-OUT</Text>
          <Text style={styles.titleText}>{reservation.checkout_date}</Text>
        </View>

        <View style={{ marginLeft: 20 }}>
          <Text style={styles.titleText}>NIGHTS</Text>
          <Text style={styles.titleText}>{reservation.no_of_nights}</Text>
        </View>

        <View style={{ marginLeft: 20 }}>
          <Text style={styles.titleText}>RESERVATION DATE</Text>
          <Text style={styles.titleText}>{reservation.booking_date}</Text>
        </View>
      </View>
    </>
  );
};

export default FolioTitle;
