export const SERVICES = {
  createAdmin: {
    apiEndpoint: "admin/createAdmin",
  },
  updateAdmin: {
    apiEndpoint: "admin/updateAdmin",
  },
  getAdminByProperty: {
    apiEndpoint: "admin/getAdminByProperty",
  },
  deleteAdmin: {
    apiEndpoint: "admin/deleteAdmin",
  },
  createAdminLog: {
    apiEndpoint: "admin/createAdminLog",
  },
  sendAdminMessage: {
    apiEndpoint: "chat/sendAdminMessage",
  },
  getAdminChat: {
    apiEndpoint: "chat/getAdminChat",
  },
  updateReadAllAdminChatMessages: {
    apiEndpoint: "chat/updateReadAllAdminChatMessages",
  },
};

export const deleteModal = {
  title: "Delete Users",
  message: "Are you sure you want to delete?",
  no: "Cancel",
  yes: "Confirm",
};
