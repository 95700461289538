import AxiosBuilder from "src/app/common/services/AxiosBuilder";
import { getJwtToken } from "src/app/common/utils/sessionUtils";

export const getReservationDataService = async (apiEndpoint, params) => {
  const token = await getJwtToken();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint)
      .withAuth(token)
      .withParams({
        reservationId: params.reservationId,
        propertyId: params.propertyId,
      })
      .GET()
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const chargeReservationService = async (params) => {
  const token = await getJwtToken();
  const apiEndpoint = "stripe/chargeReservation";

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        reservationId: params.reservationId,
        propertyId: params.propertyId,
        stripeToken: params.stripeToken,
        paymentMethodId: params.paymentMethodId,
        cardLastNumber: params.cardLastNumber,
        description: params.description,
        amount: params.amount,
        useDefaultPaymentMethod: params.useDefaultPaymentMethod,
      }),
    })
      .withAuth(token)
      .POST()
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateReservationStatusToConfirmedService = async (
  apiEndpoint,
  params
) => {
  const token = await getJwtToken();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        reservationId: params.reservationId,
        propertyId: params.propertyId

      }),
    })
      .withAuth(token)
      .POST()
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
