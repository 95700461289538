import React, { useState } from "react";
import PropTypes from "prop-types";
import { ORDER_REVERSE_MAP, SORT_ICONS } from "./constants";

export const Column = ({ config, onSort, defaultOrder, setSelectedColumn }) => {
  const [order, setOrder] = useState(ORDER_REVERSE_MAP[defaultOrder]);
  const [icon, setIcon] = useState();

  const handleClickOnSortIcon = () => {
    setOrder(ORDER_REVERSE_MAP[order]);
    setIcon(SORT_ICONS[order]);
    onSort(config.key, order);
  };

  return (
    <th className={config.className}>
      <span
        onClick={() => {
          setSelectedColumn(config.key);
          handleClickOnSortIcon();
        }}
      >
        {config.title}{" "}
        {config.icon && (
          <span className="sorting-icons">
            <img src={SORT_ICONS[defaultOrder] || icon} alt="" />
          </span>
        )}
      </span>
    </th>
  );
};

Column.propTypes = {
  config: PropTypes.object.isRequired,
  onSort: PropTypes.func,
  defaultOrder: PropTypes.string,
  setSelectedColumn: PropTypes.func,
};
