import React from "react";
import "./styles.scss";

export const ExpireIcon = () => {
  return (
    <span className="expire-icon">
      <svg
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Expire Key</title>
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="3-admin"
            transform="translate(-1136.000000, -377.000000)"
            fill="#000000"
            fillRule="nonzero"
          >
            <g id="Expire-Key" transform="translate(1136.000000, 377.000000)">
              <path
                d="M15.0303301,0.969669914 C15.2965966,1.23593648 15.3208027,1.65260016 15.1029482,1.94621165 L15.0303301,2.03033009 L13.6008554,3.46092335 C13.8180394,3.69136271 13.961437,3.99211468 13.993286,4.32541688 L14,4.46666667 L14,6.66666667 L12.5333333,6.66666667 L12.533,4.527 L10.7,6.36 L10.7,7.76666667 L9.294,7.766 L9.233,7.827 L9.23333333,9.23333333 L7.827,9.233 L7.766,9.294 L7.76666667,10.7 L6.36,10.7 L4.527,12.533 L6.66666667,12.5333333 L6.66666667,14 L4.46666667,14 C4.07737158,14 3.72350113,13.8483291 3.46092335,13.6008554 L2.03033009,15.0303301 C1.73743687,15.3232233 1.26256313,15.3232233 0.969669914,15.0303301 C0.703403352,14.7640635 0.6791973,14.3473998 0.897051761,14.0537883 L0.969669914,13.9696699 L3,11.938 L3,10.3333333 L4.46666667,10.3333333 L4.466,10.472 L7.172,7.766 L6.3,7.76666667 L6.3,6.3 L7.76666667,6.3 L7.766,7.172 L10.472,4.466 L10.3333333,4.46666667 L10.3333333,3 L11.938,3 L13.9696699,0.969669914 C14.2625631,0.676776695 14.7374369,0.676776695 15.0303301,0.969669914 Z M14,10.3333333 L14,12.5333333 C14,13.2957029 13.4183317,13.9222165 12.6745831,13.993286 L12.5333333,14 L10.3333333,14 L10.3333333,12.5333333 L12.5333333,12.5333333 L12.5333333,10.3333333 L14,10.3333333 Z M10.7,9.23333333 L10.7,10.7 L9.23333333,10.7 L9.23333333,9.23333333 L10.7,9.23333333 Z M6.66666667,3 L6.66666667,4.46666667 L4.46666667,4.46666667 L4.46666667,6.66666667 L3,6.66666667 L3,4.46666667 C3,3.70429713 3.58166835,3.07778351 4.32541688,3.00671399 L4.46666667,3 L6.66666667,3 Z"
                id="Combined-Shape"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};
