import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import "./styles.scss";
import { DashboardWrapper } from "../Dashboard/Components/DashboardWrapper";
import { isValidSession } from "src/app/common/utils/sessionUtils";

export const PrivateRoute = (props) => {
  const { component } = props;
  const ComponentWithRouterProps = withRouter(component);

  return (
    <React.Fragment>
      {isValidSession() ? (
        <DashboardWrapper>
          <ComponentWithRouterProps params={props.computedMatch.params} />
        </DashboardWrapper>
      ) : (
        <Redirect to="/" />
      )}
    </React.Fragment>
  );
};
