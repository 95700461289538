import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
const moment = require("moment");

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#000",
    borderBottomWidth: 0,
    alignItems: "center",
    height: 16,
    fontStyle: "bold",
  },

  reservationid: {
    textAlign: "left",
    width: "10%",
    fontSize: 10,
  },
  datetime: {
    textAlign: "left",
    width: "10%",
    fontSize: 10,
  },
  name: {
    textAlign: "left",
    width: "20%",
    fontSize: 10,
  },
  room: {
    textAlign: "left",
    width: "10%",
    fontSize: 10,
  },
  type: {
    textAlign: "left",
    width: "30%",
    fontSize: 10,
  },
  quantity: {
    textAlign: "right",
    width: "10%",
    fontSize: 10,
  },
  amount: {
    textAlign: "right",
    width: "10%",
    fontSize: 10,
  },
});

const FolioTableRow = ({ items, reservation }) => {
  const rows = items.map((item, index) => (
    <View style={styles.row} key={index}>
      <Text style={styles.reservationid}>{reservation.reservation_id}</Text>
      <Text style={styles.datetime}>
        {moment(new Date(item.created_at)).utcOffset(0).format("MM/DD/YYYY")}
      </Text>
      <Text style={styles.name}>{reservation.guest_name}</Text>
      <Text style={styles.room}>{reservation.room_no}</Text>
      <Text style={styles.type}>{item.description}</Text>
      <Text style={styles.quantity}>
        {item.hasOwnProperty("quantity") ? item.quantity : ""}
      </Text>
      <Text style={styles.amount}>${item.amount}</Text>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default FolioTableRow;
