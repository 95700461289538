import React, { useEffect } from "react";
import { State, City } from "country-state-city";

const General = ({ onChange, values, errors, onFocus }) => {
  useEffect(() => {
    if (values["state"]) {
      const stateCode = State.getStatesOfCountry("US").find(
        (item) => item.name === values["state"]
      ).isoCode;
      // eslint-disable-next-line
      const newCities = City.getCitiesOfState("US", stateCode);
    }
    // eslint-disable-next-line
  }, [values["state"]]);

  return (
    <div className="property-creation-form-section" style={{ width: "50%" }}>
      <h2>General</h2>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            width: "100%",
            paddingRight: 32,
          }}
        >
          <label
            htmlFor="property_name"
            className="property-creation-form-item"
          >
            <span data-required={true} id="property_name">
              Property name
            </span>
            <input
              type="text"
              id="property_name"
              name="property_name"
              placeholder=""
              onFocus={onFocus}
              onChange={onChange}
              value={values["property_name"]}
            />
            <p data-error={errors["property_name"] ? true : false}>
              {errors["property_name"]}
            </p>
          </label>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "2fr 1fr",
              gridGap: "32px",
            }}
          >
            <label htmlFor="address" className="property-creation-form-item">
              <span data-required={true} id="address">
                Address
              </span>
              <input
                type="text"
                id="address"
                name="address"
                placeholder=""
                onFocus={onFocus}
                onChange={onChange}
                value={values["address"]}
              />
              <p data-error={errors["address"] ? true : false}>
                {errors["address"]}
              </p>
            </label>
            <label htmlFor="city" className="property-creation-form-item">
              <span data-required={true} id="city">
                City
              </span>
              <input
                type="text"
                id="city"
                name="city"
                placeholder=""
                onFocus={onFocus}
                onChange={onChange}
                value={values["city"]}
              />
              <p data-error={errors["city"] ? true : false}>{errors["city"]}</p>
            </label>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gridGap: "32px",
              marginBottom: 8,
            }}
          >
            <label htmlFor="country" className="property-creation-form-item">
              <span data-required={true} id="country">
                Country
              </span>
              <div className="select-cont">
                <select
                  //type="text"
                  id="country"
                  name="country"
                  onFocus={onFocus}
                  onChange={onChange}
                  value={values["country"]}
                >
                  <option selected="selected" value="">
                    - Select -
                  </option>
                  <option value="United States" style={{ color: "#4E4E4E" }}>
                    United States
                  </option>
                  <option value="other" style={{ color: "#4E4E4E" }}>
                    Other
                  </option>
                </select>
              </div>
              <p data-error={errors["country"] ? true : false}>
                {errors["country"]}
              </p>
            </label>
            <label htmlFor="state" className="property-creation-form-item">
              <span data-required={true} id="state">
                State
              </span>
              <div className="select-cont">
                <select
                  //type="text"
                  id="state"
                  name="state"
                  onFocus={onFocus}
                  onChange={onChange}
                  value={values["state"]}
                >
                  <option selected="selected" value="">
                    - Select -
                  </option>
                  {State.getStatesOfCountry("US").map((item) => (
                    <option value={item.name} style={{ color: "#4E4E4E" }}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <p data-error={errors["state"] ? true : false}>
                {errors["state"]}
              </p>
            </label>
            <label htmlFor="zip_code" className="property-creation-form-item">
              <span data-required={true} id="zip_code">
                Zip code
              </span>
              <input
                type="number"
                className="property-creation-input-number"
                id="zip_code"
                name="zip_code"
                placeholder=""
                onFocus={onFocus}
                onChange={onChange}
                value={values["zip_code"]}
              />
              <p data-error={errors["zip_code"] ? true : false}>
                {errors["zip_code"]}
              </p>
            </label>
          </div>
          <div style={{ display: "flex" }}>
            <label
              htmlFor="email"
              className="property-creation-form-item"
              style={{ width: "50%", paddingRight: 16 }}
            >
              <span data-required={true} id="email">
                Email
              </span>
              <input
                type="text"
                id="email"
                name="email"
                placeholder=""
                onFocus={onFocus}
                onChange={onChange}
                value={values["email"]}
              />
              <p data-error={errors["email"] ? true : false}>
                {errors["email"]}
              </p>
            </label>
            <label
              htmlFor="phone"
              className="property-creation-form-item"
              style={{ width: "50%", paddingLeft: 16 }}
            >
              <span data-required={true} id="phone">
                Phone
              </span>
              <input
                type="text"
                id="phone"
                name="phone"
                placeholder=""
                onFocus={onFocus}
                onChange={onChange}
                value={values["phone"]}
              />
              <p data-error={errors["phone"] ? true : false}>
                {errors["phone"]}
              </p>
            </label>
          </div>
          <label
            htmlFor="terms_and_conditions"
            className="property-creation-form-item"
          >
            <span data-required={true} id="terms_and_conditions">
              Terms and conditions
            </span>
            <textarea
              rows={9}
              type="text"
              id="terms_and_conditions"
              name="terms_and_conditions"
              placeholder=""
              onFocus={onFocus}
              onChange={onChange}
              value={values["terms_and_conditions"]}
            />
            <p data-error={errors["terms_and_conditions"] ? true : false}>
              {errors["terms_and_conditions"]}
            </p>
          </label>
        </div>
      </div>
    </div>
  );
};

export default General;
