export const SERVICES = {
  getReservationAndIncidentalsDetails: {
    apiEndpoint: "incidentals/getReservationAndIncidentalsDetails",
  },
  getReservationDetails: {
    apiEndpoint: "reservations/getReservationDetails",
  },
  createAttachment: {
    apiEndpoint: "reservations/createAttachment",
  },
  getAttachments: {
    apiEndpoint: "reservations/getAttachments",
  },
  deleteAttachment: {
    apiEndpoint: "reservations/deleteAttachment",
  },
  shareAttachment: {
    apiEndpoint: "reservations/shareAttachment",
  },
  createAdminLog: {
    apiEndpoint: "admin/createAdminLog",
  },
};
