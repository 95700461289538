import React from "react";
import "./styles.scss";

export const CancelIcon = () => {
  return (
    <span className="cancel-icon">
      <svg
        width="15px"
        height="15px"
        viewBox="0 0 15 15"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>FE0D8071-B74E-45CD-99B5-2F76651A1E71</title>
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="4-admin"
            transform="translate(-954.000000, -830.000000)"
            fill="#858585"
            fillRule="nonzero"
          >
            <g id="Group-19" transform="translate(382.000000, 809.000000)">
              <path
                d="M579.5,21 C575.35625,21 572,24.35625 572,28.5 C572,32.64375 575.35625,36 579.5,36 C583.64375,36 587,32.64375 587,28.5 C587,24.35625 583.64375,21 579.5,21 Z M582.4375,30.2375 C582.76875,30.56875 582.76875,31.10625 582.4375,31.4375 C582.28125,31.59375 582.05625,31.68125 581.8375,31.68125 C581.6125,31.68125 581.39375,31.59375 581.2375,31.4375 L579.5,29.70625 L577.7625,31.44375 C577.60625,31.6 577.38125,31.6875 577.1625,31.6875 C576.9375,31.6875 576.71875,31.6 576.5625,31.44375 C576.23125,31.1125 576.23125,30.575 576.5625,30.24375 L578.3,28.50625 L576.5625,26.76875 C576.23125,26.4375 576.23125,25.9 576.5625,25.56875 C576.89375,25.2375 577.43125,25.2375 577.7625,25.56875 L579.5,27.30625 L581.2375,25.5625 C581.56875,25.23125 582.10625,25.23125 582.4375,25.5625 C582.76875,25.89375 582.76875,26.43125 582.4375,26.7625 L580.7,28.5 L582.4375,30.2375 Z"
                id="Close"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};
