import React, { useState, useEffect } from "react";
import "./styles.scss";
import Header from "./components/Header";
import MonthlySales from "./components/MonthlySales";
import CustomerList from "./components/CustomerList/index";
import { useSelector } from "react-redux";
import { getMonthlySales } from "./service";
import Loader from "react-loader-spinner";
import { Auth } from "aws-amplify";

const Dashboard = () => {
  const {
    reservations: { selectedProperty },
  } = useSelector((state) => state);
  const property_id = selectedProperty?.value;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  const getUser = async () => {
    const user = await Auth.currentUserInfo();
    if (user && user.hasOwnProperty("id")) {
      setUser(user);
    } /*else {
      handleLogout();
    }*/
  };

  useEffect(() => {
    getUser();
    return () => {};
  }, []);

  useEffect(() => {
    if (property_id) {
      getMonthlySales(
        property_id,
        setLoading,
        setData,
        user && user.attributes["custom:role"] === "root"
      );
    }
  }, [property_id, user]);

  return loading ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "80vh",
        width: "100%",
      }}
    >
      <Loader type="Oval" color="#030303" height={50} width={50} />
    </div>
  ) : (
    <div className="right-wrapper">
      <Header />
      <MonthlySales monthlySales={data?.monthlySales || []} />
      <CustomerList customersHistory={data?.customersHistory || []} />
    </div>
  );
};

export default Dashboard;
