import React from "react";

const Cloudbeds = ({ onChange, values, errors, onFocus, required }) => (
  <div
    style={{
      width: "100%",
    }}
  >
    <div
      style={{
        width: "100%",
        marginBottom: 32,
      }}
    >
      <label
        htmlFor="cloudbeds"
        className="property-creation-form-item"
        style={{ marginBottom: 0 }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type={"checkbox"}
            checked={values["cloudbeds"]}
            id="cloudbeds"
            name="cloudbeds"
            onFocus={onFocus}
            onChange={onChange}
          />
          <span style={{ margin: 8 }}>Cloudbeds</span>
        </div>
      </label>
    </div>
    {values["cloudbeds"] && (
      <React.Fragment>
        <div>
          <label
            htmlFor="api_user_key"
            className="property-creation-form-item"
            data-required={required}
            id="api_user_key"
          >
            <span data-required={required} id="terms_and_conditions">
              Api user key
            </span>
            <input
              type="text"
              id="api_user_key"
              name="api_user_key"
              onFocus={onFocus}
              onChange={onChange}
              value={values["api_user_key"]}
            />
            <p data-error={errors["api_user_key"] ? true : false}>
              {errors["api_user_key"]}
            </p>
          </label>
        </div>
        <div>
          <label
            htmlFor="api_user_pwd"
            className="property-creation-form-item"
            data-required={required}
            id="api_user_pwd"
          >
            <span data-required={required} id="terms_and_conditions">
              Api user pwd
            </span>
            <input
              type="text"
              id="api_user_pwd"
              name="api_user_pwd"
              onFocus={onFocus}
              onChange={onChange}
              value={values["api_user_pwd"]}
            />
            <p data-error={errors["api_user_pwd"] ? true : false}>
              {errors["api_user_pwd"]}
            </p>
          </label>
        </div>
        <div>
          <label
            htmlFor="building_no"
            className="property-creation-form-item"
            data-required={required}
            id="building_no"
          >
            <span data-required={required} id="terms_and_conditions">
              Building no
            </span>
            <input
              type="text"
              id="building_no"
              name="building_no"
              onFocus={onFocus}
              onChange={onChange}
              value={values["building_no"]}
              style={{ width: "25%" }}
            />
            <p data-error={errors["building_no"] ? true : false}>
              {errors["building_no"]}
            </p>
          </label>
        </div>
      </React.Fragment>
    )}
  </div>
);

export default Cloudbeds;
