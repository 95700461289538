import React from "react";
import { AppButton } from "src/app/components/AppButton";

const Welcome = ({ onSection }) => {
  return (
    <React.Fragment>
      <span
        style={{
          width: "300px",
          textAlign: "center",
          fontSize: "16px",
          lineHeight: "21px",
          fontWeight: "400",
        }}
      >
        Get more guests using GoorooBooster to advertise your business.
      </span>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <a href="https://app.gooroobooster.com" target="_blank" rel="noopener noreferrer">
          <AppButton label={"Login"} onClick={() => { }} />
        </a>
        <span style={{ margin: "0 1rem" }}>OR</span>
        <a href="https://gooroobooster.com/signup" target="_blank" rel="noopener noreferrer">
          <AppButton label={"sign up"} onClick={() => { }} />
        </a>
      </div>
    </React.Fragment>
  )
};

export default Welcome;
