export const setItemIntoLocalStoarge = (key, value) => {
	try {
		localStorage.setItem(key, value);
	} catch (err) {}
};

export const getItemFromLocalStorage = (key) => {
	try {
		return localStorage.getItem(key);
	} catch (err) {}
};

export const removeItemFromLocalStorage = (key) => {
	try {
		return localStorage.removeItem(key);
	} catch (err) {}
};
