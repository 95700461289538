import React from "react";
import "./styles.scss";

export const WarningIcon = () => {
  return (
    <span className="warning-icon">
      <svg
        width="12px"
        height="11px"
        viewBox="0 0 12 11"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>611A9118-C4DA-426B-88A3-8C2300418A49</title>
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="5.1-admin"
            transform="translate(-682.000000, -206.000000)"
            fill="#E77426"
            fillRule="nonzero"
          >
            <path
              d="M689.874891,207.087782 L693.696046,213.674884 C694.093701,214.352745 694.102277,215.19303 693.71854,215.878997 C693.334349,216.565776 692.616231,216.992244 691.825499,217.000001 L684.168354,217.000001 C683.385758,216.993546 682.666273,216.565768 682.282022,215.878377 C681.898089,215.191556 681.907056,214.349402 682.30343,213.677325 L686.126003,207.087809 C686.514396,206.413611 687.229657,205.99909 688.002999,206.000001 C688.776258,206.000916 689.49127,206.417155 689.874891,207.087782 Z M687.068482,207.641836 L683.243077,214.23621 C683.044628,214.572707 683.040139,214.994307 683.232361,215.338175 C683.424795,215.682421 683.785183,215.896694 684.172792,215.899935 L691.820177,215.899935 C692.216277,215.896037 692.575969,215.682427 692.768369,215.338494 C692.960495,214.99505 692.956202,214.574378 692.755752,214.232669 L688.931581,207.64036 C688.741061,207.307313 688.38588,207.100547 688.001722,207.100092 C687.61752,207.09964 687.262209,207.305555 687.068482,207.641836 Z M688.000745,214.799818 C687.699395,214.799818 687.455103,214.553555 687.455103,214.249773 C687.455103,213.945991 687.699395,213.699728 688.000745,213.699728 C688.302095,213.699728 688.546388,213.945991 688.546388,214.249773 C688.546388,214.553555 688.302095,214.799818 688.000745,214.799818 Z M687.456714,209.299364 L688.547999,209.299364 L688.547999,213.149682 L687.456714,213.149682 L687.456714,209.299364 Z"
              id="Alert"
            ></path>
          </g>
        </g>
      </svg>
    </span>
  );
};
