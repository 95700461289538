import React from "react";
import "./styles.scss";
import PropTypes from "prop-types";
import Select from "react-select";

export const SelectField = ({
  newItem,
  label,
  placeholder,
  extraStyles,
  styles,
  width,
  theme,
  options,
  setValue,
  setItem,
  showError,
  disabled,
  isClearable,
  errorMessage,
}) => {
  return (
    <div className={`form-field ${extraStyles}`}>
      <label className="field-title">{label}</label>

      <div className="field-wrap">
        <Select
          isClearable={isClearable}
          placeholder={placeholder}
          theme={theme}
          styles={styles}
          options={options}
          onChange={(event) => {
            if (event) {
              setValue(event.value);
              setItem(event);
            } else {
              setValue(null);
              setItem(null);
            }
          }}
          disabled={disabled}
        />

        {showError && <div className="error-wrap">{errorMessage}</div>}
      </div>
    </div>
  );
};

SelectField.propTypes = {
  newItem: PropTypes.any,
  label: PropTypes.string.isRequired,
  extraStyles: PropTypes.string,
  styles: PropTypes.any,
  theme: PropTypes.any,
  options: PropTypes.any,
  setValue: PropTypes.func,
  showError: PropTypes.bool,
  disabled: PropTypes.bool,
  isClearable: PropTypes.bool,

  errorMessage: PropTypes.string,
};
