import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "./styles.scss";

export const AppDropdown = ({ config, onSelect, selectedValue }) => {
  useEffect(() => {
    config && onSelect(config[0]);
    // eslint-disable-next-line
  }, [config]);

  return (
    <div className="hotel-selectbox-wrap">
      <Dropdown
        value={selectedValue}
        options={config && config}
        onChange={(event) => {
          onSelect && onSelect(event);
        }}
        placeholder="Select Hotel"
        disabled={!config}
      />
    </div>
  );
};

AppDropdown.propTypes = {
  config: PropTypes.array,
  onSelect: PropTypes.func,
  selectedValue: PropTypes.object,
};
