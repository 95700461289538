import React, { useEffect, useState } from "react";
import "./styles.scss";
import Loader from "react-loader-spinner";
import Authentication from "./auth";
import Integration from "./integration";

const GoorooBooster = () => {
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [loggedEmail, setLoggedEmail] = useState("");
  const [processing, setProcessing] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastError, setToastError] = useState(false);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    const email = localStorage.getItem("gb-email");
    if (email) setLoggedEmail(email);
  }, []);

  const handleIntegrate = async () => {
    setProcessing(true);
    try {
      const url =
        "https://api-dev.gooroobooster.com/customer/get-integration-token";
      const options = {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify({
          email: window.localStorage.getItem("gb-email"),
        }),
      };
      const data = await fetch(url, options);
      const result = await data.json();
      if (result.statusCode === 200) {
        const { token } = JSON.parse(result.body);
        window
          .open(
            "https://app-dev.gooroobooster.com/integration/" + token,
            "_black"
          )
          .focus();
      }
    } catch (error) {
      setToastMessage(error.message);
      setToastError(true);
      setShowToast(true);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div className="right-wrapper">
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "80vh",
          }}
        >
          <Loader type="Oval" color="#030303" height={50} width={50} />
        </div>
      ) : !loggedEmail ? (
        <Authentication setLoggedEmail={setLoggedEmail} />
      ) : (
        <Integration
          handleIntegrate={handleIntegrate}
          setLoggedEmail={setLoggedEmail}
          processing={processing}
          toastError={toastError}
          toastMessage={toastMessage}
          showToast={showToast}
          setShowToast={setShowToast}
        />
      )}
    </div>
  );
};
export default GoorooBooster;
