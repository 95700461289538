import React from "react";
import "./styles.scss";

export const ResendIcon = () => {
  return (
    <span className="resend-icon">
      <svg
        width="11px"
        height="11px"
        viewBox="0 0 11 11"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Resend</title>
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="3-admin"
            transform="translate(-1139.000000, -331.000000)"
            fill="#000000"
            fillRule="nonzero"
          >
            <g id="Group-16" transform="translate(1117.000000, 312.000000)">
              <g id="Resend" transform="translate(22.000000, 19.000000)">
                <path
                  d="M1.46666667,7.33333333 L1.46666667,9.53333333 L3.66666667,9.53333333 L3.66666667,11 L1.46666667,11 C0.70429713,11 0.0777835147,10.4183317 0.00671398745,9.67458312 L7.88213939e-12,9.53333333 L7.88213939e-12,7.33333333 L1.46666667,7.33333333 Z M11,7.33333333 L11,9.53333333 C11,10.2957029 10.4183317,10.9222165 9.67458312,10.993286 L9.53333333,11 L7.33333333,11 L7.33333333,9.53333333 L9.53333333,9.53333333 L9.53333333,7.33333333 L11,7.33333333 Z M7.7,6.23333333 L7.7,7.7 L6.23333333,7.7 L6.23333333,6.23333333 L7.7,6.23333333 Z M4.76666667,6.23333333 L4.76666667,7.7 L3.3,7.7 L3.3,6.23333333 L4.76666667,6.23333333 Z M6.23333333,4.76666667 L6.23333333,6.23333333 L4.76666667,6.23333333 L4.76666667,4.76666667 L6.23333333,4.76666667 Z M7.7,3.3 L7.7,4.76666667 L6.23333333,4.76666667 L6.23333333,3.3 L7.7,3.3 Z M4.76666667,3.3 L4.76666667,4.76666667 L3.3,4.76666667 L3.3,3.3 L4.76666667,3.3 Z M9.53333333,1.97042382e-12 C10.2957029,1.97042382e-12 10.9222165,0.581668348 10.993286,1.32541688 L11,1.46666667 L11,3.66666667 L9.53333333,3.66666667 L9.53333333,1.46666667 L7.33333333,1.46666667 L7.33333333,1.97042382e-12 L9.53333333,1.97042382e-12 Z M3.66666667,1.97042382e-12 L3.66666667,1.46666667 L1.46666667,1.46666667 L1.46666667,3.66666667 L7.88213939e-12,3.66666667 L7.88213939e-12,1.46666667 C7.88213939e-12,0.70429713 0.581668348,0.0777835147 1.32541688,0.00671398744 L1.46666667,1.97042382e-12 L3.66666667,1.97042382e-12 Z"
                  id="Combined-Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};
