export const LOCAL_STORAGE_KEYS = {
	accessToken: "accessToken",
};

export const DATE_FORMATS = {
	dayTimeFormat: "ddd, MMM DD, YYYY",
};

// These are the status types which we need to send in the api
export const ADMIN_CHECKIN_STATUS_TYPES_TO_SEND = {
	DOC_UNVERIFIED: "DOC_UNVERIFIED",
	DOC_VERIFIED: "DOC_VERIFIED",
	KEY_SENT: "KEY_SENT",
	KEY_EXPIRED: "KEY_EXPIRED",
};

// These are the status types which we will get from the api
export const ADMIN_CHECKIN_STATUS_TYPES_TO_GET = {
	DOC_UNVERIFIED: "Doc-unverified",
	DOC_VERIFIED: "Doc-verified",
	KEY_SENT: "Key-sent",
	KEY_EXPIRED: "Key-expired",
};
