import React from "react";
import "./styles.scss";

export const NoInternet = () => {
  return (
    <div className="overlayWhite">
      <div className="toast-wrapper no-network-toast">
        <div className="toast-bg"></div>
        <span className="notification-text">No Internet Connection</span>
      </div>
    </div>
  );
};
