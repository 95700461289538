import React from "react";
import "./styles.scss";
import PropTypes from "prop-types";

export const TextAreaField = ({
  label,
  extraStyles,
  type,
  value,
  setValue,
  showError,
  disabled,
  errorMessage,
  placeHolder,
}) => {
  return (
    <div className={`form-field ${extraStyles}`}>
      <label className="field-title">{label}</label>
      <div className="field-wrap">
        <textarea
          type={type ? type : "text"}
          className={disabled ? "textarea-field disabled" : "textarea-field"}
          value={value}
          onChange={(event) => setValue(event.target.value)}
          placeholder={placeHolder ? placeHolder : ""}
          disabled={disabled}
        />
        {showError && (
          <div className="textarea-error-wrap ">{errorMessage}</div>
        )}
      </div>
    </div>
  );
};

TextAreaField.propTypes = {
  label: PropTypes.string.isRequired,
  extraStyles: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  setValue: PropTypes.func,
  showError: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
};
