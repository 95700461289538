import React from "react";
import "./styles.scss";

const Option = ({ children }) => (
  <li className="app-dashboard-menu-item">{children}</li>
);

const Menu = ({ children, title, style, open, setOpen }) => {
  return (
    <div style={style} className="app-dashboard-menu-wrapper">
      <button
        className="app-dashboard-menu-title"
        data-open={open}
        onClick={() => setOpen(!open)}
      >
        <span>
          <svg style={{ width: "8px", height: "8px" }} viewBox="0 0 24 24">
            <path fill="#979797" d="M1,21H23L12,2" />
          </svg>
        </span>
        {title}
      </button>
      <ul className="app-dashboard-menu-list" data-open={open}>
        {children}
      </ul>
    </div>
  );
};

Menu.Option = Option;

export default Menu;
