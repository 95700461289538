export const SERVICES = {
  getReservations: {
    key: "reservations",
    apiEndpoint: "reservations",
  },
  sendKey: {
    apiEndpoint: "reservationKey/resendRoomKey",
  },
  updateAdminCheckInStatus: {
    apiEndpoint: "reservations/updateReservationAdminCheckInStatus",
  },
  expireKey: {
    apiEndpoint: "reservationKey/expireRoomCard",
  },
  sendSms: {
    apiEndpoint: "reservations/sendSMS",
  },
};
