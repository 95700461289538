import { RESERVATION_STATUS } from "./Components/Reservations/constants";

export const RESERVATIONS_INITIAL_STATE = {
  data: [],
  reservationsCount: {
    [RESERVATION_STATUS.Booking]: "",
    [RESERVATION_STATUS.Confimed]: "",
    [RESERVATION_STATUS.CheckedIn]: "",
    [RESERVATION_STATUS.CheckedOut]: "",
    [RESERVATION_STATUS.Canceled]: "",
  },
  reservationStatus: RESERVATION_STATUS.Confimed,
  pressedHeaderTab: {
    currentPressedTab: RESERVATION_STATUS.Confimed,
    previousPressedTab: null,
  },

  isWsMessageReceived: false,

  reservationsPageNumber: {
    [RESERVATION_STATUS.Booking]: 0,
    [RESERVATION_STATUS.Confimed]: 0,
    [RESERVATION_STATUS.CheckedIn]: 0,
    [RESERVATION_STATUS.CheckedOut]: 0,
    [RESERVATION_STATUS.Canceled]: 0,
    [RESERVATION_STATUS.NoShow]: 0,
  },

  selectedProperty: null,
};
