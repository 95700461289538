import AxiosBuilder from "src/app/common/services/AxiosBuilder";
import { getJwtToken } from "src/app/common/utils/sessionUtils";

export const getUnreadChatMessagesByProperty = async (apiEndpoint, params) => {
  const token = await getJwtToken();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        propertyId: params.propertyId,
        timezoneRegion: params.timezoneRegion,
      }),
    })
      .withAuth(token)
      .POST()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const updateReadAllChatMessages = async (apiEndpoint, params) => {
  const token = await getJwtToken();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        reservationId: params.reservationId,
        isRead: params.isRead,
      }),
    })
      .withAuth(token)
      .POST()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getUnreadNotificationsByProperty = async (apiEndpoint, params) => {
  const token = await getJwtToken();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        propertyId: params.propertyId,
        timezoneRegion: params.timezoneRegion,
      }),
    })
      .withAuth(token)
      .POST()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const updateReadAllNotifications = async (apiEndpoint, params) => {
  const token = await getJwtToken();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        propertyId: params.propertyId,
        isRead: params.isRead,
      }),
    })
      .withAuth(token)
      .POST()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
