import React from 'react';

const KeyHandling = ({ onChange, values, errors, onFocus }) => (
  <div className='property-creation-form-section' style={{ width: "100%" }}>
    <h2>Key handling</h2>
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      <div
        style={{
          width: "50%",
          paddingRight: 32
        }}
      >
        <label htmlFor="secret_key" className='property-creation-form-item' style={{ marginTop: 24 }}>
          <span>
            Kiosk secret key
          </span>
          <input
            type="text"
            id="secret_key"
            name="secret_key"
            placeholder=""
            onFocus={onFocus}
            onChange={onChange}
            value={values["secret_key"]}
          />
          <p data-error={errors["secret_key"] ? true : false}>
            { errors["secret_key"] }
          </p>
        </label>  
        <label htmlFor="qr_master_account_id" className='property-creation-form-item' style={{ marginTop: 24 }}>
          <span>
            QR Master account ID
          </span>
          <input
            type="text"
            id="qr_master_account_id"
            name="qr_master_account_id"
            placeholder=""
            onFocus={onFocus}
            onChange={onChange}
            value={values["qr_master_account_id"]}
          />
          <p data-error={errors["qr_master_account_id"] ? true : false}>
            { errors["qr_master_account_id"] }
          </p>
        </label> 
        <label htmlFor="qr_master_auth_token" className='property-creation-form-item' style={{ marginTop: 24 }}>
          <span>
            QR Master auth token
          </span>
          <input
            type="text"
            id="qr_master_auth_token"
            name="qr_master_auth_token"
            placeholder=""
            onFocus={onFocus}
            onChange={onChange}
            value={values["qr_master_auth_token"]}
          />
          <p data-error={errors["qr_master_auth_token"] ? true : false}>
            { errors["qr_master_auth_token"] }
          </p>
        </label>                            
      </div>
      <div
        style={{
          width: "50%",
          paddingLeft: 32
        }}
      >
        <label htmlFor="qr_master_community_no" className='property-creation-form-item' style={{ marginTop: 24, visibility: "hidden" }}>
          <span>
            QR Master community number
          </span>
          <input
            type="text"
            id="qr_master_community_no"
            name="qr_master_community_no"
            placeholder=""
            onFocus={onFocus}
            onChange={onChange}
            value={values["qr_master_community_no"]}
          />
          <p data-error={errors["qr_master_community_no"] ? true : false}>
            { errors["qr_master_community_no"] }
          </p>
        </label>         
        <label htmlFor="qr_master_community_no" className='property-creation-form-item' style={{ marginTop: 24 }}>
          <span>
            QR Master community number
          </span>
          <input
            type="text"
            id="qr_master_community_no"
            name="qr_master_community_no"
            placeholder=""
            onFocus={onFocus}
            onChange={onChange}
            value={values["qr_master_community_no"]}
          />
          <p data-error={errors["qr_master_community_no"] ? true : false}>
            { errors["qr_master_community_no"] }
          </p>
        </label>           
        <div>
          <label htmlFor="send_key_automatically" className='property-creation-form-item' style={{ marginBottom: 10 }}>
              <div
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type={"checkbox"}
                  checked={values["send_key_automatically"]}
                  id="send_key_automatically"
                  name="send_key_automatically"
                  onFocus={onFocus}
                  onChange={onChange}
                />                      
                <span style={{ margin: 0 }}>
                  Send key automatically
                </span>
              </div>
          </label> 
            {
              values["send_key_automatically"] && (
                <label htmlFor="send_key_time" className='property-creation-form-item' data-required={true} id="send_key_time">
                  <input
                    type="text"
                    id="send_key_time"
                    name="send_key_time"
                    placeholder="Send time"
                    onFocus={onFocus}
                    onChange={onChange}
                    value={values["send_key_time"]}
                    style={{ width: "25%" }}
                  />                    
                  <p data-error={errors["send_key_time"] ? true : false}>
                    { errors["send_key_time"] }
                  </p>
                </label>               
              )
            }              
        </div>          
      </div>
    </div>
  </div>
)

export default KeyHandling;