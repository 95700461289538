import React from "react";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "./styles.scss";

export function DateRangePicker({
  format,
  calendarDate,
  getCalendarDate,
  maxDate,
  minDate,
}) {
  const onChange = (value) => {
    getCalendarDate(value);
  };

  const onFocus = (e) => {
    e.target.blur();
  };

  return (
    <div>
      <DatePicker
        format={format}
        value={calendarDate}
        onFocus={onFocus}
        onChange={onChange}
        minDate={minDate}
        maxDate={maxDate}
      />
    </div>
  );
}
