export const SERVICES = {
  createExtraCharge: {
    apiEndpoint: "extracharges/createExtraCharge",
  },
  getExtraCharges: {
    apiEndpoint: "extracharges/getExtraCharges",
  },
  deleteExtraCharges: {
    apiEndpoint: "extracharges/deleteExtraCharges",
  },
  getReservationAndExtraCharges: {
    apiEndpoint: "extracharges/getReservationAndExtraCharges",
  },
  createExtraChargeItem: {
    apiEndpoint: "extracharges/createExtraChargeItem",
  },
  updateExtraChargeItem: {
    apiEndpoint: "extracharges/updateExtraChargeItem",
  },
  getExtraChargesItems: {
    apiEndpoint: "extracharges/getExtraChargesItems",
  },
  getExtraChargesItem: {
    apiEndpoint: "extracharges/getExtraChargesItem",
  },
  deleteExtraChargesItem: {
    apiEndpoint: "extracharges/deleteExtraChargesItem",
  },
  charge: {
    apiEndpoint: "stripe/charge",
  },
  refund: {
    apiEndpoint: "stripe/refundAmount",
  },
  createAdminLog: {
    apiEndpoint: "admin/createAdminLog",
  },
};

export const deleteModal = {
  title: "Delete Extra Charge",
  message: "Are you sure you want to delete?",
  no: "Cancel",
  yes: "Confirm",
};
