export const SERVICES = {
  getAdminByEmail: {
    apiEndpoint: "admin/getAdminByEmail",
  },
};

export const LOGIN_FORM = {
  username: "email",
  password: "password",
  loginButtonTitle: "Login",
};
