import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./styles.scss";
import LogoutIcon from "src/app/assets/images/logout-1.png";
import NotificationsIcon from "src/app/assets/images/notifications.svg";
import CheckInIcon from "src/app/assets/images/notification-check-in-icon.svg";
import CheckOutIcon from "src/app/assets/images/notification-check-out-icon.svg";
import ReservationIcon from "src/app/assets/images/notification-bed-icon.svg";
import DefaultIcon from "src/app/assets/images/notification-default-icon.svg";
import ReleaseIcon from "src/app/assets/images/notification-refund-icon.svg";
import ChatIcon from "src/app/assets/images/chat.svg";
import { logoutModal } from "../../constants";
import { routes } from "../../../AppRoutes";
import { AppDropdown } from "src/app/components/AppDropdown";
import { BrightHotelLogo } from "src/app/components/BrightHotelLogo";
import { setPropertyId } from "../../actionCreators";
import Notifications from "react-notifications-menu-modern";
import { getTimezoneOffsetRegion } from "src/app/common/utils/dateUtils";
import { SERVICES } from "./constants";
import {
  getUnreadChatMessagesByProperty,
  getUnreadNotificationsByProperty,
  updateReadAllNotifications,
} from "./services";
import { useDispatch } from "react-redux";
import { setWsMessageReceivedStatus } from "src/app/pages/Dashboard/actionCreators";
import { ReservationDetails } from "../ReservationDetails";
import { Toast } from "src/app/components/Toast";
import { useSelector, connect } from "react-redux";

export const Header = ({ adminProperties }) => {
  const dispatch = useDispatch();
  const { isWsMessageReceived } = useSelector((state) => state.reservations);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [unreadMessages, setUnreadMessages] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [showReservationDetails, setShowReservationDetails] = useState(false);
  const [selectedReservationId, setSelectedReservationId] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [toastError, setToastError] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [user, setUser] = useState(null);
  const [currentProperty, setCurrentProperty] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (Array.isArray(adminProperties)) setCurrentProperty(adminProperties[0]);
    return () => {};
  }, [adminProperties]);

  useEffect(() => {
    if (currentProperty) {
      getUnreadChatMessages();
      getUnreadNotifications();
      getUser();
    }
    // eslint-disable-next-line
  }, [currentProperty]);

  useEffect(() => {
    if (isWsMessageReceived) {
      getUnreadChatMessages();
      getUnreadNotifications();
    }
    // eslint-disable-next-line
  }, [isWsMessageReceived]);

  const handleLogout = async () => {
    await Auth.signOut();
    history.push(routes.home);
  };

  const showLogoutAlert = () => {
    confirmAlert({
      title: logoutModal.title,
      message: logoutModal.message,
      buttons: [
        {
          label: logoutModal.no,
        },
        {
          label: logoutModal.yes,
          onClick: handleLogout,
        },
      ],
    });
  };

  const getSelectedPropertyValue = (val) => {
    dispatch(setPropertyId(val));
    setSelectedProperty(val);
    setCurrentProperty(val);
  };

  const getUnreadChatMessages = async () => {
    const { apiEndpoint } = SERVICES.getUnreadChatMessagesByProperty;
    try {
      if (adminProperties) {
        const response = await getUnreadChatMessagesByProperty(apiEndpoint, {
          propertyId: currentProperty?.value,
          timezoneRegion: getTimezoneOffsetRegion(),
        });
        if (response) {
          let messages = [];
          response.forEach((message) => {
            messages.push(message);
          });
          setUnreadMessages([]);
          setUnreadMessages(messages);
        }
      }
    } catch (error) {
      console.log("Error to get unread chat messages");
    }
  };

  const getUnreadNotifications = async () => {
    const { apiEndpoint } = SERVICES.getUnreadNotificationsByProperty;
    try {
      if (adminProperties) {
        const response = await getUnreadNotificationsByProperty(apiEndpoint, {
          propertyId: currentProperty?.value,
          timezoneRegion: getTimezoneOffsetRegion(),
        });
        if (response) {
          let items = [];
          response.forEach((item) => {
            switch (item.type) {
              case "check-in":
                item.image = CheckInIcon;
                break;
              case "check-out":
                item.image = CheckOutIcon;
                break;
              case "reservation":
                item.image = ReservationIcon;
                break;
              case "release":
                item.image = ReleaseIcon;
                break;

              default:
                item.image = DefaultIcon;
                break;
            }
            items.push(item);
          });

          setUnreadNotifications(items);
        }
      }
    } catch (error) {
      console.log("Error to get unread notifications");
    }
  };

  const updateNotificationsReadAll = async () => {
    const { apiEndpoint } = SERVICES.updateReadAllNotifications;

    try {
      if (adminProperties) {
        await updateReadAllNotifications(apiEndpoint, {
          propertyId: currentProperty?.value,
          isRead: true,
        });
      }
    } catch (error) {
      console.log("Error to mark all notifications as read");
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      await updateNotificationsReadAll();
      await getUnreadNotifications();
    } catch (error) {
      console.log("Error to mark all notifications as read");
    }
  };

  const dismissReservationDetails = async () => {
    dispatch(setWsMessageReceivedStatus(true));
    setShowReservationDetails(false);
    await getUnreadChatMessages();
  };

  const handleReservationDetailsError = () => {
    setShowReservationDetails(false);
    setToastMessage("Error");
    setToastError(true);
    setShowToast(true);
  };

  const handleReservationDetails = async (data) => {
    setSelectedReservationId(data.reservationId);
    setShowReservationDetails(true);
    await getUnreadChatMessages();
  };

  const getUser = async () => {
    const user = await Auth.currentUserInfo();
    if (user.hasOwnProperty("id")) {
      setUser(user);
    } else {
      handleLogout();
    }
  };

  return (
    <React.Fragment>
      <header className="header">
        <div className="header-wrap">
          <div className="logo-wrap">
            <BrightHotelLogo />
          </div>

          <div className="admin-handler">
            <div className="admin-properties">
              <AppDropdown
                config={adminProperties}
                onSelect={getSelectedPropertyValue}
                selectedValue={selectedProperty}
              />
            </div>
            <div className="admin-actions">
              <div className="admin-notifications">
                <Notifications
                  data={unreadMessages}
                  icon={ChatIcon}
                  classNamePrefix="cw"
                  markAsRead={(data) => handleReservationDetails(data)}
                  renderImage={false}
                  noNotificationsText="No messages"
                  header={{
                    title: "Messages",
                    option: {
                      text: "",
                    },
                  }}
                />

                <Notifications
                  data={unreadNotifications}
                  icon={NotificationsIcon}
                  classNamePrefix="nw"
                  header={{
                    title: "Notifications",
                    option: {
                      text: "Mark all as read",
                      onClick: () => handleMarkAllAsRead(),
                    },
                  }}
                />
              </div>
              <div
                className="admin-name"
                style={{ cursor: "pointer" }}
                onClick={showLogoutAlert}
              >
                {user ? user.attributes.email : ""}
                <span className="logout-icon">
                  <img src={LogoutIcon} alt="logout" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </header>

      {showReservationDetails && (
        <ReservationDetails
          activeClassName={showReservationDetails && "active"}
          onDismiss={dismissReservationDetails}
          selectedResId={selectedReservationId}
          setErrorOccured={handleReservationDetailsError}
          chatIsOpen={true}
        />
      )}

      <Toast
        message={toastMessage}
        activeClassName={showToast ? "active" : ""}
        onDismiss={() => setShowToast(false)}
        isError={toastError}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { isWsMessageReceived } = state.reservations;
  return {
    isWsMessageReceived,
  };
};

const mapDispatchToProps = {
  setWsMessageReceivedStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
