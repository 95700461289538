import React from "react";
import "./styles.scss";
import PropTypes from "prop-types";
import CurrencyInput from "react-currency-input-field";

export const NumericField = ({
  label,
  extraStyles,
  inputId,
  inputName,
  value,
  setValue,
  showError,
  disabled,
  errorMessage,
  allowNegativeValue,
  allowDecimals,
  prefix,
  decimalsLimit,
  intlConfig,
}) => {
  return (
    <div className={`form-field ${extraStyles}`}>
      <label className="field-title">{label}</label>
      <div className="field-wrap">
        <CurrencyInput
          id={inputId || "input-currency-id"}
          name={inputName || "input-currency-name"}
          className="input-currency-field"
          decimalsLimit={decimalsLimit || 2}
          allowDecimals={allowDecimals || true}
          value={value || ""}
          onValueChange={(value, name) => setValue(value)}
          allowNegativeValue={allowNegativeValue || false}
          disabled={disabled || false}
        />

        {showError && <div className="error-wrap">{errorMessage}</div>}
      </div>
    </div>
  );
};

NumericField.propTypes = {
  label: PropTypes.string.isRequired,
  extraStyles: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  setValue: PropTypes.func,
  showError: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
};
