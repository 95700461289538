import React from "react";
import { SkeletonLoader } from "src/app/components/SkeletonLoader";
import "./styles.scss";

export const EmptyState = ({ index }) => {
  return (
    <tr className={(index + 1) % 2 === 0 ? "even" : "odd"}>
      <td style={{ paddingTop: 32 }}>
        <SkeletonLoader width={75} height={10} />
      </td>

      <EmptyColumn />
      <EmptyColumn />
      <EmptyColumn />
      <EmptyColumn />

      <td style={{ paddingTop: 32 }}>
        <SkeletonLoader width={100} height={10} />
      </td>
    </tr>
  );
};

const EmptyColumn = () => {
  return (
    <td style={{ paddingTop: 32 }}>
      <SkeletonLoader width={130} height={10} />
    </td>
  );
};
