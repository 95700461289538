import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { AppButton } from "src/app/components/AppButton";
import { useSelector } from "react-redux";
import "./styles.scss";
import { SERVICES } from "./constants";
import {
  createExtraChargeItemService,
  updateExtraChargeItemService,
  getExtraChargesItemsService,
  deleteExtraChargesItemService,
  createAdminLogService,
} from "./services";
import { TextField } from "src/app/components/TextField";
import EditIcon from "src/app/assets/images/edit-icon.svg";
import DeleteIcon from "src/app/assets/images/incidentals-delete-icon.svg";
import IncidentalsIcon from "src/app/assets/images/zero-incidentals.svg";
import InactiveIcon from "src/app/assets/images/inactive-icon.svg";
import ActiveIcon from "src/app/assets/images/active-icon.svg";
import Loader from "react-loader-spinner";
import { CurrencyField } from "src/app/components/CurrencyField";
import { deleteModal } from "./constants";
import { confirmAlert } from "react-confirm-alert";
import Popup from "reactjs-popup";
import CloseIcon from "src/app/assets/images/close-icon.svg";

const ExtraCharges = () => {
  const { selectedProperty } = useSelector((state) => state.reservations);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [processing, setProcessing] = useState(false);
  // eslint-disable-next-line
  const [deleting, setDeleting] = useState(false);
  // eslint-disable-next-line
  const [toastMessage, setToastMessage] = useState("");
  // eslint-disable-next-line
  const [toastError, setToastError] = useState(false);
  // eslint-disable-next-line
  const [showToast, setShowToast] = useState(false);

  const [extraChargesItems, setExtraChargesItems] = useState(null);
  // eslint-disable-next-line
  const [activeExtraChargesItems, setActiveExtraChargesItems] = useState([]);
  const [extraChargesDescription, setExtraChargesDescription] = useState("");
  const [extraChargesAmount, setExtraChargesAmount] = useState(0);
  const [extraChargesRequiresQuantity, setExtraChargesRequiresQuantity] =
    useState(false);

  const [chatbot, setChatbot] = useState(false);
  const [panel, setPanel] = useState(false);
  const [preCheckin, setPreCheckin] = useState(false);
  const [notification, setNotification] = useState(true);
  const [required, setRequired] = useState(false);
  const [extraField, setExtraField] = useState(false);
  const [extraFieldDescription, setExtraFieldDescription] = useState("");
  const [chargeReservationDays, setChargeReservationDays] = useState(false);
  const [extraChargesActive, setExtraChargesActive] = useState(true);
  const [extraChargesProcessing, setExtraChargesProcessing] = useState(false);
  // eslint-disable-next-line
  const [extraChargesDeleting, setExtraChargesDeleting] = useState(false);
  const [
    showExtraChargesDescriptionError,
    setShowExtraChargesDescriptionError,
  ] = useState(false);
  const [showExtraChargesAmountError, setShowExtraChargesAmountError] =
    useState(false);
  const [
    // eslint-disable-next-line
    showExtraChargesChatbotCodeError,
    setShowExtraChargesChatbotCodeError,
  ] = useState(false);
  const [extraChargesErrorMessage, setExtraChargesErrorMessage] = useState("");
  const [extraChargesloading, setExtraChargesLoading] = useState(true);
  const [extraChargesEdit, setExtraChargesEdit] = useState(false);
  const [extraChargesEditId, setExtraChargesEditId] = useState(null);

  const [open, setOpen] = useState(false);
  const closeModal = () => {
    setShowExtraChargesDescriptionError(false);
    setShowExtraChargesAmountError(false);
    setExtraChargesDescription("");
    setExtraChargesAmount(0);
    setChatbot(false);
    setPanel(false);
    setPreCheckin(false);
    setExtraChargesRequiresQuantity(false);
    setExtraChargesActive(true);
    setExtraChargesEdit(false);
    setExtraChargesEditId(null);
    setChargeReservationDays(false);
    setRequired(false);
    setExtraField(false);
    setExtraFieldDescription("");
    setOpen(false);
  };

  useEffect(() => {
    if (selectedProperty) {
      getExtraChargesItems();
    }
    // eslint-disable-next-line
  }, [selectedProperty]);

  const getExtraChargesItems = async () => {
    const { apiEndpoint } = SERVICES.getExtraChargesItems;
    setExtraChargesLoading(true);
    try {
      const response = await getExtraChargesItemsService(apiEndpoint, {
        propertyId: selectedProperty.value,
      });
      if (response) {
        const items = response.map((item, i) => {
          const extraChargeItem = {
            id: item.id,
            description: item.description,
            amount: item.amount,
            requiresQuantity: item.requires_quantity,
            chatbot: item.chatbot,
            panel: item.panel,
            preCheckin: item.pre_checkin,
            notification: item.notification,
            extraField: item.extra_field,
            extraFieldDescription: item.extra_field_description,
            required: item.required,
            chargeReservationDays: item.charge_reservation_days,
            active: item.active,
            paymentId: item.payment_id,
            value: item.description,
            label: item.description,
          };

          return extraChargeItem;
        });

        let activeItems = [];
        response.forEach((item) => {
          const extraChargeItem = {
            id: item.id,
            description: item.description,
            amount: item.amount,
            requiresQuantity: item.requires_quantity,
            chatbot: item.chatbot,
            panel: item.panel,
            preCheckin: item.pre_checkin,
            notification: item.notification,
            extraField: item.extra_field,
            extraFieldDescription: item.extra_field_description,
            required: item.required,
            chargeReservationDays: item.charge_reservation_days,
            active: item.active,
            paymentId: item.payment_id,
            value: item.description,
            label: item.description,
          };
          if (item.active === 1 && item.panel) {
            activeItems.push(extraChargeItem);
          }
        });

        setExtraChargesItems(items);
        setActiveExtraChargesItems(activeItems);
      } else {
        setExtraChargesItems([]);
      }
    } catch (error) {
      handleError();
    } finally {
      setExtraChargesLoading(false);
    }
  };

  const handleError = (error) => {
    setLoading(false);
    // sending the value to parent to show toast error.
    // setErrorOccured(true);
  };

  const handleCancelEditExtraChargesItem = async () => {
    closeModal();
    setShowExtraChargesDescriptionError(false);
    setShowExtraChargesAmountError(false);
    setShowExtraChargesChatbotCodeError(false);
    setShowToast(false);

    setExtraChargesDescription("");
    setExtraChargesAmount("");
    setExtraChargesRequiresQuantity(false);
    setChatbot(false);
    setPanel(false);
    setPreCheckin(false);
    setNotification(true);
    setExtraField(false);
    setExtraFieldDescription("");
    setRequired(false);
    setChargeReservationDays(false);
    setExtraChargesActive(true);

    setExtraChargesEdit(false);
    setExtraChargesEditId(null);
  };

  const handleEditExtraChargesItem = async (id, index) => {
    handleNewExtraChargeItem();
    setShowExtraChargesDescriptionError(false);
    setShowExtraChargesAmountError(false);
    setShowExtraChargesChatbotCodeError(false);
    setShowToast(false);
    const item = extraChargesItems[index];
    setExtraChargesDescription(item.description);
    setExtraChargesAmount(item.amount);
    setExtraChargesRequiresQuantity(item.requiresQuantity);
    setChatbot(item.chatbot);
    setPanel(item.panel);
    setPreCheckin(item.preCheckin);
    setNotification(item.notification);
    setExtraField(item.extraField);
    setExtraFieldDescription(
      item.extraFieldDescription ? item.extraFieldDescription : ""
    );
    setRequired(item.required);
    setChargeReservationDays(item.chargeReservationDays);
    setExtraChargesActive(item.active);

    setExtraChargesEdit(true);
    setExtraChargesEditId(item.id);
  };

  const handleAddExtraChargesItemClick = async () => {
    setShowToast(false);
    await createExtraChargeItem();
  };

  const handleUpdateExtraChargesItemClick = async () => {
    setShowToast(false);
    await updateExtraChargeItem();
  };

  const createExtraChargeItem = async () => {
    setExtraChargesProcessing(true);
    setShowExtraChargesDescriptionError(false);
    setShowExtraChargesAmountError(false);
    setShowExtraChargesChatbotCodeError(false);

    let fieldsValidated = true;
    const { apiEndpoint } = SERVICES.createExtraChargeItem;

    try {
      if (!extraChargesDescription || extraChargesDescription.trim() === "") {
        setExtraChargesErrorMessage("Description is required");
        setShowExtraChargesDescriptionError(true);
        fieldsValidated = false;
      } else if (!extraChargesAmount || extraChargesAmount.trim() === "") {
        setExtraChargesErrorMessage("Amount is required");
        setShowExtraChargesAmountError(true);
        fieldsValidated = false;
      } else if (parseFloat(extraChargesAmount) <= 0) {
        setExtraChargesErrorMessage("Amount must be greater than $0.00");
        setShowExtraChargesAmountError(true);
        fieldsValidated = false;
      }

      if (fieldsValidated) {
        await createExtraChargeItemService(apiEndpoint, {
          propertyId: selectedProperty.value,
          description: extraChargesDescription,
          amount: extraChargesAmount,
          requiresQuantity: extraChargesRequiresQuantity,
          chatbot: chatbot,
          panel: panel,
          preCheckin: preCheckin,
          notification: notification,
          extraField: extraField,
          extraFieldDescription: extraFieldDescription,
          isRequired: required,
          chargeReservationDays: chargeReservationDays,
          active: extraChargesActive,
        });

        // Create Log
        const data = {
          action: "Create",
          message: "Create extra charge item",
          reservationId: null,
          data: {
            propertyId: selectedProperty.value,
            description: extraChargesDescription,
            amount: extraChargesAmount,
            requiresQuantity: extraChargesRequiresQuantity,
            chatbot: chatbot,
            panel: panel,
            preCheckin: preCheckin,
            notification: notification,
            extraField: extraField,
            extraFieldDescription: extraFieldDescription,
            isRequired: required,
            chargeReservationDays: chargeReservationDays,
            active: extraChargesActive,
          },
        };
        await createAdminLog("Extra charges management", JSON.stringify(data));

        await getExtraChargesItems();
        closeModal();
        setExtraChargesDescription("");
        setExtraChargesAmount(0);
        setExtraChargesRequiresQuantity(false);
        setChatbot(false);
        setPanel(false);
        setPreCheckin(false);
        setNotification(true);
        setExtraField(false);
        setExtraFieldDescription("");
        setRequired(false);
        setChargeReservationDays(false);
        setExtraChargesActive(true);
      }
    } catch (error) {
      setToastMessage(error.message);
      setToastError(true);
      setShowToast(true);
    } finally {
      setExtraChargesProcessing(false);
    }
  };

  const updateExtraChargeItem = async () => {
    setExtraChargesProcessing(true);
    const id = extraChargesEditId;
    setExtraChargesEditId(null);
    setShowExtraChargesDescriptionError(false);
    setShowExtraChargesAmountError(false);
    setShowExtraChargesChatbotCodeError(false);

    let fieldsValidated = true;
    const { apiEndpoint } = SERVICES.updateExtraChargeItem;

    try {
      if (!extraChargesDescription || extraChargesDescription.trim() === "") {
        setExtraChargesErrorMessage("Description is required");
        setShowExtraChargesDescriptionError(true);
        fieldsValidated = false;
      } else if (!extraChargesAmount || extraChargesAmount.trim() === "") {
        setExtraChargesErrorMessage("Amount is required");
        setShowExtraChargesAmountError(true);
        fieldsValidated = false;
      } else if (parseFloat(extraChargesAmount) <= 0) {
        setExtraChargesErrorMessage("Amount must be greater than $0.00");
        setShowExtraChargesAmountError(true);
        fieldsValidated = false;
      }

      if (fieldsValidated) {
        await updateExtraChargeItemService(apiEndpoint, {
          extraChargeItemId: id,
          propertyId: selectedProperty.value,
          description: extraChargesDescription,
          amount: extraChargesAmount,
          requiresQuantity: extraChargesRequiresQuantity,
          chatbot: chatbot,
          panel: panel,
          preCheckin: preCheckin,
          notification: notification,
          extraField: extraField,
          extraFieldDescription: extraFieldDescription,
          isRequired: required,
          chargeReservationDays: chargeReservationDays,
          active: extraChargesActive,
        });

        const data = {
          action: "Update",
          message: "Update extra charge item",
          reservationId: null,
          data: {
            extraChargeItemId: id,
            propertyId: selectedProperty.value,
            description: extraChargesDescription,
            amount: extraChargesAmount,
            requiresQuantity: extraChargesRequiresQuantity,
            chatbot: chatbot,
            panel: panel,
            preCheckin: preCheckin,
            notification: notification,
            extraField: extraField,
            extraFieldDescription: extraFieldDescription,
            isRequired: required,
            chargeReservationDays: chargeReservationDays,
            active: extraChargesActive,
          },
        };
        await createAdminLog("Extra charges management", JSON.stringify(data));

        await getExtraChargesItems();
        closeModal();
        setExtraChargesDescription("");
        setExtraChargesAmount(0);
        setExtraChargesRequiresQuantity(false);
        setChatbot(false);
        setPanel(false);
        setPreCheckin(false);
        setNotification(true);
        setExtraField(false);
        setExtraFieldDescription("");
        setRequired(false);
        setChargeReservationDays(false);
        setExtraChargesActive(true);
        setExtraChargesEdit(false);
        setExtraChargesEditId(null);
      }
    } catch (error) {
      setToastMessage(error.message);
      setToastError(true);
      setShowToast(true);
    } finally {
      setExtraChargesProcessing(false);
    }
  };

  const removeExtraChargesItem = async (id) => {
    setExtraChargesDeleting(true);
    const { apiEndpoint } = SERVICES.deleteExtraChargesItem;

    try {
      await deleteExtraChargesItemService(apiEndpoint, {
        propertyId: selectedProperty.value,
        extraChargeItemId: id,
      });

      const data = {
        action: "Delete",
        message: "Delete extra charge item",
        reservationId: null,
        data: {
          propertyId: selectedProperty.value,
          extraChargeItemId: id,
        },
      };
      await createAdminLog("Extra charges management", JSON.stringify(data));

      await getExtraChargesItems();
      closeModal();
      setExtraChargesDescription("");
      setExtraChargesAmount(0);
      setExtraChargesRequiresQuantity(false);
      setChatbot(true);
      setPanel(true);
      setPreCheckin(false);
      setNotification(true);
      setExtraField(false);
      setExtraFieldDescription("");
      setRequired(false);
      setChargeReservationDays(false);
      setExtraChargesActive(true);
      setExtraChargesEdit(false);
      setExtraChargesEditId(null);
    } catch (error) {
      setToastMessage("Error to delete the extra charges item");
      setToastError(true);
      setShowToast(true);
    } finally {
      setExtraChargesDeleting(false);
    }
  };

  const handleNewExtraChargeItem = async () => {
    setOpen((o) => !o);
  };

  const showDeleteAlert = (id) => {
    try {
      confirmAlert({
        title: deleteModal.title,
        message: deleteModal.message,

        buttons: [
          {
            label: deleteModal.no,
            onClick: () => {},
          },
          {
            label: deleteModal.yes,
            onClick: () => {
              removeExtraChargesItem(id);
            },
          },
        ],
      });
    } catch (error) {
      handleError();
    } finally {
      setProcessing(false);
      setDeleting(false);
    }
  };

  const createAdminLog = async (type, data) => {
    setLoading(true);
    const { apiEndpoint } = SERVICES.createAdminLog;
    try {
      const user = await Auth.currentUserInfo();

      const params = {
        propertyId: selectedProperty.value,
        adminCognitoId: user.attributes.sub,
        type: type,
        data: data,
      };
      await createAdminLogService(apiEndpoint, params);
    } catch (error) {
      handleError();
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="right-wrapper">
      {extraChargesloading && !extraChargesItems ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <Loader type="Oval" color="#030303" height={50} width={50} />
        </div>
      ) : (
        <div className="tabs-wrapper">
          <h1>Extra Charges</h1>
          <div
            style={{
              display: "flex",
              paddingLeft: "40px",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <AppButton
              isLoading={extraChargesProcessing}
              label="Add"
              onClick={handleNewExtraChargeItem}
              isDisabled={extraChargesProcessing}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              padding: "40px",
              gap: "10px",
            }}
          >
            <Popup
              open={open}
              closeOnDocumentClick={false}
              onClose={closeModal}
            >
              <div className="modal">
                <div
                  className="close-button"
                  onClick={!extraChargesProcessing && closeModal}
                >
                  <img alt="" src={CloseIcon} width="24px" height="24px" />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "10px",
                  }}
                >
                  <h2>Extra charges</h2>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <TextField
                    extraStyles="text-field-description"
                    label="Description"
                    value={extraChargesDescription}
                    setValue={setExtraChargesDescription}
                    disabled={extraChargesProcessing}
                    showError={showExtraChargesDescriptionError}
                    errorMessage={extraChargesErrorMessage}
                  />
                  <CurrencyField
                    inputId="amount"
                    inputName="amount"
                    extraStyles="text-field-amount-no-padding"
                    label="Amount"
                    value={extraChargesAmount}
                    setValue={setExtraChargesAmount}
                    disabled={extraChargesProcessing}
                    showError={showExtraChargesAmountError}
                    errorMessage={extraChargesErrorMessage}
                    allowNegativeValue={false}
                    prefix={"$"}
                    intlConfig={{ locale: "en-US", currency: "USD" }}
                  />
                </div>

                <div className="field-group-items">
                  <div>
                    <label className="checkbox-component">
                      <input
                        type="checkbox"
                        onChange={(event) => {
                          setChatbot(event.target.checked);
                        }}
                        checked={chatbot}
                        disabled={extraChargesProcessing}
                      />
                      <span className="checkmark"></span>
                      Chatbot
                    </label>
                  </div>

                  <div>
                    <label className="checkbox-component">
                      <input
                        type="checkbox"
                        onChange={(event) => {
                          setPanel(event.target.checked);
                        }}
                        checked={panel}
                        disabled={extraChargesProcessing}
                      />
                      <span className="checkmark"></span>
                      Panel
                    </label>
                  </div>

                  {(chatbot || panel) && (
                    <div>
                      <label className="checkbox-component">
                        <input
                          type="checkbox"
                          onChange={(event) => {
                            setExtraChargesRequiresQuantity(
                              event.target.checked
                            );
                          }}
                          checked={extraChargesRequiresQuantity}
                          disabled={extraChargesProcessing}
                        />
                        <span className="checkmark"></span>
                        Requires quantity
                      </label>
                    </div>
                  )}

                  <div>
                    <label className="checkbox-component">
                      <input
                        type="checkbox"
                        onChange={(event) => {
                          setPreCheckin(event.target.checked);
                        }}
                        checked={preCheckin}
                        disabled={extraChargesProcessing}
                      />
                      <span className="checkmark"></span>
                      Pre Check-in
                    </label>
                  </div>

                  <div>
                    <label className="checkbox-component">
                      <input
                        type="checkbox"
                        onChange={(event) => {
                          setNotification(event.target.checked);
                        }}
                        checked={notification}
                        disabled={extraChargesProcessing}
                      />
                      <span className="checkmark"></span>
                      Notification
                    </label>
                  </div>

                  <div>
                    <label className="checkbox-component">
                      <input
                        type="checkbox"
                        onChange={(event) => {
                          setExtraChargesActive(event.target.checked);
                        }}
                        checked={extraChargesActive}
                        disabled={extraChargesProcessing}
                      />
                      <span className="checkmark"></span>
                      Active
                    </label>
                  </div>
                </div>
                {preCheckin ? (
                  <div className="field-group-items">
                    <div>
                      <label className="checkbox-component">
                        <input
                          type="checkbox"
                          onChange={(event) => {
                            setRequired(event.target.checked ? 1 : 0);
                          }}
                          checked={required}
                          disabled={extraChargesProcessing}
                        />
                        <span className="checkmark"></span>
                        Required
                      </label>
                    </div>
                    <div>
                      <label className="checkbox-component">
                        <input
                          type="checkbox"
                          onChange={(event) => {
                            setChargeReservationDays(event.target.checked);
                          }}
                          checked={chargeReservationDays}
                          disabled={extraChargesProcessing}
                        />
                        <span className="checkmark"></span>
                        Charge whole stay
                      </label>
                    </div>
                    <div>
                      <label className="checkbox-component">
                        <input
                          type="checkbox"
                          onChange={(event) => {
                            setExtraField(event.target.checked);
                          }}
                          checked={extraField}
                          disabled={extraChargesProcessing}
                        />
                        <span className="checkmark"></span>
                        Extra field
                      </label>
                    </div>

                    {extraField ? (
                      <div>
                        <TextField
                          extraStyles="text-field-description"
                          label="Extra field description"
                          value={extraFieldDescription}
                          setValue={setExtraFieldDescription}
                          disabled={extraChargesProcessing}
                        />
                      </div>
                    ) : null}
                  </div>
                ) : null}

                <div className="btn-container-row">
                  <button
                    className="cancel-item-btn"
                    onClick={handleCancelEditExtraChargesItem}
                    disabled={extraChargesProcessing}
                  >
                    <span>Cancel</span>
                  </button>

                  <AppButton
                    isLoading={extraChargesProcessing}
                    label={!extraChargesEdit ? "Save" : "Update"}
                    onClick={
                      !extraChargesEdit
                        ? handleAddExtraChargesItemClick
                        : handleUpdateExtraChargesItemClick
                    }
                    isDisabled={extraChargesProcessing}
                  />
                </div>
              </div>
            </Popup>

            {!extraChargesItems ||
              (extraChargesItems.length === 0 && (
                <div className="no-extra-charges">
                  <div className="no-extra-charges-icon">
                    <img
                      alt=""
                      src={IncidentalsIcon}
                      width="64px"
                      height="64px"
                    />
                  </div>
                  <div className="no-extra-charges-message">
                    No extra charges items found
                  </div>
                </div>
              ))}

            {extraChargesItems && extraChargesItems.length && (
              <div className="extra-charges-table-wrapper">
                <table className="extra-charges-table">
                  <tbody>
                    <tr>
                      <th className="extra-charges-description">Description</th>
                      <th className="extra-charges-amount">Amount</th>
                      <th className="extra-charges-column">Chatbot</th>
                      <th className="extra-charges-column">Panel</th>
                      <th className="extra-charges-column">Pre Check-in</th>
                      <th className="extra-charges-column">Notification</th>
                      <th className="extra-charges-column">
                        Requires Quantity
                      </th>
                      <th className="extra-charges-column">Active</th>
                      <th className="extra-charges-management-action"></th>
                    </tr>

                    {extraChargesItems.map((extraChargeItem, i) => {
                      return (
                        <tr
                          key={i}
                          className={(i + 1) % 2 === 0 ? "even" : "odd"}
                        >
                          <td className="extra-charges-description">
                            <span
                              className="description-text"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                if (!extraChargesProcessing) {
                                  handleEditExtraChargesItem(
                                    extraChargeItem.id,
                                    i
                                  );
                                }
                              }}
                            >
                              {extraChargeItem.description}
                            </span>
                          </td>
                          <td className="extra-charges-amount">
                            $ {extraChargeItem.amount}
                          </td>
                          <td className="extra-charges-column">
                            {extraChargeItem.chatbot ? (
                              <img
                                alt=""
                                src={ActiveIcon}
                                width="18px"
                                height="18px"
                              />
                            ) : (
                              <img
                                alt=""
                                src={InactiveIcon}
                                width="18px"
                                height="18px"
                              />
                            )}
                          </td>

                          <td className="extra-charges-column">
                            {extraChargeItem.panel ? (
                              <img
                                alt=""
                                src={ActiveIcon}
                                width="18px"
                                height="18px"
                              />
                            ) : (
                              <img
                                alt=""
                                src={InactiveIcon}
                                width="18px"
                                height="18px"
                              />
                            )}
                          </td>

                          <td className="extra-charges-column">
                            {extraChargeItem.preCheckin ? (
                              <img
                                alt=""
                                src={ActiveIcon}
                                width="18px"
                                height="18px"
                              />
                            ) : (
                              <img
                                alt=""
                                src={InactiveIcon}
                                width="18px"
                                height="18px"
                              />
                            )}
                          </td>

                          <td className="extra-charges-column">
                            {extraChargeItem.notification ? (
                              <img
                                alt=""
                                src={ActiveIcon}
                                width="18px"
                                height="18px"
                              />
                            ) : (
                              <img
                                alt=""
                                src={InactiveIcon}
                                width="18px"
                                height="18px"
                              />
                            )}
                          </td>

                          <td className="extra-charges-column">
                            {extraChargeItem.requiresQuantity ? (
                              <img
                                alt=""
                                src={ActiveIcon}
                                width="18px"
                                height="18px"
                              />
                            ) : (
                              <img
                                alt=""
                                src={InactiveIcon}
                                width="18px"
                                height="18px"
                              />
                            )}
                          </td>
                          <td className="extra-charges-column">
                            {extraChargeItem.active ? (
                              <img
                                alt=""
                                src={ActiveIcon}
                                width="18px"
                                height="18px"
                              />
                            ) : (
                              <img
                                alt=""
                                src={InactiveIcon}
                                width="18px"
                                height="18px"
                              />
                            )}
                          </td>

                          <td className="extra-charges-management-action">
                            <span
                              className="extra-charges-delete"
                              onClick={(e) => {
                                if (!extraChargesProcessing) {
                                  handleEditExtraChargesItem(
                                    extraChargeItem.id,
                                    i
                                  );
                                }
                              }}
                            >
                              <img
                                alt=""
                                src={EditIcon}
                                width="21px"
                                height="21px"
                              />
                            </span>
                            <span
                              className="extra-charges-delete"
                              onClick={(e) => {
                                if (!extraChargesProcessing) {
                                  showDeleteAlert(extraChargeItem.id);
                                }
                              }}
                            >
                              <img
                                alt=""
                                src={DeleteIcon}
                                width="21px"
                                height="21px"
                              />
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ExtraCharges;
