import React from 'react';
import "./styles.scss";
import Loader from "react-loader-spinner";

const OverlayLoader = ({ loading }) => (
  <div className='overlay-loader-main-cont' data-visible={loading}>
    <Loader type="Oval" color="#030303" height={50} width={50} />
  </div>
)

export default OverlayLoader;