const AWS_CONFIG = {
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_S3_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEBCLIENT_ID,

    scope: [
      "openid",
      "email",
      "phone",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    oauth: {
      domain: "tuck-infra-dev-user.auth.us-east-1.amazoncognito.com",
      scope: [
        "openid",
        "email",
        "phone",
        "profile",
        "aws.cognito.signin.user.admin",
      ],

      redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN,
      redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT,
      responseType: "code",
    },
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_BUCKET_NAME,
      region: process.env.REACT_APP_S3_REGION,
    },
  },
};

export default AWS_CONFIG;
