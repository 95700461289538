import React from "react";
import "./styles.scss";
import { ExpireIcon } from "src/app/components/ExpireIcon";
import { WifiIcon } from "src/app/components/WifiIcon";
import { ResendIcon } from "src/app/components/ResendIcon";
import PropTypes from "prop-types";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

export const MoreOptions = ({ config, onDismiss }) => {
  return (
    <ClickAwayListener onClickAway={onDismiss}>
      <div className="more-info-wrapper">
        <ul className="more-info-menu">
          {config &&
            config.map((option) => {
              return (
                <li onClick={option.onClick && option.onClick}>
                  <a href className="resend-key">
                    {option.icon} {option.name}
                  </a>
                </li>
              );
            })}
        </ul>
      </div>
    </ClickAwayListener>
  );
};

MoreOptions.propTypes = {
  config: PropTypes.array,
};

MoreOptions.defaultProps = {
  config: [
    {
      name: "RESend KEY",
      icon: <ResendIcon />,
    },
    {
      name: "EXPIRE KEY",
      icon: <ExpireIcon />,
    },
    {
      name: "RESEND Wifi SMS",
      icon: <WifiIcon />,
    },
  ],
};
