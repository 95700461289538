import React, { useState } from "react";
import "./styles.scss";
import PropTypes from "prop-types";
import { Column } from "./Components/Column";
import { Row } from "./Components/Row/index";
import { EmptyState } from "./Components/EmptyState";
import { MoreOptions } from "src/app/components/MoreOptions";

export const Table = ({
  data,
  columnsConfig,
  loading,
  onStatusClick,
  onNameClick,
  onMoreOptionsClick,
  showMoreOptions,
  moreOptionsConfig,
  moreOptionsConfigDefault,
  selectedReservationId,
  dismissMoreOptions,
  onSort,
  isArrivalsTab,
  isBookingsTab,
  reservationStatus,
  keyEnabled,
}) => {
  const [selectedColumn, setSelectedColumn] = useState();
  const renderEmptyState = () => {
    return [...Array(8)].map((e, i) => <EmptyState index={i} key={i} />);
  };

  return (
    <React.Fragment>
      <table className="table">
        <tbody>
          <tr>
            {
              // eslint-disable-next-line
              columnsConfig.map((column) => {
                if (
                  (column.key === "status" && isArrivalsTab()) ||
                  (column.key === "status" && isBookingsTab())
                ) {
                  return (
                    <Column
                      key={column.key}
                      config={column}
                      onSort={onSort}
                      setSelectedColumn={setSelectedColumn}
                      defaultIcon={column.key !== selectedColumn && column.icon}
                    />
                  );
                } else if (column.key !== "status") {
                  return (
                    <Column
                      key={column.key}
                      config={column}
                      onSort={onSort}
                      setSelectedColumn={setSelectedColumn}
                      defaultOrder={
                        (column.key !== selectedColumn &&
                          ((reservationStatus === "CheckedIn" &&
                            column.key === "checkin_date" &&
                            "desc") ||
                            (reservationStatus === "CheckedOut" &&
                              column.key === "checkout_date" &&
                              "desc"))) ||
                        (column.key !== selectedColumn && "asc")
                      }
                    />
                  );
                }
              })
            }
            <th className="info-width"></th>
          </tr>

          {loading
            ? renderEmptyState()
            : data.map((item, index) => {
                return (
                  <Row
                    key={item.id}
                    data={{ ...item, index }}
                    onStatusClick={onStatusClick}
                    onNameClick={onNameClick}
                    onMoreOptionsClick={() => {
                      onMoreOptionsClick(item.id, item.name);
                    }}
                    showMoreOptions={
                      showMoreOptions && selectedReservationId === item.id
                    }
                    componentToShowOnMoreOptions={
                      <MoreOptions
                        config={moreOptionsConfig}
                        onDismiss={dismissMoreOptions}
                      />
                    }
                    isArrivalsTab={isArrivalsTab}
                    isBookingsTab={isBookingsTab}
                    keyEnabled={keyEnabled}
                  />
                );
              })}
        </tbody>
      </table>
    </React.Fragment>
  );
};

Table.propTypes = {
  data: PropTypes.array.isRequired,
  columnsConfig: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  onStatusClick: PropTypes.func,
  onMoreOptionsClick: PropTypes.func,
  showMoreOptions: PropTypes.bool,
  moreOptionsConfig: PropTypes.array,
  selectedReservationId: PropTypes.any,
  dismissMoreOptions: PropTypes.func,
  onSort: PropTypes.func,
  isArrivalsTab: PropTypes.func,
  reservationStatus: PropTypes.string,
  keyEnabled: PropTypes.bool,
};
