import React from 'react';
import CurrencyInput from 'react-currency-input-field';

const Payments = ({ onChange, values, errors, onFocus, required }) => (
  <div className='property-creation-form-section'>
    <h2>Payments</h2>
    <div
      style={{
        display: "flex",
        flexWrap: "wrap"
      }}
    >
      <div
        style={{
          width: "50%",
          paddingRight: 32
        }}
      >
        <label htmlFor="stripe_public_token" className='property-creation-form-item' data-required={required}>
          <span data-required={required} id="stripe_public_token">
            Stripe public token
          </span>
          <input
            type="text"
            id="stripe_public_token"
            name="stripe_public_token"
            placeholder=""
            onFocus={onFocus}
            onChange={onChange}
            value={values["stripe_public_token"]}
          />
          <p data-error={errors["stripe_public_token"] ? true : false}>
            { errors["stripe_public_token"] }
          </p>
        </label>
        <label htmlFor="stripe_secret_token" className='property-creation-form-item' data-required={required}>
          <span data-required={required} id="stripe_secret_token">
            Stripe secret token
          </span>
          <input
            type="text"
            id="stripe_secret_token"
            name="stripe_secret_token"
            placeholder=""
            onFocus={onFocus}
            onChange={onChange}
            value={values["stripe_secret_token"]}
          />
          <p data-error={errors["stripe_secret_token"] ? true : false}>
            { errors["stripe_secret_token"] }
          </p>
        </label>                           
      </div>                
      <div
      style={{
        width: "50%",
        paddingLeft: 32,
        display: "flex",
        gap: "0 32px",
        flexWrap: "wrap",
        height: 100,
      }}
      >
        <label htmlFor="hold_amount" className='property-creation-form-item' style={{ width: "45%" }}>
          <span>
            Hold amount
          </span>
            <CurrencyInput
              id="hold_amount"
              name="hold_amount"
              className='property-creation-input-number'        
              placeholder="$0.00"
              prefix='$'
              decimalSeparator="."
              groupSeparator=","
              //defaultValue={1000}
              value={values["hold_amount"]}
              decimalsLimit={2}
              onValueChange={(value, name) => onChange({ target: { id: name, value } })}
            />
          <p data-error={errors["hold_amount"] ? true : false}>
            { errors["hold_amount"] }
          </p>
        </label>  
        <label htmlFor="release_days" className='property-creation-form-item' style={{ width: "45%" }}>
          <span>
            Release days
          </span>
          <input
            type="text"
            className='property-creation-input-number'                
            id="release_days"
            name="release_days"
            placeholder="E.g.: 5"
            onFocus={onFocus}
            onChange={onChange}
            value={values["release_days"]}
          />
          <p data-error={errors["release_days"] ? true : false}>
            { errors["release_days"] }
          </p>
        </label>  
        <label htmlFor="city_tax" className='property-creation-form-item' style={{ width: "28%" }}>
          <span>
            City tax
          </span>
          <CurrencyInput
            id="city_tax"
            name="city_tax"
            className='property-creation-input-number'        
            placeholder="$0.00"
            prefix='$'
            decimalSeparator="."
            groupSeparator=","
            //defaultValue={1000}
            value={values["city_tax"]}
            decimalsLimit={2}
            onValueChange={(value, name) => onChange({ target: { id: name, value } })}
          />
          <p data-error={errors["city_tax"] ? true : false}>
            { errors["city_tax"] }
          </p>
        </label>  
        <label htmlFor="occupancy_tax" className='property-creation-form-item' style={{ width: "28%" }}>
          <span>
            Occupancy tax
          </span>
          <CurrencyInput
            id="occupancy_tax"
            name="occupancy_tax"
            className='property-creation-input-number'        
            placeholder="$0.00"
            prefix='$'
            decimalSeparator="."
            groupSeparator=","
            //defaultValue={1000}
            value={values["occupancy_tax"]}
            decimalsLimit={2}
            onValueChange={(value, name) => onChange({ target: { id: name, value } })}
          />
          <p data-error={errors["occupancy_tax"] ? true : false}>
            { errors["occupancy_tax"] }
          </p>
        </label>   
        <label htmlFor="amenity_fee" className='property-creation-form-item' style={{ width: "28%" }}>
          <span>
            Amenity fee            
          </span>
          <CurrencyInput
            id="amenity_fee"
            name="amenity_fee"
            className='property-creation-input-number'        
            placeholder="$0.00"
            prefix='$'
            decimalSeparator="."
            groupSeparator=","
            //defaultValue={1000}
            value={values["amenity_fee"]}
            decimalsLimit={2}
            onValueChange={(value, name) => onChange({ target: { id: name, value } })}
          />
          <p data-error={errors["amenity_fee"] ? true : false}>
            { errors["amenity_fee"] }
          </p>
        </label>                                                 
      </div>             
    </div>               
  </div>  
)

export default Payments;