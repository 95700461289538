import React, { useState, useReducer } from "react";
import { TextField } from "src/app/components/TextField";
import { AppButton } from "src/app/components/AppButton";
import { useSelector } from "react-redux";

const INITIAL_VALUES = {
  first_name: {
    value: "",
    error: false,
    errorMessage: "",
    rules: [
      {
        required: true,
        message: "This field is required, please enter a value.",
      },
    ],
  },
  last_name: {
    value: "",
    error: false,
    errorMessage: "",
    rules: [
      {
        required: true,
        message: "This field is required, please enter a value.",
      },
    ],
  },
  email: {
    value: "",
    error: false,
    errorMessage: "",
    rules: [
      {
        required: true,
        message: "This field is required, please enter a value.",
      },
      {
        email: true,
        message: "The email is not valid.",
      },
    ],
  },
  phone: {
    value: "",
    error: false,
    errorMessage: "",
    rules: [
      {
        required: true,
        message: "This field is required, please enter a value.",
      },
      {
        phone: true,
        message: "The phone number is not valid.",
      },
    ],
  },
  password: {
    value: "",
    error: false,
    errorMessage: "",
    rules: [
      {
        required: true,
        message: "This field is required, please enter a value.",
      },
      {
        password: true,
        message:
          "The password must include lowercase letters, uppercase letters, numbers and special characters.",
      },
    ],
  },
  re_password: {
    value: "",
    error: false,
    errorMessage: "",
    rules: [
      {
        required: true,
        message: "This field is required, please enter a value.",
      },
      {
        re_password: true,
        message: "the password does not match the one entered previously.",
      },
    ],
  },
};

const Signup = ({ onSection }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [values, setValues] = useReducer(
    (current, next) => ({ ...current, ...next }),
    INITIAL_VALUES
  );
  // eslint-disable-next-line
  const { selectedProperty, isWsMessageReceived } = useSelector(
    (state) => state.reservations
  );

  const onChange = (key, value) => {
    const keyValues = {
      ...values[key],
      value,
    };
    setValues({ [key]: keyValues });
  };

  const onFocus = (key) => {
    const keyValues = {
      ...values[key],
      errorMessage: "",
      error: false,
    };
    setValues({ [key]: keyValues });
  };

  const onValidate = () => {
    for (const key in values) {
      const item = values[key];
      item.rules.forEach((element) => {
        if (element.required && values[key].value === "") {
          const keyValues = values[key];
          keyValues.error = true;
          keyValues.errorMessage = element.message;
          setValues({ [key]: keyValues });
        }

        if (element.email) {
          if (
            // eslint-disable-next-line
            !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(item.value)
          ) {
            const keyValues = values[key];
            keyValues.error = true;
            keyValues.errorMessage = element.message;
            setValues({ [key]: keyValues });
          }
        }

        if (element.phone) {
          if (
            // eslint-disable-next-line
            !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
              item.value
            )
          ) {
            const keyValues = values[key];
            keyValues.error = true;
            keyValues.errorMessage = element.message;
            setValues({ [key]: keyValues });
          }
        }

        if (element.password) {
          if (
            // eslint-disable-next-line
            !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/.test(
              item.value
            )
          ) {
            const keyValues = values[key];
            keyValues.error = true;
            keyValues.errorMessage = element.message;
            setValues({ [key]: keyValues });
          }
        }

        if (element.re_password) {
          if (values.password.value !== item.value) {
            const keyValues = values[key];
            keyValues.error = true;
            keyValues.errorMessage = element.message;
            setValues({ [key]: keyValues });
          }
        }
      });
    }
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      onValidate();
      const url = process.env.REACT_APP_GB_BASE_URL + "/customer/add";
      const options = {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          company: selectedProperty.label,
          first_name: values.first_name.value,
          last_name: values.last_name.value,
          email: values.email.value,
          phone: values.phone.value,
          password: values.password.value,
          language: "en_EN",
        }),
      };

      const data = await fetch(url, options);
      // eslint-disable-next-line
      const result = await data.json();

      setValues(INITIAL_VALUES);
      setSuccess(true);
      onSection("LOGIN");
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ul className="gb-form-cont">
      <ol>
        <li>
          <TextField
            extraStyles="text-field"
            label="First Name"
            value={values.first_name.value}
            setValue={(value) => onChange("first_name", value)}
            onFocus={(value) => onFocus("first_name", value)}
            disabled={loading}
            showError={values.first_name.error}
            errorMessage={values.first_name.errorMessage}
          />
        </li>
        <li>
          <TextField
            extraStyles="text-field"
            label="Last Name"
            value={values.last_name.value}
            setValue={(value) => onChange("last_name", value)}
            onFocus={(value) => onFocus("last_name", value)}
            disabled={loading}
            showError={values.last_name.error}
            errorMessage={values.last_name.errorMessage}
          />
        </li>
        <TextField
          extraStyles="text-field"
          label="Email"
          value={values.email.value}
          setValue={(value) => onChange("email", value)}
          onFocus={(value) => onFocus("email", value)}
          disabled={loading}
          showError={values.email.error}
          errorMessage={values.email.errorMessage}
        />
      </ol>
      <li>
        <TextField
          extraStyles="text-field"
          label="Phone"
          placeHolder="E.g.: +112355555555"
          value={values.phone.value}
          setValue={(value) => onChange("phone", value)}
          onFocus={(value) => onFocus("phone", value)}
          disabled={loading}
          showError={values.phone.error}
          errorMessage={values.phone.errorMessage}
        />
      </li>
      <li>
        <TextField
          extraStyles="text-field"
          label="Password"
          type="password"
          value={values.password.value}
          setValue={(value) => onChange("password", value)}
          onFocus={(value) => onFocus("password", value)}
          disabled={loading}
          showError={values.password.error}
          errorMessage={values.password.errorMessage}
        />
      </li>
      <li>
        <TextField
          extraStyles="text-field"
          label="Confirm Password"
          type="password"
          value={values.re_password.value}
          setValue={(value) => onChange("re_password", value)}
          onFocus={(value) => onFocus("re_password", value)}
          disabled={loading}
          showError={values.re_password.error}
          errorMessage={values.re_password.errorMessage}
        />
      </li>
      <li>
        <div className="gb-form-buttons-cont">
          <AppButton label="Start" onClick={onFinish} disabled={loading} />
          <AppButton
            label="Go back"
            onClick={() => onSection("WELCOME")}
            disabled={loading}
          />
        </div>
      </li>
      <li style={{ display: "flex", justifyContent: "center" }}>
        <button className="gb-link-button" onClick={() => onSection("LOGIN")}>
          Do you already have an account?
        </button>
      </li>
      <p data-visible={success} className="gb-signup-success">
        Your account was successfully registered.
      </p>
    </ul>
  );
};

export default Signup;
