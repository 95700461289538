import React, { useState } from "react";
import { useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import { SERVICES } from "./constants";
import { confirmAccountService } from "./services";
import Loader from "react-loader-spinner";

export const Confirm = () => {
  const [isLoading, setLoading] = useState(false);
  let { id } = useParams();

  useEffect(() => {
    confirmAccount();
    // eslint-disable-next-line
  }, [id]);

  const confirmAccount = async () => {
    try {
      setLoading(true);
      const { apiEndpoint } = SERVICES.confirmAccount;
      await confirmAccountService(apiEndpoint, id);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {isLoading && !id ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <Loader type="Oval" color="#030303" height={80} width={80} />
        </div>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
};
