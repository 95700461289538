export const getMonthlySales = async (
  property_id,
  setLoading,
  setData,
  isRoot
) => {
  try {
    setLoading(true);
    const url =
      "https://8tk4uvx7yb.execute-api.us-east-1.amazonaws.com/store/reports/getHotelDashboard";
    const options = {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify({ property_id, isRoot }),
    };

    const data = await fetch(url, options);
    const result = await data.json();
    setData(result);
  } catch (e) {
    console.log(e);
  } finally {
    setLoading(false);
  }
};
