import AxiosBuilder from "src/app/common/services/AxiosBuilder";
import { getJwtToken } from "src/app/common/utils/sessionUtils";

export const getAdminProperties = async (apiEndpoint, data) => {
  const token = await getJwtToken();

  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint)
      .withAuth(token)
      .withParams({ cognitoUserId: data.cognitoUserId })
      .GET()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
