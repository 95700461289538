export const SERVICES = {
  getReservationDetails: {
    apiEndpoint: "reservations/getReservationDetails",
  },
  getReservationData: {
    apiEndpoint: "reservations/getReservationData",
  },
  updateAdminCheckInStatus: {
    apiEndpoint: "reservations/updateReservationAdminCheckInStatus",
  },
  sendChatMessages: {
    apiEndpoint: "chat/sendMessage",
  },
  getChatMessages: {
    apiEndpoint: "chat/getChat",
  },
  getUnreadChatMessages: {
    apiEndpoint: "chat/getUnreadChatMessages",
  },
  getUnreadChatMessagesByProperty: {
    apiEndpoint: "chat/getUnreadChatMessagesByProperty",
  },
  updateReadAllChatMessages: {
    apiEndpoint: "chat/updateReadAllChatMessages",
  },
  getAdminProperties: {
    apiEndpoint: "reservations/getAdminProperties",
  },
  updateLateCheckout: {
    apiEndpoint: "extracharges/updateLateCheckout",
  },
  charge: {
    apiEndpoint: "stripe/charge",
  },
  refund: {
    apiEndpoint: "stripe/refund",
  },
  updateReservationRollbackStatus: {
    apiEndpoint: "reservations/updateReservationRollbackStatus",
  },
  reservationRelease: {
    apiEndpoint: "stripe/reservationRelease",
  },
  getPropertyLateCheckout: {
    apiEndpoint: "extracharges/getPropertyLateCheckout",
  },
  getLateCheckoutPaymentId: {
    apiEndpoint: "extracharges/getLateCheckoutPaymentId",
  },
  createAdminLog: {
    apiEndpoint: "admin/createAdminLog",
  },
  getFolioData: {
    apiEndpoint: "reservations/getFolioData",
  },
  updateSecurityBlock: {
    apiEndpoint: "reservations/updateSecurityBlock",
  },
};
