import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import "./styles.scss";
import { AppButton } from "src/app/components/AppButton";
import { useSelector } from "react-redux";
import { SERVICES } from "../constants";
import {
  getReservationDetailsService,
  updateAdminCheckInStatusService,
  updateReservationRollbackStatusService,
  reservationReleaseService,
  createAdminLogService,
} from "../services";
import { ADMIN_CHECKIN_STATUS_TYPES_TO_SEND } from "src/app/common/constants/AppConstants";
import { parseReservationDetails } from "../helper";
import { Storage } from "aws-amplify";
import Loader from "react-loader-spinner";
import DocumentNotFoundIcon from "src/app/assets/images/document-notfound-icon.svg";
import { SuccessIcon } from "src/app/components/SuccessIcon";
import { useDispatch } from "react-redux";
import { setWsMessageReceivedStatus } from "src/app/pages/Dashboard/actionCreators";
import jimp from "jimp";
import FlipIcon from "src/app/assets/images/flip-icon.svg";
import DocumentIcon from "src/app/assets/images/document-icon.svg";
import PhotoIcon from "src/app/assets/images/selfie-icon.png";
import { confirmAlert } from "react-confirm-alert";
import ReactTooltip from "react-tooltip";

export const ViewDocument = ({ onDismiss, selectedResId, setErrorOccured }) => {
  const { selectedProperty } = useSelector((state) => state.reservations);
  const [reservationDetails, setReservationDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [flipping, setFlipping] = useState(false);
  const [validationDisabled, setValidationDisabled] = useState(false);
  const [showActionButtons, setShowActionButtons] = useState(true);
  const [activeImage, setActiveImage] = useState("DOCUMENT");
  const dispatch = useDispatch();

  useEffect(() => {
    getReservationDetails();
    return () => {};
    // eslint-disable-next-line
  }, []);

  const handleAcceptClick = async () => {
    await updateAdminCheckInStatus();
    await getReservationDetails();
    dispatch(setWsMessageReceivedStatus(true));
  };

  const handleChangeActiveImage = async () => {
    if (activeImage === "DOCUMENT") {
      setActiveImage("PHOTO");
    } else {
      setActiveImage("DOCUMENT");
    }
  };

  const showRejectModal = () => {
    confirmAlert({
      title: "Reject Document",
      message: "Are you sure you want to reject this document?",

      buttons: [
        {
          label: "No",
          onClick: () => {},
        },
        {
          label: "Yes",
          onClick: () => {
            handleReject();
          },
        },
      ],
    });
  };

  const handleRejectClick = async () => {
    try {
      showRejectModal();
    } catch (error) {
      handleError();
    }
  };

  const handleReject = async () => {
    try {
      setLoading(true);
      const response = await reservationRelease();
      if (response) {
        await updateReservationRollbackStatus();
      }

      const data = {
        action: "Reject",
        message: "Reject document",
        reservationId: selectedResId,
        data: null,
      };
      await createAdminLog("View Document", JSON.stringify(data));

      await getReservationDetails();
    } catch (error) {
      handleError();
    } finally {
      setLoading(false);
    }
  };

  const updateReservationRollbackStatus = async () => {
    const { apiEndpoint } = SERVICES.updateReservationRollbackStatus;
    try {
      const response = await updateReservationRollbackStatusService(
        apiEndpoint,
        {
          reservationId: selectedResId,
        }
      );
      return response;
    } catch (error) {
      handleError();
    }
  };

  const reservationRelease = async () => {
    const { apiEndpoint } = SERVICES.reservationRelease;

    try {
      const response = await reservationReleaseService(apiEndpoint, {
        reservationId: selectedResId,
        propertyId: selectedProperty.value,
      });
      return response;
    } catch (error) {
      handleError();
    }
  };

  const getReservationDetails = async () => {
    setLoading(true);
    const { apiEndpoint } = SERVICES.getReservationDetails;
    try {
      const response = await getReservationDetailsService(apiEndpoint, {
        reservationId: selectedResId,
        propertyId: selectedProperty.value,
      });
      const { doc_s3_key, guest_photo_s3_key, cognito_identity_id } = response;
      const s3DocumentUrl = await getDocumentFromS3(
        doc_s3_key,
        cognito_identity_id,
        "protected"
      );

      const s3PhotoUrl = await getDocumentFromS3(
        guest_photo_s3_key,
        cognito_identity_id,
        "protected"
      );

      if (response.hasOwnProperty("admin_checkin_status")) {
        if (
          response.admin_checkin_status === "Doc-unverified" ||
          response.admin_checkin_status === null
        ) {
          setShowActionButtons(true);
        } else {
          setShowActionButtons(false);
        }
      } else {
        setShowActionButtons(true);
      }

      if (response.hasOwnProperty("check_in_status")) {
        if (response.check_in_status === "Web-check-in") {
          setValidationDisabled(false);
        } else {
          setValidationDisabled(true);
        }
      }

      response &&
        setReservationDetails(
          parseReservationDetails({
            ...response,
            s3_document_url: s3DocumentUrl,
            s3_photo_url: s3PhotoUrl,
          })
        );
    } catch (error) {
      handleError();
    } finally {
      setLoading(false);
    }
  };

  const getDocumentFromS3 = async (s3Key, cognitoIdentityId, level) => {
    try {
      const response = await Storage.get(s3Key, {
        level,
        identityId: cognitoIdentityId,
      });
      return response;
    } catch (err) {
      handleError(err);
    }
  };

  const updateAdminCheckInStatus = async () => {
    setLoading(true);
    const { apiEndpoint } = SERVICES.updateAdminCheckInStatus;
    const { guest_name, guest_phone_no } = reservationDetails;
    try {
      await updateAdminCheckInStatusService(apiEndpoint, {
        reservationId: selectedResId,
        adminCheckInStatus: ADMIN_CHECKIN_STATUS_TYPES_TO_SEND.DOC_VERIFIED,
        guestPhoneNo: guest_phone_no,
        guestName: guest_name,
      });

      const data = {
        action: "Accept",
        message: "Accept document",
        reservationId: selectedResId,
        data: {
          reservationId: selectedResId,
          adminCheckInStatus: ADMIN_CHECKIN_STATUS_TYPES_TO_SEND.DOC_VERIFIED,
          guestPhoneNo: guest_phone_no,
          guestName: guest_name,
        },
      };
      await createAdminLog("View Document", JSON.stringify(data));
    } catch (error) {
      handleError();
    } finally {
      setLoading(false);
    }
  };

  const handleError = (error) => {
    setLoading(false);
    setErrorOccured(true);
  };

  const handleFlipImage = async () => {
    setFlipping(true);
    const { apiEndpoint } = SERVICES.getReservationDetails;
    try {
      const response = await getReservationDetailsService(apiEndpoint, {
        reservationId: selectedResId,
        propertyId: selectedProperty.value,
      });
      const { doc_s3_key, guest_photo_s3_key, cognito_identity_id } = response;

      if (activeImage === "DOCUMENT") {
        const s3DocumentUrl = await flipDocumentFromS3(
          doc_s3_key,
          cognito_identity_id,
          "protected"
        );

        const s3PhotoUrl = await getDocumentFromS3(
          guest_photo_s3_key,
          cognito_identity_id,
          "protected"
        );

        response &&
          setReservationDetails(
            parseReservationDetails({
              ...response,
              s3_document_url: s3DocumentUrl,
              s3_photo_url: s3PhotoUrl,
            })
          );
      } else {
        const s3PhotoUrl = await flipDocumentFromS3(
          guest_photo_s3_key,
          cognito_identity_id,
          "protected"
        );

        const s3DocumentUrl = await getDocumentFromS3(
          doc_s3_key,
          cognito_identity_id,
          "protected"
        );

        response &&
          setReservationDetails(
            parseReservationDetails({
              ...response,
              s3_document_url: s3DocumentUrl,
              s3_photo_url: s3PhotoUrl,
            })
          );
      }

      const data = {
        action: "Rotate",
        message: "Rotate document",
        reservationId: selectedResId,
        data: {
          docS3Key: doc_s3_key,
          cognitoId: cognito_identity_id,
        },
      };
      await createAdminLog("View Document", JSON.stringify(data));
    } catch (error) {
      handleError();
    } finally {
      setFlipping(false);
    }
  };

  const flipDocumentFromS3 = async (s3Key, cognitoIdentityId, level) => {
    try {
      const imageUrl = await Storage.get(s3Key, {
        level,
        identityId: cognitoIdentityId,
      });

      const image = await jimp.read(imageUrl);
      image.rotate(90);
      const imgBuffer = await image.getBufferAsync(jimp.MIME_PNG);

      await Storage.put(s3Key, imgBuffer, {
        level,
        identityId: cognitoIdentityId,
        progressCallback(progress) {
          // eslint-disable-next-line
          const remainingPart = (progress.loaded / progress.total) * 100;
        },
      });

      const responseNew = await Storage.get(s3Key, {
        level,
        identityId: cognitoIdentityId,
      });

      return responseNew;
    } catch (err) {
      handleError(err);
    }
  };

  const createAdminLog = async (type, data) => {
    setLoading(true);
    const { apiEndpoint } = SERVICES.createAdminLog;
    try {
      const user = await Auth.currentUserInfo();

      const params = {
        propertyId: selectedProperty.value,
        adminCognitoId: user.attributes.sub,
        type: type,
        data: data,
      };
      await createAdminLogService(apiEndpoint, params);
    } catch (error) {
      handleError();
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      {loading ? (
        <div className="viewdocument-loader">
          <Loader type="Oval" color="#030303" height={50} width={50} />
        </div>
      ) : (
        <React.Fragment>
          <div className="view-document">
            <React.Fragment>
              <div className="photo-id">
                <div className="image-wrapper">
                  <img
                    src={
                      reservationDetails && activeImage === "DOCUMENT"
                        ? reservationDetails.s3_document_url
                        : reservationDetails.s3_photo_url
                    }
                    alt=""
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = DocumentNotFoundIcon;
                      setValidationDisabled(true);
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <div
                    data-tip
                    data-for="switchImage"
                    style={{ padding: "10px" }}
                  >
                    {activeImage === "DOCUMENT" ? (
                      <img
                        onClick={handleChangeActiveImage}
                        alt=""
                        style={{ cursor: "pointer" }}
                        src={PhotoIcon}
                        width="30px"
                        height="30px"
                      />
                    ) : (
                      <img
                        onClick={handleChangeActiveImage}
                        alt=""
                        style={{ cursor: "pointer" }}
                        src={DocumentIcon}
                        width="30px"
                        height="30px"
                      />
                    )}

                    <ReactTooltip id="switchImage" aria-haspopup="true">
                      <span>Switch image</span>
                    </ReactTooltip>
                  </div>

                  <div
                    data-tip
                    data-for="flipImage"
                    style={{ padding: "10px" }}
                  >
                    {flipping ? (
                      <Loader
                        type="Oval"
                        color="#030303"
                        height={30}
                        width={30}
                      />
                    ) : (
                      <img
                        onClick={handleFlipImage}
                        alt=""
                        style={{ cursor: "pointer" }}
                        src={FlipIcon}
                        width="30px"
                        height="30px"
                      />
                    )}
                    <ReactTooltip id="flipImage" aria-haspopup="true">
                      <span>Flip image</span>
                    </ReactTooltip>
                  </div>
                </div>
              </div>
            </React.Fragment>
          </div>

          <React.Fragment>
            {showActionButtons ? (
              <div className="btn-group">
                <button className="cancel-btn" onClick={handleRejectClick}>
                  Reject
                </button>

                <AppButton
                  className="active"
                  isDisabled={validationDisabled}
                  label="Looks Good"
                  onClick={handleAcceptClick}
                />
              </div>
            ) : (
              <div className="document-verified">
                <span className="document-verified-text">
                  Document verified <SuccessIcon />
                </span>
              </div>
            )}
          </React.Fragment>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
