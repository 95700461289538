import React, { useState, useEffect } from "react";
import "./index.scss";
import { getCognitoUser } from "src/app/common/utils/sessionUtils";
import Loader from "react-loader-spinner";
import InactiveIcon from "src/app/assets/images/inactive-icon.svg";
import ActiveIcon from "src/app/assets/images/active-icon.svg";
import EditIcon from "src/app/assets/images/edit-icon.svg";
import DeleteIcon from "src/app/assets/images/incidentals-delete-icon.svg";
import { AppButton } from "src/app/components/AppButton";
import Upsert from "./upsert";
import { confirmAlert } from "react-confirm-alert";
import { useHistory } from "react-router-dom";

const Properties = () => {
  const [loading, setLoading] = useState(false);
  const [properties, setProperties] = useState([]);
  const [user, setUser] = useState(null);
  const [open, setOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    getProperties();
  }, []);

  const getProperties = async () => {
    try {
      setLoading(true);

      const cognitoUserInfo = await getCognitoUser();
      setUser(cognitoUserInfo);
      const email = cognitoUserInfo?.attributes?.email;

      const options = {
        method: "POST",
        cors: true,
        body: JSON.stringify({ email }),
        headers: {
          "Content-Type": "application/json",
        },
      };
      const url =
        "https://qlsy48lvrg.execute-api.us-east-1.amazonaws.com/dev/getAllProperties";
      const data = await fetch(url, options);
      const result = await data.json();
      setProperties(result);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const onDelete = async (id) => {
    try {
      setLoading(true);

      const cognitoUserInfo = await getCognitoUser();
      const email = cognitoUserInfo?.attributes?.email;

      const options = {
        method: "POST",
        cors: true,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ property_id: id, rootEmail: email }),
      };

      const url =
        "https://qlsy48lvrg.execute-api.us-east-1.amazonaws.com/dev/deletePropertyById";
      const data = await fetch(url, options);
      await data.json();

      if (data.status === 200) {
        const newProperties = properties.filter((item) => item.id !== id);
        setProperties(newProperties);
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const showDeleteAlert = (id) => {
    try {
      confirmAlert({
        title: "Delete property",
        message: "Do you want to delete this property?",

        buttons: [
          {
            label: "No",
            onClick: () => { },
          },
          {
            label: "Yes",
            onClick: () => {
              onDelete(id);
            },
          },
        ],
      });
    } catch (error) {
      console.log(error);
    } finally {
      //setProcessing(false);
      //setDeleting(false);
    }
  };

  return (
    <div className="right-wrapper">
      <Upsert loading={loading} open={open} setOpen={setOpen} />
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <Loader type="Oval" color="#030303" height={50} width={50} />
        </div>
      ) : (
        <div className="tabs-wrapper">
          <h1>Properties</h1>

          {(user && user?.attributes["custom:role"] === "super_admin") ||
            user?.attributes["custom:role"] === "root" ? (
            <div
              style={{
                display: "flex",
                paddingLeft: "40px",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <AppButton
                isLoading={loading}
                label="Add"
                onClick={() => history.push("/properties/create")}
                isDisabled={loading}
              />
            </div>
          ) : null}
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: "40px",
          gap: "10px",
        }}
      >
        {properties.length > 0 && (
          <table className="extra-charges-table properties-table">
            <tbody>
              <tr>
                <th className="extra-charges-description">Name</th>
                <th className="extra-charges-description">E-mail</th>
                <th className="extra-charges-description">Address</th>
                <th className="extra-charges-description">City</th>
                <th className="extra-charges-description">State</th>
                <th className="extra-charges-date">GoorooBooster</th>
                <th className="extra-charges-date">Hold Amount</th>
                <th className="extra-charges-date">Roomkey domain</th>
                <th className="admin-action"></th>
              </tr>
              {properties.map((property, i) => {
                return (
                  <tr key={i} className={(i + 1) % 2 === 0 ? "even" : "odd"}>
                    <td className="extra-charges-description">
                      <span className="description-text">
                        {property.property_name ? property.property_name : ""}
                      </span>
                    </td>
                    <td className="extra-charges-amount">
                      {property.email ? property.email : ""}
                    </td>
                    <td className="extra-charges-date">
                      {property.address ? property.address : ""}
                    </td>
                    <td className="extra-charges-date">
                      {property.city ? property.city : ""}
                    </td>
                    <td className="extra-charges-date">
                      {property.state ? property.state : ""}
                    </td>
                    <td className="extra-charges-date properties-td-center">
                      {property.gooroo_booster ? (
                        <img
                          alt=""
                          src={ActiveIcon}
                          width="18px"
                          height="18px"
                        />
                      ) : (
                        <img
                          alt=""
                          src={InactiveIcon}
                          width="18px"
                          height="18px"
                        />
                      )}
                    </td>
                    <td
                      className="extra-charges-date"
                      style={{ textAlign: "right" }}
                    >
                      {property.hold_amount ? property.hold_amount : 0}
                    </td>

                    <td className="admin-action">
                      <span
                        className="extra-charges-delete"
                        onClick={() =>
                          history.push("/properties/create/" + property.id)
                        }
                      >
                        <img alt="" src={EditIcon} width="21px" height="21px" />
                      </span>
                      <span
                        className="extra-charges-delete"
                        onClick={() => showDeleteAlert(property.id)}
                      >
                        <img
                          alt=""
                          src={DeleteIcon}
                          width="21px"
                          height="21px"
                        />
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default Properties;
