import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { AppButton } from "src/app/components/AppButton";
import { useSelector } from "react-redux";
import "./styles.scss";
import { SERVICES } from "./constants";
import {
  getAdministratorsService,
  createAdminService,
  deleteAdminService,
  updateAdminService,
  createAdminLogService,
  sendAdminMessageService,
  getAdminChatService,
  updateReadAllAdminChatMessagesService,
} from "./services";
import { getCognitoUser } from "src/app/common/utils/sessionUtils";
import { TextField } from "src/app/components/TextField";
import { PhoneField } from "src/app/components/PhoneField";
import ChatIcon from "src/app/assets/images/chat-icon.svg";
import EditIcon from "src/app/assets/images/edit-icon.svg";
import DeleteIcon from "src/app/assets/images/incidentals-delete-icon.svg";
import IncidentalsIcon from "src/app/assets/images/zero-incidentals.svg";
import InactiveIcon from "src/app/assets/images/inactive-icon.svg";
import ActiveIcon from "src/app/assets/images/active-icon.svg";
import Loader from "react-loader-spinner";
import { getTimezoneOffsetRegion } from "src/app/common/utils/dateUtils";
import { deleteModal } from "./constants";
import { confirmAlert } from "react-confirm-alert";
import Select from "react-select";
import Popup from "reactjs-popup";
import CloseIcon from "src/app/assets/images/close-icon.svg";

import { Launcher } from "react-chat-window-modern";
import { useDispatch } from "react-redux";
import { setWsMessageReceivedStatus } from "src/app/pages/Dashboard/actionCreators";
import { isValidPhoneNumber } from "react-phone-number-input";

const Administrators = () => {
  const { selectedProperty, isWsMessageReceived } = useSelector(
    (state) => state.reservations
  );
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [toastMessage, setToastMessage] = useState("");
  // eslint-disable-next-line
  const [toastError, setToastError] = useState(false);
  // eslint-disable-next-line
  const [showToast, setShowToast] = useState(false);
  const [showErrorMessageName, setShowErrorMessageName] = useState(false);
  const [showErrorMessageEmail, setShowErrorMessageEmail] = useState(false);
  const [showErrorMessagePhone, setShowErrorMessagePhone] = useState(false);
  const [showErrorMessagePassword, setShowErrorMessagePassword] =
    useState(false);
  const [showErrorMessageRole, setShowErrorMessageRole] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [administrators, setAdministrators] = useState(null);
  const [id, setId] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [role, setRole] = useState("");
  const [active, setActive] = useState(true);
  const [user, setUser] = useState(null);
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line
  const [roles, setRoles] = useState([
    { value: "admin", label: "Admin" },
    { value: "basic_user", label: "Basic User" },
    { value: "housekeeper", label: "Housekeeper" },
    { value: "housekeeper_supervisor", label: "Housekeeper Supervisor" },
    { value: "super_admin", label: "Super Admin" },
  ]);
  const [messageList, setMessageList] = useState([]);
  const [showChat, setShowChat] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const [chatUser, setChatUser] = useState(null);

  const dispatch = useDispatch();

  const closeModal = () => {
    setEdit(false);
    setId(null);
    setOpen(false);
    clearFields();
    clearErrors();
  };

  useEffect(() => {
    if (selectedProperty) {
      getAdminByProperty();
      getUser();
    }
    // eslint-disable-next-line
  }, [selectedProperty]);

  useEffect(() => {
    if (isWsMessageReceived && selectedProperty && selectedProperty) {
      if (isOpen && chatUser) {
        getChat(chatUser);
      }
      getAdminByProperty();
    }
    // eslint-disable-next-line
  }, [isWsMessageReceived]);

  const getUser = async () => {
    const user = await getCognitoUser();
    /*if (user?.attributes["custom:role"] !== "super_admin") {
      history.push("/reservations");
    }*/

    setUser(user);
  };

  const getAdminByProperty = async () => {
    const { apiEndpoint } = SERVICES.getAdminByProperty;
    setLoading(true);
    try {
      const response = await getAdministratorsService(apiEndpoint, {
        propertyId: selectedProperty.value,
      });
      if (response) {
        const items = response.map((item, i) => {
          const administrator = {
            id: item.id,
            propertyId: item.property_id,
            cognitoUserId: item.cognito_user_id,
            cognitoIdentityId: item.cognito_identity_id,
            name: item.name,
            email: item.email,
            password: item.password,
            phoneNumber: item.phone_no,
            role: item.role,
            verified: item.verified,
            active: item.active,
            unreadMessages: item.unread_messages,
          };

          return administrator;
        });

        setAdministrators(items);
      } else {
        setAdministrators([]);
      }
    } catch (error) {
      handleError();
    } finally {
      setLoading(false);
    }
  };

  const handleError = (error) => {
    setLoading(false);
    // sending the value to parent to show toast error.
    // setErrorOccured(true);
  };

  const handleCancel = async () => {
    closeModal();
    setShowToast(false);

    setEdit(false);
    setId(null);
  };

  const handleEdit = async (id, index) => {
    handleNewAdministrator();
    setShowToast(false);
    const item = administrators[index];
    setName(item.name);
    setEmail(item.email);
    setPhoneNumber(item.phoneNumber);
    setRole(item.role);
    setEdit(true);
    setId(item.id);
    setActive(item.active);
  };

  const handleCreateAdmin = async () => {
    setShowToast(false);
    setRole("admin");
    await createAdmin();
  };

  const handleUpdateAdmin = async () => {
    setShowToast(false);
    await updateAdmin();
  };

  const createAdmin = async () => {
    setLoading(true);
    clearErrors();

    let fieldsValidated = true;
    const { apiEndpoint } = SERVICES.createAdmin;
    try {
      if (!name || name.trim() === "") {
        setErrorMessage("Name is required");
        setShowErrorMessageName(true);
        fieldsValidated = false;
      } else if (!email || email.trim() === "") {
        setErrorMessage("Email is required");
        setShowErrorMessageEmail(true);
        fieldsValidated = false;
      } else if (!phoneNumber || phoneNumber.trim() === "") {
        setErrorMessage("Phone is required");
        setShowErrorMessagePhone(true);
        fieldsValidated = false;
      } else if (!isValidPhoneNumber(phoneNumber)) {
        setErrorMessage("Please use a valid phone number");
        setShowErrorMessagePhone(true);
        fieldsValidated = false;
      } else if (!password || password.trim() === "") {
        setErrorMessage("Password is required");
        setShowErrorMessagePassword(true);
        fieldsValidated = false;
      } else if (!role || role.trim() === "") {
        setErrorMessage("Role is required");
        setShowErrorMessageRole(true);
        fieldsValidated = false;
      }

      if (fieldsValidated) {
        const res = await createAdminService(apiEndpoint, {
          propertyId: selectedProperty.value,
          name: name,
          email: email,
          password: password,
          phoneNumber: phoneNumber,
          role: role,
          active: active,
        });

        const data = {
          action: "Create",
          message: "Create administrator",
          reservationId: null,
          data: {
            propertyId: selectedProperty.value,
            name: name,
            email: email,
            password: password,
            phoneNumber: phoneNumber,
            role: role,
            active: active,
          },
        };
        await createAdminLog("Users", JSON.stringify(data));

        if (!res.hasOwnProperty("errorMessage")) {
          await getAdminByProperty();
          closeModal();
        } else {
          const obj = JSON.parse(res.errorMessage);

          throw new Error(obj.error);
        }
      }
    } catch (error) {
      setErrorMessage(error.message);
      setShowErrorMessage(true);
    } finally {
      setLoading(false);
    }
  };

  const updateAdmin = async () => {
    setLoading(true);
    const adminId = id;
    clearErrors();
    let fieldsValidated = true;

    const { apiEndpoint } = SERVICES.updateAdmin;

    try {
      if (!name || name.trim() === "") {
        setErrorMessage("Name is required");
        setShowErrorMessageName(true);
        fieldsValidated = false;
      } else if (!email || email.trim() === "") {
        setErrorMessage("Email is required");
        setShowErrorMessageEmail(true);
        fieldsValidated = false;
      } else if (!phoneNumber || phoneNumber.trim() === "") {
        setErrorMessage("Phone is required");
        setShowErrorMessagePhone(true);
        fieldsValidated = false;
      } else if (!role || role.trim() === "") {
        setErrorMessage("Role is required");
        setShowErrorMessageRole(true);
        fieldsValidated = false;
      }

      if (fieldsValidated) {
        const res = await updateAdminService(apiEndpoint, {
          id: adminId,
          propertyId: selectedProperty.value,
          name: name,
          email: email,
          password: password,
          phoneNumber: phoneNumber,
          role: role,
          active: active,
        });
        const data = {
          action: "Update",
          message: "Update administrator",
          reservationId: null,
          data: {
            id: adminId,
            propertyId: selectedProperty.value,
            name: name,
            email: email,
            password: password,
            phoneNumber: phoneNumber,
            role: role,
            active: active,
          },
        };
        await createAdminLog("Users", JSON.stringify(data));

        if (!res.hasOwnProperty("errorMessage")) {
          await getAdminByProperty();
          closeModal();
          setEdit(false);
          setId(null);
        } else {
          const obj = JSON.parse(res.errorMessage);
          throw new Error(obj.error);
        }
      }
    } catch (error) {
      setErrorMessage(error.message);
      setShowErrorMessage(true);
    } finally {
      setLoading(false);
    }
  };

  const deleteAdmin = async (id) => {
    setLoading(true);
    const { apiEndpoint } = SERVICES.deleteAdmin;

    try {
      await deleteAdminService(apiEndpoint, {
        propertyId: selectedProperty.value,
        id: id,
      });

      const data = {
        action: "Delete",
        message: "Delete User",
        reservationId: null,
        data: {
          propertyId: selectedProperty.value,
          id: id,
        },
      };
      await createAdminLog("Users", JSON.stringify(data));

      await getAdminByProperty();
      closeModal();

      setEdit(false);
      setId(null);
    } catch (error) {
      setToastMessage("Error to delete administrator");
      setToastError(true);
      setShowToast(true);
    } finally {
      setLoading(false);
    }
  };

  const handleNewAdministrator = async () => {
    setOpen((o) => !o);
  };

  const showDeleteAlert = (id) => {
    try {
      confirmAlert({
        title: deleteModal.title,
        message: deleteModal.message,

        buttons: [
          {
            label: deleteModal.no,
            onClick: () => {},
          },
          {
            label: deleteModal.yes,
            onClick: () => {
              deleteAdmin(id);
            },
          },
        ],
      });
    } catch (error) {
      handleError();
    }
  };

  const clearFields = async () => {
    setName("");
    setEmail("");
    setPhoneNumber("");
    setPassword("");
    setRole("");
    setActive(true);
    setShowPassword(true);
  };

  const clearErrors = async () => {
    setShowErrorMessageName(false);
    setShowErrorMessageEmail(false);
    setShowErrorMessagePhone(false);
    setShowErrorMessagePassword(false);
    setShowErrorMessageRole(false);
    setShowErrorMessage(false);
  };

  const createAdminLog = async (type, data) => {
    setLoading(true);
    const { apiEndpoint } = SERVICES.createAdminLog;
    try {
      const user = await Auth.currentUserInfo();

      const params = {
        propertyId: selectedProperty.value,
        adminCognitoId: user?.attributes.sub,
        type: type,
        data: data,
      };
      await createAdminLogService(apiEndpoint, params);
    } catch (error) {
      handleError();
    } finally {
      setLoading(false);
    }
  };

  const handleChatClick = async (id, index) => {
    try {
      const item = administrators[index];

      if (isOpen) {
        setShowChat(false);
        setIsOpen(false);
        await updateReadAllAdminChatMessages(item);
        dispatch(setWsMessageReceivedStatus(true));
      } else {
        setChatUser(item);
        await getChat(item);
        setShowChat(true);
        setIsOpen(true);
        await updateReadAllAdminChatMessages(item);
        dispatch(setWsMessageReceivedStatus(true));
      }
    } catch (error) {
      handleError();
    }
  };

  const handleOnMessageWasSent = async (message) => {
    const { apiEndpoint } = SERVICES.sendAdminMessage;
    try {
      const user = await Auth.currentUserInfo();
      if (user) {
        await sendAdminMessageService(apiEndpoint, {
          propertyId: selectedProperty.value,
          adminId: chatUser.id,
          sender: user?.attributes.name,
          receiver: chatUser.phoneNumber,
          message: message.data.text,
          isRead: true,
          isHousekeeper: true,
          name: user?.attributes.name,
        });
        await getChat(chatUser);
      }
    } catch (error) {
      handleError();
    }
  };

  const getChat = async (admin) => {
    const { apiEndpoint } = SERVICES.getAdminChat;
    try {
      const response = await getAdminChatService(apiEndpoint, {
        adminId: admin.id,
        propertyId: selectedProperty.value,
        timezoneRegion: getTimezoneOffsetRegion(),
      });
      response && setMessageList(response);
    } catch (error) {
      handleError();
    }
  };

  const updateReadAllAdminChatMessages = async (admin) => {
    const { apiEndpoint } = SERVICES.updateReadAllAdminChatMessages;
    try {
      await updateReadAllAdminChatMessagesService(apiEndpoint, {
        adminId: admin.id,
        isRead: true,
      });
    } catch (error) {
      handleError();
    }
  };

  return (
    <div className="right-wrapper">
      {loading && !administrators ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <Loader type="Oval" color="#030303" height={50} width={50} />
        </div>
      ) : (
        <div className="tabs-wrapper">
          <h1>Users</h1>
          {(user && user?.attributes["custom:role"] === "super_admin") ||
          user?.attributes["custom:role"] === "root" ? (
            <div
              style={{
                display: "flex",
                paddingLeft: "40px",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <AppButton
                label="Add"
                onClick={handleNewAdministrator}
                isDisabled={loading}
              />
            </div>
          ) : null}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              padding: "40px",
              gap: "10px",
            }}
          >
            <Popup
              className="popup-admin"
              open={open}
              closeOnDocumentClick={false}
              onClose={closeModal}
            >
              <div className="modal">
                <div className="close-button" onClick={!loading && closeModal}>
                  <img alt="" src={CloseIcon} width="24px" height="24px" />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "10px",
                  }}
                >
                  <h2>{edit ? "Edit User" : "Add User"}</h2>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "20px",
                  }}
                >
                  <TextField
                    extraStyles="text-field"
                    label="Name"
                    value={name}
                    setValue={setName}
                    disabled={loading}
                    showError={showErrorMessageName}
                    errorMessage={errorMessage}
                  />
                  <TextField
                    extraStyles="text-field"
                    label="Email"
                    value={email}
                    setValue={setEmail}
                    disabled={loading || edit ? true : false}
                    showError={showErrorMessageEmail}
                    errorMessage={errorMessage}
                  />

                  <PhoneField
                    extraStyles="text-field"
                    label="Phone"
                    defaultCountry="US"
                    value={phoneNumber}
                    setValue={setPhoneNumber}
                    disabled={loading || edit ? true : false}
                    showError={showErrorMessagePhone}
                    errorMessage={errorMessage}
                  />
                  {showPassword ? (
                    <TextField
                      extraStyles="text-field"
                      type="password"
                      label="Password"
                      placeHolder={edit ? "Change password" : "Set a password"}
                      value={password}
                      setValue={setPassword}
                      disabled={loading}
                      showError={showErrorMessagePassword}
                      errorMessage={errorMessage}
                    />
                  ) : null}
                </div>

                <div
                  style={{
                    paddingTop: "15px",
                  }}
                >
                  <Select
                    isClearable={false}
                    isSearchable={false}
                    placeholder="Role"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 4,
                      height: "100px",
                      colors: {
                        ...theme.colors,
                        primary25: "#FFD865",
                        primary50: "#FFD865",
                        primary: "black",
                      },
                    })}
                    style={{
                      width: `100px`,
                    }}
                    options={roles}
                    onChange={(event) => {
                      if (event) {
                        if (event.value === "basic_user") {
                          setShowPassword(false);
                          // need to change
                          setPassword("BasicUser123$");
                        } else {
                          setShowPassword(true);
                          // need to change
                          if (password === "BasicUser123$") {
                            setPassword("");
                          }
                        }
                        setRole(event.value);
                      } else {
                        setShowPassword(true);
                        setRole(null);
                      }
                    }}
                    isLoading={loading}
                    isDisabled={loading}
                    defaultValue={
                      edit && role
                        ? roles.filter((item) => item.value === role)
                        : roles.filter((item) => item.value === "admin")
                    }
                  />

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      color: "red",
                      paddingTop: "15px",
                    }}
                  >
                    {showErrorMessageRole ? errorMessage : ""}
                  </div>
                </div>

                <div>
                  <label className="checkbox-component">
                    <input
                      type="checkbox"
                      onChange={(event) => {
                        setActive(event.target.checked);
                      }}
                      checked={active}
                      disabled={loading}
                    />
                    <span className="checkmark"></span>
                    Active
                  </label>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    color: "red",
                    paddingTop: "15px",
                  }}
                >
                  {showErrorMessage ? errorMessage : ""}
                </div>

                <div className="btn-container-row">
                  <button
                    className="cancel-item-btn"
                    onClick={handleCancel}
                    disabled={loading}
                  >
                    <span>Cancel</span>
                  </button>

                  <AppButton
                    isLoading={loading}
                    label={!id ? "Save" : "Update"}
                    onClick={!edit ? handleCreateAdmin : handleUpdateAdmin}
                    isDisabled={loading}
                  />
                </div>
              </div>
            </Popup>

            {!administrators ||
              (administrators.length === 0 && (
                <div className="no-extra-charges">
                  <div className="no-extra-charges-icon">
                    <img
                      alt=""
                      src={IncidentalsIcon}
                      width="64px"
                      height="64px"
                    />
                  </div>
                  <div className="no-extra-charges-message">No users found</div>
                </div>
              ))}

            {administrators && administrators.length && (
              <div className="extra-charges-table-wrapper">
                <table className="extra-charges-table">
                  <tbody>
                    <tr>
                      <th className="extra-charges-description">Name</th>
                      <th className="extra-charges-description">E-mail</th>
                      <th className="extra-charges-date">Phone</th>
                      <th className="extra-charges-date">Role</th>
                      <th className="extra-charges-date">Verified</th>
                      <th className="extra-charges-date">Active</th>
                      <th className="admin-action"></th>
                    </tr>

                    {administrators.map((administrator, i) => {
                      return (
                        <tr
                          key={i}
                          className={(i + 1) % 2 === 0 ? "even" : "odd"}
                        >
                          <td className="extra-charges-description">
                            <span
                              className="description-text"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                if (
                                  (!loading &&
                                    user?.attributes["custom:role"] ===
                                      "super_admin") ||
                                  user?.attributes["custom:role"] === "root"
                                ) {
                                  handleEdit(administrator.id, i);
                                }
                              }}
                            >
                              {administrator.name ? administrator.name : ""}
                            </span>
                          </td>
                          <td className="extra-charges-amount">
                            {administrator.email ? administrator.email : ""}
                          </td>
                          <td className="extra-charges-date">
                            {administrator.phoneNumber
                              ? administrator.phoneNumber
                              : ""}
                          </td>

                          <td className="extra-charges-date">
                            {administrator.role === "admin" && "Admin"}
                            {administrator.role === "super_admin" &&
                              "Super Admin"}
                            {administrator.role === "housekeeper" &&
                              "Housekeeper"}
                            {administrator.role === "housekeeper_supervisor" &&
                              "Housekeeper Supervisor"}
                            {administrator.role === "basic_user" &&
                              "Basic User"}
                          </td>

                          <td className="extra-charges-date">
                            {administrator.verified ? (
                              <img
                                alt=""
                                src={ActiveIcon}
                                width="18px"
                                height="18px"
                              />
                            ) : (
                              <img
                                alt=""
                                src={InactiveIcon}
                                width="18px"
                                height="18px"
                              />
                            )}
                          </td>

                          <td className="extra-charges-date">
                            {administrator.active ? (
                              <img
                                alt=""
                                src={ActiveIcon}
                                width="18px"
                                height="18px"
                              />
                            ) : (
                              <img
                                alt=""
                                src={InactiveIcon}
                                width="18px"
                                height="18px"
                              />
                            )}
                          </td>

                          <td className="admin-action">
                            {user &&
                            user?.attributes &&
                            user?.attributes?.email !== administrator.email
                              ? administrator.role !== "root" && (
                                  <span
                                    className="extra-charges-delete"
                                    onClick={(e) => {
                                      if (!loading) {
                                        handleChatClick(administrator.id, i);
                                      }
                                    }}
                                  >
                                    {administrator.unreadMessages > 0 && (
                                      <span className="icon-badge">
                                        {administrator.unreadMessages}
                                      </span>
                                    )}

                                    <img
                                      alt=""
                                      src={ChatIcon}
                                      width="21px"
                                      height="21px"
                                    />
                                  </span>
                                )
                              : null}

                            {(user &&
                              user?.attributes["custom:role"] ===
                                "super_admin") ||
                            user?.attributes["custom:role"] === "root"
                              ? administrator.role !== "root" && (
                                  <span
                                    className="extra-charges-delete"
                                    onClick={(e) => {
                                      if (!loading) {
                                        handleEdit(administrator.id, i);
                                      }
                                    }}
                                  >
                                    <img
                                      alt=""
                                      src={EditIcon}
                                      width="21px"
                                      height="21px"
                                    />
                                  </span>
                                )
                              : null}

                            {(user &&
                              user &&
                              user?.attributes.email !== administrator.email &&
                              user?.attributes["custom:role"] ===
                                "super_admin") ||
                            (user?.attributes["custom:role"] === "root" &&
                              administrators.length >= 2 &&
                              administrator.role !== "root")
                              ? administrator.role !== "root" && (
                                  <span
                                    className="extra-charges-delete"
                                    onClick={(e) => {
                                      if (!loading) {
                                        showDeleteAlert(administrator.id);
                                      }
                                    }}
                                  >
                                    <img
                                      alt=""
                                      src={DeleteIcon}
                                      width="21px"
                                      height="21px"
                                    />
                                  </span>
                                )
                              : null}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>

          <div>
            {showChat && (
              <Launcher
                agentProfile={{
                  teamName: chatUser && chatUser.name,
                }}
                onMessageWasSent={handleOnMessageWasSent.bind(this)}
                messageList={messageList}
                showEmoji={false}
                newMessagesCount={0}
                isOpen={isOpen}
                handleClick={handleChatClick}
                placeholder="Write a message..."
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Administrators;
