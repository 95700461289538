import AxiosBuilder from "src/app/common/services/AxiosBuilder";

export const getAdminByEmailService = async (apiEndpoint, email) => {
  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        email: email,
      }),
    })
      .POST()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
