import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#fff",
    backgroundColor: "#fff",
    color: "#000",
    borderBottomWidth: 0,
    alignItems: "center",
    height: 16,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  reservationid: {
    textAlign: "left",
    width: "10%",
    fontStyle: "bold",
    fontSize: 10,
    fontWeight: 900,
  },
  datetime: {
    textAlign: "left",
    width: "10%",
    fontStyle: "bold",
    fontSize: 10,
    fontWeight: 900,
  },
  name: {
    textAlign: "left",
    width: "20%",
    fontStyle: "bold",
    fontSize: 10,
    fontWeight: 900,
  },
  room: {
    textAlign: "left",
    width: "10%",
    fontStyle: "bold",
    fontSize: 10,
    fontWeight: 900,
  },
  type: {
    textAlign: "left",
    width: "30%",
    fontStyle: "bold",
    fontSize: 10,
    fontWeight: 900,
  },
  quantity: {
    textAlign: "right",
    width: "10%",
    fontStyle: "bold",
    fontSize: 10,
    fontWeight: 900,
  },
  amount: {
    textAlign: "right",
    width: "10%",
    fontStyle: "bold",
    fontSize: 10,
    fontWeight: 900,
  },
});

const FolioTableHeader = () => (
  <View style={styles.container}>
    <Text style={styles.reservationid}>RES ID</Text>
    <Text style={styles.datetime}>DATE/TIME</Text>
    <Text style={styles.name}>NAME</Text>
    <Text style={styles.room}>ROOM</Text>
    <Text style={styles.type}>TYPE</Text>
    <Text style={styles.quantity}>QUANTITY</Text>
    <Text style={styles.amount}>AMOUNT</Text>
  </View>
);

export default FolioTableHeader;
