import moment from "moment";
import "moment-timezone";

// get current date
export const getCurrentDateInDateObj = () => {
  return new Date();
};

// get passed date in date obj
export const getDateInDateObj = (date) => {
  return new Date(date);
};

export const getFormattedDate = (date, format) => {
  if (date) {
    const date_object = getDateInDateObj(date);
    return moment(date_object).format(format);
  }
};

export const getCurrentDate = () => {
  const date_object = getCurrentDateInDateObj();
  return moment(date_object).format("MM/DD/YYYY");
};

/** get increment dates by the passed arg in date obj */
export const getIncrementDateByXinDateObj = (incrementBy) => {
  const currDate = getCurrentDateInDateObj();
  const tomorrowsDate = currDate.setDate(currDate.getDate() + incrementBy);
  return getDateInDateObj(tomorrowsDate);
};

/** get decrement dates by the passed arg in date obj */
export const getDecrementDateByXinDateObj = (decrementBy) => {
  const currDate = getCurrentDateInDateObj();
  const tomorrowsDate = currDate.setDate(currDate.getDate() - decrementBy);
  return getDateInDateObj(tomorrowsDate);
};

// get timezone region
export const getTimezoneOffsetRegion = () => {
  return moment.tz.guess("Z");
};
