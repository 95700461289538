import React, { useState } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import "./styles.scss";
import personPin from "src/app/assets/images/person-pin.svg";
import storePin from "src/app/assets/images/bussiness.svg";
import hotelPin from "src/app/assets/images/hotel-pin.svg";

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const containerStyle = {
  width: "100%",
  height: "100%",
};

const mapOptions = {
  styles: [
    {
      elementType: "labels",
      featureType: "poi.business",
      stylers: [{ visibility: "off" }],
    },
  ],
};

const Map = ({ mapProps, guests, locals, hotelCenter, onTrack }) => {
  const [openInfo, setOpenInfo] = useState(null);

  return (
    <div className="geofencing-map-main-container">
      <LoadScript googleMapsApiKey={API_KEY}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={mapProps.center}
          zoom={mapProps.zoom}
          options={mapOptions}
        >
          <Marker
            position={hotelCenter}
            icon={{
              url: hotelPin,
              size: { height: 60, width: 60 },
            }}
          />
          {locals.map((local, index) => (
            <Marker
              key={index}
              position={local.position}
              onMouseOver={() => setOpenInfo(local.id)}
              onMouseOut={() => setOpenInfo(null)}
              visible={local.visible}
              onClick={() => onTrack(local.id)}
              icon={{
                url: storePin,
                size: { height: 60, width: 27 },
              }}
            >
              {openInfo === local.id && (
                <InfoWindow>
                  <ul className="map-info-window">
                    <li>
                      <span>Local:</span> {local.name}
                    </li>
                    <li>
                      <span>Discount:</span> {local.discount}
                    </li>
                  </ul>
                </InfoWindow>
              )}
            </Marker>
          ))}
          {guests.map((guest) => (
            <React.Fragment>
              <Marker
                position={guest.position}
                onMouseOver={() => setOpenInfo(guest.id)}
                onMouseOut={() => setOpenInfo(null)}
                visible={guest.visible}
                zIndex={100}
                onClick={() => onTrack(guest.id)}
                icon={{
                  url: personPin,
                  size: { height: 36, width: 40 },
                }}
              >
                {openInfo === guest.id && (
                  <InfoWindow>
                    <ul className="map-info-window">
                      <li>
                        <span>Name:</span> {guest.name}
                      </li>
                      <li>
                        <span>Phone:</span> {guest.phone}
                      </li>
                      <li>
                        <span>Room:</span> {guest.room}
                      </li>
                      <li>
                        <span>Check-in:</span> {guest.checkIn}
                      </li>
                      <li>
                        <span>Check-out:</span> {guest.checkOut}
                      </li>
                    </ul>
                  </InfoWindow>
                )}
              </Marker>
            </React.Fragment>
          ))}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default Map;
