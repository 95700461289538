const moment = require("moment");

export const parseReservations = (data) => {
  try {
    const reservationData = data.map((reservation) => {
      const { checkout_date, checkin_date } = reservation;
      const checkout_data_object = new Date(checkout_date);
      const checkin_data_object = new Date(checkin_date);
      const diffTime = Math.abs(
        checkout_data_object.getTime() - checkin_data_object.getTime()
      );
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      reservation.no_of_nights = diffDays;
      reservation.checkin_date = moment(checkin_data_object)
        .utcOffset(0)
        .format("MM/DD/YYYY");
      reservation.checkout_date = moment(checkout_data_object)
        .utcOffset(0)
        .format("MM/DD/YYYY");

      if (reservation.room_no === "null") {
        reservation.room_no = null;
      }
      return reservation;
    });
    return { reservations: reservationData, parsingError: null };
  } catch (error) {
    //console.log("Error while parsing data!");
    return { parsingError: error, reservations: null };
  }
};

export const validateEmail = (email) => {
  const regex =
    // eslint-disable-next-line
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return !(!email || regex.test(email) === false);
};
