import React, { useState } from "react";
import { LoginForm } from "./LoginForm";
import { Redirect } from "react-router-dom";
import { Auth } from "aws-amplify";
import { SERVICES } from "./constants";
import { getAdminByEmailService } from "./services";

export const Home = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const handleLoginClick = async (event) => {
    try {
      event.preventDefault(true);

      const { apiEndpoint } = SERVICES.getAdminByEmail;

      const user = await getAdminByEmailService(apiEndpoint, username);

      if (user) {
        const phoneNumber = user.phone_no;
        await signInUser(phoneNumber, password);
      } else {
      }
    } catch (error) {
      setError(true);
    }
  };

  const signInUser = async (phoneNumber, password) => {
    try {
      setLoading(true);
      // eslint-disable-next-line
      const resp = await Auth.signIn({
        username: phoneNumber,
        password,
      });

      setIsLoggedIn(true);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const checkForButtonDisabled = () => {
    if (username && password) {
      return false;
    }
    return true;
  };

  const onChangeUsername = (value) => {
    if (isError) {
      setError(false);
    }
    setUsername(value);
  };

  const onChangePassword = (value) => {
    if (isError) {
      setError(false);
    }
    setPassword(value);
  };

  const isDisabled = checkForButtonDisabled();

  return (
    <>
      {isLoggedIn ? (
        <Redirect to="/reservations" />
      ) : (
        <LoginForm
          onLoginClick={handleLoginClick}
          username={username}
          password={password}
          setUsername={onChangeUsername}
          setPassword={onChangePassword}
          isDisabled={isDisabled}
          isLoading={isLoading}
          incorrectCredentialsError={isError}
        />
      )}
    </>
  );
};
