import React from "react";
import "./styles.scss";

export const RiskAlertIcon = () => {
  return (
    <span className="risk-alert-icon">
      <svg
        width="11"
        height="11"
        data-name="Layer 2"
        viewBox="0 0 38 38"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 .56A18.44 18.44 0 1 0 37.44 19 18.461 18.461 0 0 0 19 .56Z"
          fill="#b01a1a"
          class="fill-000000"
        ></path>
        <circle
          cx="19"
          cy="26.242"
          fill="#ffffff"
          r="1.878"
          class="fill-ffffff"
        ></circle>
        <path
          d="M18.969 21.569a1.2 1.2 0 0 1-1.2-1.145l-.425-8.883A1.652 1.652 0 0 1 19 9.82a1.652 1.652 0 0 1 1.65 1.731l-.487 8.881a1.2 1.2 0 0 1-1.194 1.137Z"
          fill="#ffffff"
          class="fill-ffffff"
        ></path>
      </svg>
    </span>
  );
};
