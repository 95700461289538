import React from "react";
import NoRecord from "src/app/assets/images/no-records.png";
import "./styles.scss";

export const NoRecordsFound = () => {
  return (
    <div className="no-records-wrapper">
      <div className="no-record-img">
        <img src={NoRecord} alt="no-records" />
      </div>
      <div className="no-records-heading">No records found</div>
      <div className="records-desc">There are no upcoming reservations</div>
    </div>
  );
};
