import React, { useEffect, useReducer, useState } from "react";
import "./styles.scss";
import Header from "./components/Header";
import Map from "./components/map";
import localsAround from "./mock-data/locals-around";
import { getReservationsService } from "./service";
import { useQuery } from "react-query";
import { SERVICES } from "./constants";
import moment from "moment";

const hotelCenter = {
  lat: 34.1057458970718,
  lng: -118.34212490236463,
};

const locations = [
  {
    lat: 34.104688196343254,
    lng: -118.34132023971058,
  },
  {
    lat: 34.10404352558248,
    lng: -118.34140748951445,
  },
  {
    lat: 34.10345275754678,
    lng: -118.3438214775145,
  },
  {
    lat: 34.104052409283554,
    lng: -118.34185810063869,
  },
  {
    lat: 34.104896356408666,
    lng: -118.3441272493913,
  },
  {
    lat: 34.10705060428364,
    lng: -118.34249110195749,
  },
  {
    lat: 34.106295513329975,
    lng: -118.34073157286858,
  },
  {
    lat: 34.105513764990405,
    lng: -118.3373949049338,
  },
  {
    lat: 34.103661525661025,
    lng: -118.34028632610253,
  },
  {
    lat: 34.104688196343255,
    lng: -118.34132023971059,
  },
];

const GeoFencing = () => {
  const [guests, setGuests] = useState([]);
  const [locals, setLocals] = useState(localsAround);
  const [localsCheck, setLocalsCheck] = useState(true);
  const [guestsCheck, setGuestsCheck] = useState(true);
  const [view, setView] = useState("MAP");
  const { key, apiEndpoint } = SERVICES.getReservations;
  const [mapProps, setMapProps] = useReducer(
    (current, next) => ({ ...current, ...next }),
    {
      center: {
        lat: 34.1057458970718,
        lng: -118.34212490236463,
      },
      zoom: 15,
    }
  );
  // eslint-disable-next-line
  const { data, status, error, isFetching, refetch } = useQuery(
    [key, apiEndpoint],
    () =>
      getReservationsService(apiEndpoint, {
        fromToDate: { fromDate: undefined, toDate: undefined },
        limit: 20,
        offset: 0,
        propertyId: 2,
        search: "",
        sortOn: { undefined: undefined },
        status: "CheckedIn",
        timezoneRegion: "America/Caracas",
      })
  );

  useEffect(() => {
    if (data) {
      const newGuests = [];

      for (const item of data) {
        let validation = [];
        let location = 0;
        do {
          location = locations[Math.floor(Math.random() * 10)];
          validation = newGuests.filter(
            // eslint-disable-next-line
            (newGuest) => newGuest.position.lat === location.lat
          );
        } while (validation.length > 0);

        const guest = {
          ...item,
          visible: true,
          phone: item.phone_number,
          room: item.room_no,
          checkIn: moment(item.checkin_date).format("DD/MM/YYYY"),
          checkOut: moment(item.checkout_date).format("DD/MM/YYYY"),
          position: location,
        };
        newGuests.push(guest);
      }

      setGuests(newGuests);
    }
  }, [data]);

  const onView = (e) => {
    setView(e.target.id);
  };

  const onCheck = (e) => {
    if (e.target.id === "locals") {
      if (!localsCheck) {
        setLocalsCheck(true);
        setLocals(localsAround);
      } else {
        setLocalsCheck(false);
        setLocals([]);
      }
    }
    if (e.target.id === "guests") {
      if (!guestsCheck) {
        setGuestsCheck(true);
        setGuests(
          guests.map((item) => {
            item.visible = true;
            return item;
          })
        );
      } else {
        setGuestsCheck(false);
        setGuests(
          guests.map((item) => {
            item.visible = false;
            return item;
          })
        );
      }
    }
  };

  const onTrack = (id) => {
    const markers = [...locals, ...guests];
    const marker = markers.find((item) => item.id === id);
    const newMapProps = {
      center: marker.position,
      zoom: 0,
    };
    setMapProps(newMapProps);
    setTimeout(() => setMapProps({ zoom: 20 }), 50);
  };

  const onChangeSearch = (value) => {
    onTrack(value.id);
  };

  return (
    <div className="right-wrapper">
      <div className="tabs-wrapper">
        <h1>Guests</h1>
        <Header
          view={view}
          onView={onView}
          onCheck={onCheck}
          localsCheck={localsCheck}
          guestsCheck={guestsCheck}
          guests={guests}
          locals={locals}
          onChangeSearch={onChangeSearch}
        />
        <Map
          mapProps={mapProps}
          guests={guests}
          locals={locals}
          hotelCenter={hotelCenter}
          onTrack={onTrack}
        />
      </div>
    </div>
  );
};

export default GeoFencing;
