import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useDispatch } from "react-redux";
import Sockette from "sockette";
import { Header } from "../Header";
import SideBar from "../SideBar";
import { setWsMessageReceivedStatus } from "../../actionCreators";
import {
  getCognitoUserPoolId,
  getCognitoUser,
} from "src/app/common/utils/sessionUtils";
import { SERVICES } from "./constants";
import { parseAdminPropertiesData } from "./helper";
import { getAdminProperties } from "./service";
import { useSelector } from "react-redux";

export const DashboardWrapper = ({ children }) => {
  const [isWSMessageReceived, setIsWSMessageReceived] = useState(false);
  const [adminPropertiesData, setAdminPropertiesData] = useState(null);
  const dispatch = useDispatch();
  const { selectedProperty } = useSelector((state) => state.reservations); // get selected property from global state

  useEffect(() => {
    if (isWSMessageReceived) {
      dispatch(setWsMessageReceivedStatus(true));
      setIsWSMessageReceived(false);
    }
  }, [isWSMessageReceived, dispatch]);

  useEffect(() => {
    const propertyId = selectedProperty ? selectedProperty.value : 2;
    if (propertyId) {
      const ws = new Sockette(
        `${process.env.REACT_APP_WS_URL}?propertyId=${propertyId}`,
        {
          timeout: 5e3,
          maxAttempts: 10,
          onopen: (e) => {
            // console.log("Connection id", ws.websocket && ws.websocket.id);
            // console.log(ws);
            // console.log("Connected! - Dashboard Wrapper", e);
          },
          onmessage: (e) => {
            // console.log("--WS Message Received--");
            setIsWSMessageReceived(true);
          },
          // onreconnect: (e) => console.log("Reconnecting...", e),
          // onmaximum: (e) => console.log("Stop Attempting!", e),
          onclose: (e) => {
            // console.log("Closed!", e);
            ws.reconnect();
          },
          onerror: (e) => console.log("Error while connecting ws:", e),
        }
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchAdminProperties();
  }, []);

  const fetchAdminProperties = async () => {
    try {
      const cognitoUserInfo = await getCognitoUser();
      const role = cognitoUserInfo?.attributes["custom:role"];

      let parsedData = null;

      if (role === "root") {
        const url =
          "https://qlsy48lvrg.execute-api.us-east-1.amazonaws.com/dev/getAllProperties";
        const options = {
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          cors: true,
          body: JSON.stringify({ email: cognitoUserInfo?.attributes.email }),
        };
        const data = await fetch(url, options);
        const result = await data.json();
        parsedData = parseAdminPropertiesData(
          result.map((item) => {
            item.property_id = item.id;
            return item;
          })
        );
      } else {
        const apiEndpoint = SERVICES.getAdminProperties.apiEndpoint;
        const cognitoUserId = await getCognitoUserPoolId();
        const data = await getAdminProperties(apiEndpoint, {
          cognitoUserId,
        });
        parsedData = parseAdminPropertiesData(data);
      }

      setAdminPropertiesData(parsedData);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <React.Fragment>
      <Header adminProperties={adminPropertiesData} />
      <div className="spacer70"></div>
      <div className="admin-wrapper">
        <SideBar />
        {children}
      </div>
    </React.Fragment>
  );
};
