import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const SkeletonLoader = ({ width, height }) => {
  return (
    <SkeletonTheme color="#EBEAE5" highlightColor="#fff">
      <Skeleton width={width} height={height} />
    </SkeletonTheme>
  );
};
