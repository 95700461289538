import React from "react";
import { useHistory } from "react-router";
import "./styles.scss";
import { AppButton } from "src/app/components/AppButton";
import loadingState from "src/app/assets/images/loading-state.png";

export const GenericError = () => {
  const history = useHistory();

  const handlePressRefresh = () => {
    history.go(0);
  };

  return (
    <div className="generic-error-wrapper">
      <div className="genericError-img">
        <img src={loadingState} alt="loading-state" />
      </div>
      <div className="error-text">
        Oops! An error occured while loading the records
      </div>
      <AppButton label="Refresh Page" onClick={handlePressRefresh} />
    </div>
  );
};
