import React from "react";
import "./styles.scss";
import { AppButton } from "src/app/components/AppButton";
import { FaMap, FaListUl } from "react-icons/fa";
import personPin from "src/app/assets/images/person-pin.svg";
import storePin from "src/app/assets/images/bussiness.svg";
import SearchBar from "./SearchBar";

const Header = ({
  onView,
  view,
  onCheck,
  localsCheck,
  guestsCheck,
  guests,
  locals,
  onChangeSearch,
}) => {
  return (
    <React.Fragment>
      <div className="geofencing-header-main-cont">
        <ul className="geofencing-header-left-cont">
          <li>
            <AppButton label="Dashboard" />
          </li>
          <li>
            <AppButton label="Stores" />
          </li>
          <li>
            <AppButton label="Promos" />
          </li>
        </ul>
        <ul className="geofencing-header-right-cont">
          <li>
            <button
              className="geofencing-header-view-option"
              title="Map view"
              id="MAP"
              onClick={onView}
              data-selected={view === "MAP" ? true : false}
            >
              <FaMap />
            </button>
          </li>

          <li>
            <button
              className="geofencing-header-view-option"
              title="List view"
              id="LIST"
              onClick={onView}
              data-selected={view === "LIST" ? true : false}
            >
              <FaListUl />
            </button>
          </li>
        </ul>
      </div>
      <div className="geofencing-header-main-cont">
        <ul className="geofencing-header-poi-cont">
          <li>
            <label htmlFor="locals" title="Stores">
              <input
                type="checkbox"
                name="locals"
                id="locals"
                onChange={onCheck}
                checked={localsCheck}
              />
              <img src={storePin} alt="" style={{ width: 22 }} />
            </label>
          </li>
          <li>
            <label htmlFor="guests" title="Guests">
              <input
                type="checkbox"
                name="guests"
                id="guests"
                onChange={onCheck}
                checked={guestsCheck}
              />
              <img src={personPin} alt="" />
            </label>
          </li>
        </ul>
        <div>
          <SearchBar
            items={[...guests, ...locals]}
            onChange={onChangeSearch}
            disabled={false}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
