import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import "./styles.scss";
import { useSelector, connect } from "react-redux";
import { SERVICES } from "./constants";
import {
  getReservationDataService,
  getChatMessages,
  getUnreadChatMessages,
  updateReadAllChatMessages,
  sendChatMessages,
  getAdminProperties,
  updateLateCheckoutService,
  chargeService,
  refundService,
  getPropertyLateCheckoutService,
  getLateCheckoutPaymentIdService,
  createAdminLogService,
  getFolioDataService,
  updateSecurityBlockService,
} from "./services";
import { getTimezoneOffsetRegion } from "src/app/common/utils/dateUtils";
import { parseReservationDetails } from "./helper";
import { SuccessIcon } from "src/app/components/SuccessIcon";
import { RiskAlertIcon } from "src/app/components/RiskAlertIcon";
import { WarningIcon } from "src/app/components/WarningIcon";
import FolioIcon from "src/app/assets/images/folio-icon.svg";
import NotesIcon from "src/app/assets/images/notes-icon.svg";
import UnlockIcon from "src/app/assets/images/unlock-icon.svg";
import LockIcon from "src/app/assets/images/lock-icon.svg";
import { Launcher } from "react-chat-window-modern";
import { ViewDocument } from "./ViewDocument";
import { Incidentals } from "./Incidentals";
import { Attachments } from "./Attachments";
import { getCognitoUserPoolId } from "src/app/common/utils/sessionUtils";
import { SkeletonLoader } from "src/app/components/SkeletonLoader";
import CloseIcon from "src/app/assets/images/close-icon.svg";
import { setWsMessageReceivedStatus } from "src/app/pages/Dashboard/actionCreators";
import { ExtraCharges } from "./ExtraCharges";
import { confirmAlert } from "react-confirm-alert";
import { ReservationManagement } from "../ReservationManagement";
import { AppButton } from "src/app/components/AppButton";
import { Notes } from "./Notes";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { FolioDocument } from "./Folio";
import ReactTooltip from "react-tooltip";

const moment = require("moment");

export const ReservationDetails = ({
  activeClassName,
  onDismiss,
  selectedResId,
  setErrorOccured,
  chatIsOpen = false,
}) => {
  const { selectedProperty, isWsMessageReceived } = useSelector(
    (state) => state.reservations
  );
  const [adminPropertiesData, setAdminPropertiesData] = useState(null);
  const [reservationDetails, setReservationDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [messageList, setMessageList] = useState([]);
  const [unreadChatMessages, setUnreadChatMessages] = useState(null);
  const [isOpen, setIsOpen] = useState(chatIsOpen);
  const [tab, setTab] = useState("VIEW_DOCUMENT");
  const [lateCheckoutChecked, setLateCheckoutChecked] = useState(false);
  const [lateCheckoutProcessing, setLateCheckoutProcessing] = useState(false);
  // eslint-disable-next-line
  const [lateCheckoutDisabled, setLateCheckoutDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [disableEdit, setDisableEdit] = useState(true);
  const [folioData, setFolioData] = useState(null);
  const [securityBlock, setSecurityBlock] = useState(null);

  useEffect(() => {
    document.getElementById("app-body").style.overflow = "hidden";
    loadReservationDetails();

    return () => {
      document.getElementById("app-body").style.overflow = "auto";
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {}, [securityBlock]);

  const loadReservationDetails = async () => {
    getReservationData();
    getFolioData();
    fetchAdminProperties();
    getChat();
    getUnreadMessages();
  };

  useEffect(() => {
    if (isWsMessageReceived) {
      getChat();
      getUnreadMessages();
    }
    // eslint-disable-next-line
  }, [isWsMessageReceived]);

  const fetchAdminProperties = async () => {
    const apiEndpoint = SERVICES.getAdminProperties.apiEndpoint;
    const cognitoUserId = await getCognitoUserPoolId();
    const data = await getAdminProperties(apiEndpoint, {
      cognitoUserId,
    });
    setAdminPropertiesData(data);
  };

  const getReservationData = async () => {
    const { apiEndpoint } = SERVICES.getReservationData;
    try {
      const response = await getReservationDataService(apiEndpoint, {
        reservationId: selectedResId,
        propertyId: selectedProperty.value,
      });

      if (
        response &&
        response.status !== "Canceled" &&
        response.status !== "No-show" &&
        response.status !== "Checked-out"
      ) {
        setDisableEdit(false);
      }

      if (response && response.late_checkout) {
        setLateCheckoutChecked(response.late_checkout);
      }

      if (response && response.security_block) {
        setSecurityBlock(response.security_block);
      }

      response &&
        setReservationDetails(
          parseReservationDetails({
            ...response,
          })
        );
    } catch (error) {
      handleError();
    } finally {
      setLoading(false);
    }
  };

  const handleError = (error) => {
    setLoading(false);
    setErrorOccured(true);
  };

  const getValueToShow = (data) => {
    return data ? data : "-";
  };

  const handleOnMessageWasSent = async (message) => {
    const { apiEndpoint } = SERVICES.sendChatMessages;
    try {
      if (reservationDetails) {
        await sendChatMessages(apiEndpoint, {
          propertyId: selectedProperty.value,
          pmsPropertyId: reservationDetails
            ? reservationDetails.pms_property_id
            : "186368",
          reservationId: selectedResId,
          sender: adminPropertiesData[0].name,
          receiver: reservationDetails.guest_phone_no,
          message: message.data.text,
          isRead: true,
        });
        setMessageList([...messageList, message]);
      }
    } catch (error) {
      handleError();
    }
  };

  const getChat = async () => {
    const { apiEndpoint } = SERVICES.getChatMessages;
    try {
      const response = await getChatMessages(apiEndpoint, {
        reservationId: selectedResId,
        propertyId: selectedProperty.value,
        timezoneRegion: getTimezoneOffsetRegion(),
      });
      response && setMessageList(response);
    } catch (error) {
      handleError();
    }
  };

  const getUnreadMessages = async () => {
    const { apiEndpoint } = SERVICES.getUnreadChatMessages;
    try {
      const response = await getUnreadChatMessages(apiEndpoint, {
        reservationId: selectedResId,
        propertyId: selectedProperty.value,
      });

      setUnreadChatMessages(response.messages);
    } catch (error) {
      handleError();
    }
  };

  const updateReadAllMessages = async () => {
    const { apiEndpoint } = SERVICES.updateReadAllChatMessages;

    try {
      await updateReadAllChatMessages(apiEndpoint, {
        reservationId: selectedResId,
        isRead: true,
      });
    } catch (error) {
      handleError();
    }
  };

  const handleChatClick = async () => {
    try {
      getChat();
      updateReadAllMessages();
      setUnreadChatMessages(0);
      if (isOpen) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    } catch (error) {
      handleError();
    }
  };

  const handleClickTab = (tab) => {
    setTab(tab);
  };

  const updateLateCheckout = async (checked, complementary) => {
    const { apiEndpoint } = SERVICES.updateLateCheckout;
    try {
      setLateCheckoutProcessing(true);
      const propertyLateCheckout = await getPropertyLateCheckout();

      let paymentResult = null;
      let paymentId = null;
      if (checked && !complementary) {
        paymentResult = await charge(
          propertyLateCheckout.late_checkout_description,
          propertyLateCheckout.late_checkout_amount
        );
        paymentId = paymentResult.id;
      } else if (!checked) {
        paymentId = await getLateCheckoutPaymentId();
        if (paymentId.payment_id) {
          paymentResult = await refund(paymentId.payment_id);
        }
      }

      if (
        (paymentResult && paymentResult.status === "succeeded") ||
        !paymentResult
      ) {
        setLateCheckoutProcessing(true);
        await updateLateCheckoutService(apiEndpoint, {
          reservationId: selectedResId,
          lateCheckout: checked,
          paymentId: paymentResult ? paymentResult.id : null,
        });

        const data = {
          action: checked ? "Create" : "Delete",
          message: checked ? "Create late check-out" : "Delete late check-out",
          reservationId: selectedResId,
          data: {
            reservationId: selectedResId,
            lateCheckout: checked,
            paymentId: paymentResult ? paymentResult.id : null,
          },
        };
        await createAdminLog("Late Check-out", JSON.stringify(data));

        setLateCheckoutChecked(checked);
        setTab("");
        setTab("EXTRA_CHARGES");
      }
    } catch (error) {
      handleError("Unable to proceed with the late check-out change");
    } finally {
      setLateCheckoutProcessing(false);
    }
  };

  const getLateCheckoutPaymentId = async () => {
    setLateCheckoutProcessing(true);
    const { apiEndpoint } = SERVICES.getLateCheckoutPaymentId;
    const { property_id } = reservationDetails;

    try {
      const paymentId = await getLateCheckoutPaymentIdService(apiEndpoint, {
        propertyId: property_id,
        reservationId: selectedResId,
      });

      return paymentId;
    } catch (error) {
    } finally {
      setLateCheckoutProcessing(false);
    }
  };

  const getPropertyLateCheckout = async () => {
    setLateCheckoutProcessing(true);
    const { apiEndpoint } = SERVICES.getPropertyLateCheckout;
    const { property_id } = reservationDetails;

    try {
      const propertyLateCheckout = await getPropertyLateCheckoutService(
        apiEndpoint,
        {
          propertyId: property_id,
        }
      );

      return propertyLateCheckout;
    } catch (error) {
      console.log(error);
    } finally {
      setLateCheckoutProcessing(false);
    }
  };

  const charge = async (description, amount) => {
    setLateCheckoutProcessing(true);
    const { apiEndpoint } = SERVICES.charge;
    const { property_id, payment_method_id } = reservationDetails;

    try {
      const paymentResult = await chargeService(apiEndpoint, {
        propertyId: property_id,
        paymentMethodId: payment_method_id,
        description: description,
        amount: amount,
      });

      return paymentResult;
    } catch (error) {
      console.log(error);
    } finally {
      setLateCheckoutProcessing(false);
    }
  };

  const refund = async (paymentId) => {
    setLateCheckoutProcessing(true);
    const { apiEndpoint } = SERVICES.refund;
    const { property_id } = reservationDetails;

    try {
      const refundResult = await refundService(apiEndpoint, {
        propertyId: property_id,
        paymentId: paymentId,
      });

      return refundResult;
    } catch (error) {
      console.log(error);
    } finally {
      setLateCheckoutProcessing(false);
    }
  };

  const showLateCheckoutModal = (checked) => {
    let action;
    if (checked) {
      action = "include";
    } else {
      action = "remove";
    }
    if (checked) {
      confirmAlert({
        title: "Late Check-out",
        message: `Are you sure you want to ${action} a late check-out fee?`,

        buttons: [
          {
            label: "No",
            onClick: () => {},
          },
          {
            label: "Yes",
            onClick: () => {
              updateLateCheckout(checked, false);
            },
          },
          {
            label: "Yes (Complementary)",
            onClick: () => {
              updateLateCheckout(checked, true);
            },
          },
        ],
      });
    } else {
      confirmAlert({
        title: "Late Check-out",
        message: `Are you sure you want to ${action} a late check-out fee?`,

        buttons: [
          {
            label: "No",
            onClick: () => {},
          },
          {
            label: "Yes",
            onClick: () => {
              updateLateCheckout(checked, false);
            },
          },
        ],
      });
    }
  };

  const createAdminLog = async (type, data) => {
    setLoading(true);
    const { apiEndpoint } = SERVICES.createAdminLog;
    try {
      const user = await Auth.currentUserInfo();

      const params = {
        propertyId: selectedProperty.value,
        adminCognitoId: user.attributes.sub,
        type: type,
        data: data,
      };
      await createAdminLogService(apiEndpoint, params);
    } catch (error) {
      handleError();
    } finally {
      setLoading(false);
    }
  };
  const handleOpen = async () => {
    setOpen((o) => !o);
  };

  const getFolioData = async () => {
    const { apiEndpoint } = SERVICES.getFolioData;
    try {
      let response = await getFolioDataService(apiEndpoint, {
        reservationId: selectedResId,
        propertyId: selectedProperty.value,
      });

      if (response) {
        const checkout_data_object = new Date(
          response.reservation.checkout_date
        );
        const checkin_data_object = new Date(response.reservation.checkin_date);
        const booking_data_object = new Date(response.reservation.booking_date);
        const diffTime = Math.abs(
          checkout_data_object.getTime() - checkin_data_object.getTime()
        );
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        response.reservation.no_of_nights = diffDays;
        response.reservation.checkin_date = moment(checkin_data_object)
          .utcOffset(0)
          .format("MM/DD/YYYY");
        response.reservation.checkout_date = moment(checkout_data_object)
          .utcOffset(0)
          .format("MM/DD/YYYY");

        response.reservation.booking_date = moment(booking_data_object)
          .utcOffset(0)
          .format("MM/DD/YYYY");
        setFolioData(response);
      }
    } catch (error) {
      handleError();
    } finally {
      setLoading(false);
    }
  };

  const updateSecurityBlock = async (securityBlock) => {
    const { apiEndpoint } = SERVICES.updateSecurityBlock;
    try {
      await updateSecurityBlockService(apiEndpoint, {
        reservationId: selectedResId,
        propertyId: selectedProperty.value,
        securityBlock: securityBlock,
      });
    } catch (error) {
      handleError("Unable to proceed the security block");
    }
  };

  const handleUpdateSecurityBlock = async () => {
    try {
      if (securityBlock) {
        setSecurityBlock(false);
        await updateSecurityBlock(false);
      } else {
        setSecurityBlock(true);
        await updateSecurityBlock(true);
      }
    } catch (error) {
      handleError();
    }
  };

  return (
    <>
      <ReservationManagement
        open={open}
        setOpen={setOpen}
        setErrorOccured={handleError}
        edit={true}
        reservationId={selectedResId && selectedResId}
        refreshReservationDetails={loadReservationDetails}
      />

      <div className="overlay-wrapper">
        <div className={`document-viewer-wrap ${activeClassName}`}>
          <div className="close-button" onClick={onDismiss}>
            <img alt="" src={CloseIcon} width="24px" height="24px" />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <span
              style={{
                color: "#4C4C4C",
                fontSize: "20px",
                fontWeight: "600",
                letterSpacing: "1.11px",
                linHeight: "30px",
                textAlign: "center",
              }}
            >
              Reservation Details
            </span>

            <AppButton
              isLoading={loading}
              label="Edit"
              onClick={handleOpen}
              isDisabled={loading || disableEdit}
            />
          </div>
          <React.Fragment>
            <div className="view-document">
              <div className="guest-header-details">
                <div className="user-name">
                  <span className="user-title">Guest</span>

                  {loading ? (
                    <span className="guest-name">
                      <SkeletonLoader width={100} height={10} />
                    </span>
                  ) : (
                    <React.Fragment>
                      <span className="guest-name">
                        {getValueToShow(
                          reservationDetails && reservationDetails.guest_name
                        )}
                      </span>
                      <span className="guest-email">
                        {getValueToShow(
                          reservationDetails && reservationDetails.guest_email
                        )}
                      </span>
                    </React.Fragment>
                  )}
                </div>

                <div className="user-name">
                  <span className="user-title">
                    HOLD RISK LEVEL
                    {reservationDetails &&
                    reservationDetails.hold_risk_level === "normal" ? (
                      <SuccessIcon />
                    ) : reservationDetails &&
                      reservationDetails.hold_risk_level ? (
                      <RiskAlertIcon />
                    ) : null}
                  </span>

                  {loading ? (
                    <span className="guest-name">
                      <SkeletonLoader width={100} height={10} />
                    </span>
                  ) : (
                    <React.Fragment>
                      <span className="risk-level">
                        {getValueToShow(
                          reservationDetails &&
                            reservationDetails.hold_risk_level &&
                            reservationDetails.hold_risk_level
                              .split("_")
                              .join(" ")
                        )}
                      </span>
                    </React.Fragment>
                  )}
                </div>
              </div>

              <div className="room-card-details">
                {reservationDetails && reservationDetails.credit_card_no ? (
                  <div className="card-details">
                    <span className="user-title">
                      Last four digits of card <SuccessIcon />
                    </span>
                    <span className="guest-name">
                      {getValueToShow(
                        reservationDetails && reservationDetails.credit_card_no
                      )}
                    </span>
                  </div>
                ) : reservationDetails &&
                  reservationDetails.reservation_credit_card ? (
                  <div className="card-details">
                    <span className="user-title">
                      Last four digits of card <SuccessIcon />
                    </span>
                    <span className="guest-name">
                      {getValueToShow(
                        reservationDetails &&
                          reservationDetails.reservation_credit_card
                      )}
                    </span>
                  </div>
                ) : (
                  <div className="noCredit-found">
                    <WarningIcon /> No credit card details available
                  </div>
                )}

                <div className="room-details">
                  <span className="user-title">ROOM NUMBER</span>
                  {loading ? (
                    <span className="guest-name">
                      <SkeletonLoader width={100} height={10} />
                    </span>
                  ) : (
                    <React.Fragment>
                      <span className="guest-name">
                        {getValueToShow(
                          reservationDetails && reservationDetails.room_no
                        )}
                      </span>
                    </React.Fragment>
                  )}
                </div>
              </div>

              <div className="guest-details-wrap">
                <div className="phone-number">
                  <span className="user-title">
                    NUMBER <SuccessIcon />
                  </span>

                  {loading ? (
                    <span className="guest-details">
                      <SkeletonLoader width={100} height={10} />
                    </span>
                  ) : (
                    <React.Fragment>
                      <span className="guest-details">
                        {getValueToShow(
                          reservationDetails &&
                            reservationDetails.guest_phone_no
                        )}
                      </span>
                    </React.Fragment>
                  )}
                </div>

                <div className="booking-id">
                  <span className="user-title">Booking ID/Nights</span>
                  {loading ? (
                    <span className="guest-details">
                      <SkeletonLoader width={100} height={10} />
                    </span>
                  ) : (
                    <React.Fragment>
                      <span className="guest-details">
                        {getValueToShow(
                          reservationDetails &&
                            reservationDetails.reservation_id
                        )}
                        /{" "}
                        {getValueToShow(
                          reservationDetails && reservationDetails.no_of_nights
                        )}{" "}
                        {reservationDetails &&
                        reservationDetails.no_of_nights > 1
                          ? "Nights"
                          : "Night"}
                      </span>
                    </React.Fragment>
                  )}
                </div>

                <div className="checkIn-date">
                  <span className="user-title">Check-in</span>
                  {loading ? (
                    <span className="guest-details">
                      <SkeletonLoader width={100} height={10} />
                    </span>
                  ) : (
                    <React.Fragment>
                      <span className="guest-details">
                        {getValueToShow(
                          reservationDetails && reservationDetails.checkin_date
                        )}
                        ,{" "}
                        {reservationDetails && reservationDetails.checkin_time}
                      </span>
                    </React.Fragment>
                  )}
                </div>

                <div className="checkOut-date">
                  <span className="user-title">Check-out</span>
                  {loading ? (
                    <span className="guest-details">
                      <SkeletonLoader width={100} height={10} />
                    </span>
                  ) : (
                    <React.Fragment>
                      <span className="guest-details">
                        {lateCheckoutChecked
                          ? `${getValueToShow(
                              reservationDetails &&
                                reservationDetails.checkout_date
                            )}, ${
                              reservationDetails &&
                              reservationDetails.late_checkout_time
                            }`
                          : `${getValueToShow(
                              reservationDetails &&
                                reservationDetails.checkout_date
                            )}, ${
                              reservationDetails &&
                              reservationDetails.checkout_time
                            }`}
                      </span>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                minHeight: "30px",
              }}
            >
              {loading || lateCheckoutProcessing ? (
                <span className="guest-details">
                  <SkeletonLoader width={100} height={10} />
                </span>
              ) : (
                <label className="checkbox-container">
                  <input
                    disabled={lateCheckoutDisabled}
                    type="checkbox"
                    onChange={(event) => {
                      showLateCheckoutModal(event.target.checked);
                    }}
                    checked={lateCheckoutChecked}
                  />
                  <span className="checkmark"></span>
                  Late Check-out
                </label>
              )}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "3px",
                  }}
                >
                  {folioData && !loading && (
                    <>
                      <img alt="" src={FolioIcon} width="24px" height="24px" />
                      <PDFDownloadLink
                        document={
                          <FolioDocument data={folioData && folioData} />
                        }
                        fileName={
                          reservationDetails
                            ? `${reservationDetails.reservation_id}-folio.pdf`
                            : "folio.pdf"
                        }
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Folio" : "Folio"
                        }
                      </PDFDownloadLink>
                    </>
                  )}
                </span>
                {folioData && !loading && (
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      gap: "3px",
                    }}
                    onClick={(e) => {
                      handleClickTab("NOTES");
                    }}
                  >
                    <img alt="" src={NotesIcon} width="24px" height="24px" />
                    Notes
                  </span>
                )}
                <div data-tip data-for="securityBlock">
                  {folioData &&
                  !loading &&
                  securityBlock &&
                  reservationDetails &&
                  !reservationDetails.pre_checkin ? (
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        gap: "5px",
                      }}
                      onClick={(e) => {
                        handleUpdateSecurityBlock();
                      }}
                    >
                      <img alt="" src={UnlockIcon} width="15px" height="15px" />
                      Unblock
                    </span>
                  ) : null}

                  {folioData &&
                  !loading &&
                  !securityBlock &&
                  reservationDetails &&
                  !reservationDetails.pre_checkin ? (
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        gap: "5px",
                      }}
                      onClick={(e) => {
                        handleUpdateSecurityBlock();
                      }}
                    >
                      <img alt="" src={LockIcon} width="15px" height="15px" />
                      Block
                    </span>
                  ) : null}

                  <ReactTooltip id="securityBlock" aria-haspopup="true">
                    <span>Security block for pre check-in</span>
                  </ReactTooltip>
                </div>
              </div>
            </div>

            <div className="tab-wrapper">
              <div className="tab-wrap">
                <ul className="tab-menu">
                  <li
                    key={"VIEW_DOCUMENT"}
                    id={"VIEW_DOCUMENT"}
                    onClick={(e) => {
                      handleClickTab("VIEW_DOCUMENT");
                    }}
                    className={tab === "VIEW_DOCUMENT" ? "activeTab" : ""}
                  >
                    <span
                      className={
                        tab === "VIEW_DOCUMENT" ? "tabitem-active" : "tabitem"
                      }
                    >
                      View Document
                    </span>
                  </li>
                  <li
                    key={"EXTRA_CHARGES"}
                    id={"EXTRA_CHARGES"}
                    onClick={(e) => {
                      handleClickTab("EXTRA_CHARGES");
                    }}
                    className={tab === "EXTRA_CHARGES" ? "activeTab" : ""}
                  >
                    <span
                      className={
                        tab === "EXTRA_CHARGES" ? "tabitem-active" : "tabitem"
                      }
                    >
                      Extra Charges
                    </span>
                  </li>
                  <li
                    key={"INCIDENTALS"}
                    id={"INCIDENTALS"}
                    onClick={(e) => {
                      handleClickTab("INCIDENTALS");
                    }}
                    className={tab === "INCIDENTALS" ? "activeTab" : ""}
                  >
                    <span
                      className={
                        tab === "INCIDENTALS" ? "tabitem-active" : "tabitem"
                      }
                    >
                      Incidentals
                    </span>
                  </li>
                  <li
                    key={"ATTACHMENTS"}
                    id={"ATTACHMENTS"}
                    onClick={(e) => {
                      handleClickTab("ATTACHMENTS");
                    }}
                    className={tab === "ATTACHMENTS" ? "activeTab" : ""}
                  >
                    <span
                      className={
                        tab === "ATTACHMENTS" ? "tabitem-active" : "tabitem"
                      }
                    >
                      Attachments
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="tab-container">
              {tab === "VIEW_DOCUMENT" && (
                <ViewDocument
                  onDismiss={onDismiss}
                  selectedResId={selectedResId}
                  setErrorOccured={setErrorOccured}
                />
              )}

              {tab === "EXTRA_CHARGES" && (
                <ExtraCharges
                  onDismiss={onDismiss}
                  selectedResId={selectedResId}
                  setErrorOccured={setErrorOccured}
                />
              )}

              {tab === "INCIDENTALS" && (
                <Incidentals
                  onDismiss={onDismiss}
                  selectedResId={selectedResId}
                  setErrorOccured={setErrorOccured}
                />
              )}

              {tab === "ATTACHMENTS" && (
                <Attachments
                  onDismiss={onDismiss}
                  selectedResId={selectedResId}
                  setErrorOccured={setErrorOccured}
                />
              )}

              {tab === "NOTES" && (
                <Notes
                  onDismiss={onDismiss}
                  selectedResId={selectedResId}
                  setErrorOccured={setErrorOccured}
                />
              )}
            </div>

            <div>
              <Launcher
                agentProfile={{
                  teamName: getValueToShow(
                    reservationDetails && reservationDetails.guest_name
                  ),
                }}
                onMessageWasSent={handleOnMessageWasSent.bind(this)}
                messageList={messageList}
                showEmoji={false}
                newMessagesCount={
                  Number(unreadChatMessages) > 0
                    ? Number(unreadChatMessages)
                    : 0
                }
                isOpen={isOpen}
                handleClick={handleChatClick}
                handleCancelClick={(e) => {
                  alert("close");
                }}
                placeholder="Write a message..."
              />
            </div>
          </React.Fragment>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { isWsMessageReceived } = state.reservations;
  return {
    isWsMessageReceived,
  };
};

const mapDispatchToProps = {
  setWsMessageReceivedStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservationDetails);
