import React, { useState, useEffect } from "react";
import { TextField } from "src/app/components/TextField";
import { AppButton } from "src/app/components/AppButton";
import { Auth } from "aws-amplify";

const Login = ({ onSection, setLoggedEmail }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [showErrorMessageName, setShowErrorMessageName] = useState("");
  // eslint-disable-next-line
  const [errorMessage, setErrorMessage] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    Auth.configure({
      aws_cognito_region: "us-east-1",
      aws_user_pools_id: process.env.REACT_APP_GB_USER_POOL_ID,
      aws_user_pools_web_client_id:
        process.env.REACT_APP_GB_USER_POOL_WEBCLIENT_ID,
      aws_cognito_identity_pool_id: process.env.REACT_APP_GB_IDENTITY_POOL_ID,
    });
  }, []);

  const onFinish = async () => {
    try {
      setLoading(true);
      const data = await Auth.signIn({
        username: email,
        password: password,
      });

      const gbEmail = data?.attributes.email;
      localStorage.setItem("gb-email", gbEmail);
      setLoggedEmail(gbEmail);

      window.location.reload();
    } catch (e) {
      setError(e.message);
      setTimeout(() => setError(""), 5000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ul className="gb-form-cont">
      <li>
        <TextField
          extraStyles="text-field"
          label="Email"
          value={email}
          setValue={setEmail}
          disabled={loading}
          showError={showErrorMessageName}
          errorMessage={errorMessage}
        />
      </li>
      <li>
        <TextField
          extraStyles="text-field"
          label="Password"
          type="password"
          value={password}
          setValue={setPassword}
          disabled={loading}
          showError={showErrorMessageName}
          errorMessage={errorMessage}
        />
      </li>
      <li>
        <div className="gb-form-buttons-cont">
          <AppButton label="Login" onClick={onFinish} disabled={loading} />
          <AppButton label="Go back" onClick={() => onSection("WELCOME")} />
        </div>
      </li>
      <li style={{ display: "flex", justifyContent: "center" }}>
        <button
          className="gb-link-button"
          onClick={() => onSection("SIGNUP")}
          disabled={loading}
        >
          Do not you have an account yet?
        </button>
      </li>
      <p data-visible={error ? true : false} className="gb-signup-error">
        {error}
      </p>
    </ul>
  );
};

export default Login;
