import AxiosBuilder from "src/app/common/services/AxiosBuilder";
import { getJwtToken } from "src/app/common/utils/sessionUtils";

export const getReservationsService = async (apiEndpoint, data) => {
  const token = await getJwtToken();
  if (data.status === "No-show") {
    data.status = "No-show";
  }
  return new Promise(function (resolve, reject) {
    new AxiosBuilder(apiEndpoint, {
      data: JSON.stringify({
        status: data && data.status,
        sortOn: data && data.sortOn ? data.sortOn : "",
        propertyId: data.propertyId,
        fromToDate: data && data.fromToDate ? data.fromToDate : "",
        limit: data.limit,
        offset: data.offset,
        timezoneRegion: data.timezoneRegion,
        search: data.search,
      }),
    })
      .withAuth(token)
      .POST()
      .then((response) => {
        let r = response?.data;
        if (response && response.data && typeof response.data === "string") {
          r = JSON.parse(response.data);
        }
        resolve(r);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
