import React from "react";
import "./styles.scss";

export const SuccessIcon = () => {
  return (
    <span className="success-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="11"
        viewBox="0 0 11 11"
      >
        <path
          fill="#41AF1A"
          d="M840.397762,186 C837.42047,186 835,188.420728 835,191.398338 C835,194.375947 837.42047,196.796675 840.397762,196.796675 C843.375054,196.796675 845.795524,194.375947 845.795524,191.398338 C845.795524,188.420728 843.375054,186 840.397762,186 Z M842.795505,190.523239 L840.306852,193.20536 C840.181852,193.341739 840.000032,193.421294 839.806849,193.421294 C839.613666,193.421294 839.44321,193.341739 839.306846,193.20536 L838.000019,191.784745 C837.750018,191.511987 837.761381,191.08012 838.03411,190.818727 C838.306839,190.568698 838.73866,190.580063 839.000026,190.852821 L839.818213,191.72792 L841.806862,189.57995 C842.068227,189.307192 842.488685,189.295827 842.772777,189.545855 C843.034143,189.818614 843.05687,190.250481 842.795505,190.523239 Z"
          transform="translate(-835 -186)"
        />
      </svg>
    </span>
  );
};
