import React, { useState, useEffect } from "react";
import "./styles.scss";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { calcSales as dateUtil } from "../../util";
import moment from "moment";
import { Auth } from "aws-amplify";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip
  //Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        padding: 50,
        font: {
          size: 14,
        },
      },
    },
    title: {
      display: false,
      text: "Earn monthly promotions",
    },
  },
};

// eslint-disable-next-line
const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "Octuber",
  "November",
  "December",
];

const formatData = (sales = []) => {
  const { labels: formatedLabels, data: dataSource } = dateUtil(sales);

  const data = {
    labels: formatedLabels,
    datasets: [
      {
        label: "Sales",
        data: dataSource,
        backgroundColor: "#FFE084",
      },
    ],
  };
  return data;
};
// eslint-disable-next-line
export default ({ monthlySales }) => {
  const [data, setData] = useState(null);
  const [user, setUser] = useState(null);
  // eslint-disable-next-line
  const [dataSource, setDataSource] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [restoreVisible, setRestoreVisible] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(0);
  const [selectedStore, setSelectedStore] = useState("All");

  useEffect(() => {
    if (monthlySales.length) {
      const data = formatData(
        monthlySales.map((item) => {
          item.store = "Starbucks";
          item.hotel_id = 2;
          return item;
        })
      );
      setData(data);
    }
  }, [monthlySales]);

  const getUser = async () => {
    const user = await Auth.currentUserInfo();
    if (user && user.hasOwnProperty("id")) {
      setUser(user);
    } /*else {
      handleLogout();
    }*/
  };

  useEffect(() => {
    getUser();
    return () => {};
  }, []);

  const onFinish = () => {
    const min = moment(startDate).unix();
    const max = moment(endDate).unix();

    let newDatasource = monthlySales.map((item) => {
      item.store = "Starbucks";
      item.hotel_id = 2;
      return item;
    });

    if (selectedProperty !== 0) {
      newDatasource = newDatasource.filter(
        (item) => item.hotel_id === selectedProperty
      );
    }

    if (selectedStore !== "All") {
      newDatasource = newDatasource.filter(
        (item) => item.store === selectedStore
      );
    }

    if (startDate && endDate) {
      newDatasource = newDatasource.filter((item) => {
        const itemDate = moment(item.date).unix();
        return itemDate >= min && itemDate <= max;
      });
    }

    setData(formatData(newDatasource));
    setRestoreVisible(true);
  };

  const onRestore = () => {
    const dataSource = monthlySales.map((item) => {
      item.store = "Starbucks";
      item.hotel_id = 2;
      return item;
    });
    setSelectedProperty("All");
    setSelectedStore("All");
    setStartDate("");
    setEndDate("");
    setData(formatData(dataSource));
    setRestoreVisible(false);
  };

  return (
    <div className="app-dataset-wrapper">
      <h2>Monthly Transactions in US Dollars</h2>
      <p>Last 12 Months.</p>
      <header className="app-dataset-header">
        <ul style={{ alignItems: "flex-end" }}>
          {user && user.attributes["custom:role"] === "root" ? (
            <li>
              <span>Property</span>
              <select
                value={selectedProperty}
                onChange={(e) => setSelectedProperty(e.target.value)}
              >
                <option value={0}>All</option>
                <option value={2}>Hollywood Hills Hotel</option>
                <option value={1}>Tuck Ventures</option>
              </select>
            </li>
          ) : null}
          <li>
            <span>Store</span>
            <select
              value={selectedStore}
              onChange={(e) => setSelectedStore(e.target.value)}
            >
              <option>All</option>
              <option>Apple</option>
              <option>McDonalds</option>
              <option>Starbucks</option>
            </select>
          </li>
          {/*           <li>
            <span>Start Date</span>
            <DateRangePicker
              format={"MM/dd/y"}
              calendarDate={startDate}
              getCalendarDate={value => setStartDate(new Date(value))}
              //minDate={new Date()}
            />            
          </li>
          <li>
          <span>End Date</span>
            <DateRangePicker
              format={"MM/dd/y"}
              calendarDate={endDate}
              getCalendarDate={value => setEndDate(new Date(value))}
              //minDate={new Date()}
            />               
          </li> */}
          <li>
            <button className="app-dataset-header-button" onClick={onFinish}>
              Apply
            </button>
          </li>
          {restoreVisible && (
            <li>
              <button
                className="app-dataset-header-restore-button"
                onClick={onRestore}
              >
                Restore
              </button>
            </li>
          )}
        </ul>
      </header>
      {data && <Bar options={options} data={data} height={100} />}
    </div>
  );
};
