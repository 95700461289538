import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import "./styles.scss";
import { AppButton } from "src/app/components/AppButton";
import { useSelector } from "react-redux";
import { SERVICES } from "./constants";
import {
  createNoteService,
  updateNoteService,
  getNotesService,
  deleteNoteService,
} from "./services";
import DeleteIcon from "src/app/assets/images/incidentals-delete-icon.svg";
import IncidentalsIcon from "src/app/assets/images/zero-incidentals.svg";
import Loader from "react-loader-spinner";
import { getTimezoneOffsetRegion } from "src/app/common/utils/dateUtils";
import { Toast } from "src/app/components/Toast";
import { TextAreaField } from "src/app/components/TextAreaField";

export const Notes = ({ onDismiss, selectedResId, setErrorOccured }) => {
  const { selectedProperty } = useSelector((state) => state.reservations);
  const [notes, setNotes] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [note, setNote] = useState(null);
  const [showNoteError, setShowNoteError] = useState(null);
  const [noteErrorMessage, setNoteErrorMessage] = useState(null);
  const [toastMessage, setToastMessage] = useState("");
  const [toastError, setToastError] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [edit, setEdit] = useState(false);
  const [noteId, setNoteId] = useState(null);

  useEffect(() => {
    getNotes();
    // eslint-disable-next-line
  }, []);

  const getNotes = async () => {
    const { apiEndpoint } = SERVICES.getNotes;
    try {
      setNotes(null);
      setLoading(true);
      const response = await getNotesService(apiEndpoint, {
        propertyId: selectedProperty.value,
        reservationId: selectedResId,
        timezoneRegion: getTimezoneOffsetRegion(),
      });

      response && setNotes(response);
    } catch (error) {
      setToastMessage("Error to get notes");
      setToastError(true);
      setShowToast(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteNote = async (id, index) => {
    setShowToast(false);

    const filteredItems = notes
      .slice(0, index)
      .concat(notes.slice(index + 1, notes.length));

    setNotes(filteredItems);
    await deleteNote(id);
  };

  const deleteNote = async (id) => {
    const { apiEndpoint } = SERVICES.deleteNote;
    setLoading(true);
    try {
      await deleteNoteService(apiEndpoint, {
        propertyId: selectedProperty.value,
        reservationId: selectedResId,
        id: id,
      });

      await getNotes();
    } catch (error) {
      setToastMessage("Error to delete a note");
      setToastError(true);
      setShowToast(true);
    } finally {
      setLoading(false);
      setNote("");
      setNoteId(null);
      setEdit(false);
      setSaving(false);
    }
  };

  const createNote = async () => {
    const { apiEndpoint } = SERVICES.createNote;
    setSaving(true);
    setShowToast(false);
    setNoteErrorMessage(null);
    setShowNoteError(false);

    let fieldsValidated = true;

    try {
      if (!note || note.trim() === "") {
        setNoteErrorMessage("Note is required");
        setShowNoteError(true);
        fieldsValidated = false;
      }

      const user = await Auth.currentUserInfo();

      if (fieldsValidated) {
        await createNoteService(apiEndpoint, {
          propertyId: selectedProperty.value,
          reservationId: selectedResId,
          note: note,
          deleteAvailable: true,
          createdBy: user.attributes.name,
        });

        await getNotes();
      }
    } catch (error) {
      setToastMessage(error.message);
      setToastError(true);
      setShowToast(true);
    } finally {
      setNote("");
      setSaving(false);
    }
  };

  const updateNote = async () => {
    const { apiEndpoint } = SERVICES.updateNote;
    setSaving(true);
    setShowToast(false);
    setNoteErrorMessage(null);
    setShowNoteError(false);

    let fieldsValidated = true;

    try {
      if (!note || note.trim() === "") {
        setNoteErrorMessage("Note is required");
        setShowNoteError(true);
        fieldsValidated = false;
      }

      if (fieldsValidated) {
        await updateNoteService(apiEndpoint, {
          propertyId: selectedProperty.value,
          reservationId: selectedResId,
          id: noteId,
          note: note,
        });

        await getNotes();
      }
    } catch (error) {
      setToastMessage(error.message);
      setToastError(true);
      setShowToast(true);
    } finally {
      setNote("");
      setNoteId(null);
      setEdit(false);
      setSaving(false);
    }
  };

  const handleCreateNote = async () => {
    await createNote();
  };

  const handleUpdateNote = async () => {
    await updateNote();
  };

  const handleEditNote = async (id, index) => {
    const selectedNote = notes[index];
    setEdit(true);
    setNoteId(selectedNote.id);
    setNote(selectedNote.note);
  };

  const handleCancel = async () => {
    setNote("");
    setNoteId(null);
    setEdit(false);
    setSaving(false);
  };

  return (
    <React.Fragment>
      <React.Fragment>
        {loading ? (
          <div className="incidentals-loader">
            <Loader type="Oval" color="#030303" height={50} width={50} />
          </div>
        ) : (
          <React.Fragment>
            <div className="attachment-container">
              <div>
                <TextAreaField
                  extraStyles="text-field-description"
                  label="Note"
                  value={note}
                  setValue={setNote}
                  disabled={saving}
                  showError={showNoteError}
                  errorMessage={noteErrorMessage}
                  placeHolder="Add a note"
                />

                <div className="btn-container-row">
                  {edit ? (
                    <button
                      className="cancel-item-btn"
                      onClick={handleCancel}
                      disabled={saving}
                    >
                      <span>Cancel</span>
                    </button>
                  ) : null}

                  <AppButton
                    isLoading={saving}
                    label={!edit ? "Save" : "Update"}
                    onClick={!edit ? handleCreateNote : handleUpdateNote}
                    isDisabled={saving}
                  />
                </div>
              </div>

              <div className="attachment-table">
                {!notes || notes.length === 0 ? (
                  <div className="no-incidentals">
                    <div className="no-incidentals-icon">
                      <img
                        alt=""
                        src={IncidentalsIcon}
                        width="64px"
                        height="64px"
                      />
                    </div>
                    <div className="no-incidentals-message">
                      No notes available
                    </div>
                  </div>
                ) : (
                  <div className="incidentals-table-wrapper">
                    <table className="incidentals-table">
                      <tbody>
                        <tr>
                          <th style={{ width: "250px" }}>Note</th>
                          <th className="incidentals-date">Date</th>
                          <th className="incidentals-date">User</th>
                          <th className="incidentals-action"></th>
                        </tr>

                        {notes.map((note, i) => {
                          return (
                            <tr
                              key={i}
                              className={(i + 1) % 2 === 0 ? "even" : "odd"}
                            >
                              <td style={{ width: "250px" }}>
                                <span
                                  style={{ cursor: "pointer" }}
                                  className="description-text"
                                  onClick={(e) => {
                                    if (note && note.deleteAvailable) {
                                      handleEditNote(note.id, i);
                                    }
                                  }}
                                >
                                  {note.note}
                                </span>
                              </td>

                              <td className="incidentals-date">
                                {note.createdAt}
                              </td>

                              <td className="incidentals-date">
                                {note.createdBy}
                              </td>

                              <td className="incidentals-action">
                                {note && note.deleteAvailable ? (
                                  <span
                                    className="incidentals-delete"
                                    onClick={(e) => {
                                      handleDeleteNote(note.id, i);
                                    }}
                                  >
                                    <img
                                      alt=""
                                      src={DeleteIcon}
                                      width="21px"
                                      height="21px"
                                    />
                                  </span>
                                ) : null}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </React.Fragment>
        )}

        <Toast
          message={toastMessage}
          activeClassName={showToast ? "active" : ""}
          onDismiss={() => setShowToast(false)}
          isError={toastError}
        />
      </React.Fragment>
    </React.Fragment>
  );
};
