import React, { useState, useEffect } from "react";
import "./styles.scss";
import { connect } from "react-redux";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { AppButton } from "src/app/components/AppButton";
import {
  chargeReservationService,
  updateReservationStatusToConfirmedService,
} from "./services";
import { SERVICES } from "./constants";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "rgb(240, 57, 122)",
      color: "#000",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "13px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#000",
      },
      "::placeholder": {
        color: "#a7a7a7",
      },
    },
    invalid: {
      iconColor: "#ff5656",
      color: "#ff5656",
    },
  },
};

const CardNumberField = ({ label, onChange }) => (
  <div className={`form-field`}>
    <label className="field-title">{label}</label>
    <div className="field-wrap">
      <CardNumberElement
        className="FormRowInput"
        options={CARD_OPTIONS}
        onChange={onChange}
      />
    </div>
  </div>
);

const CardExpiryField = ({ label, onChange }) => (
  <div className={`form-field`}>
    <label className="field-title">{label}</label>
    <div className="field-wrap">
      <CardExpiryElement
        className="FormRowInput"
        options={CARD_OPTIONS}
        onChange={onChange}
      />
    </div>
  </div>
);

const CardCvcField = ({ label, onChange, disabled = false }) => (
  <div className={`form-field`}>
    <label className="field-title">{label}</label>
    <div className="field-wrap">
      <CardCvcElement
        className="FormRowInput"
        options={CARD_OPTIONS}
        onChange={onChange}
      />
    </div>
  </div>
);

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
  disabled = false,
}) => (
  <div className={`form-field`}>
    <label htmlFor={id} className="field-title">
      {label}
    </label>
    <div className="field-wrap">
      <input
        className="input-field"
        id={id}
        type={type}
        placeholder={placeholder}
        required={required}
        autoComplete={autoComplete}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  </div>
);

const ErrorMessage = ({ children }) => (
  <div className="ErrorMessage" role="alert">
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#ff5656"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="#fff"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </div>
);

const CheckoutForm = ({
  propertyId,
  reservationId,
  description,
  amount,
  handleCancel,
  useDefaultPaymentMethod = false,
  processing,
  setProcessing,
  params,
  button,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [billingDetails, setBillingDetails] = useState({
    name: "",
    address: {
      postal_code: "",
    },
  });

  useEffect(() => {}, [reservationId, button]);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setError(null);

      if (!stripe || !elements) {
        return;
      }

      if (error) {
        if (elements.getElement("card")) {
          elements.getElement("card").focus();
        }
        return;
      }

      if (cardComplete) {
        setProcessing(true);

        const payload = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardNumberElement),
          billing_details: billingDetails,
        });

        if (payload.error) {
          setError(payload.error);
        } else {
          const myCard = elements.getElement(CardNumberElement);
          const token = await stripe.createToken(myCard);
          const { id } = payload.paymentMethod;

          const params = {
            reservationId: reservationId,
            propertyId: propertyId,
            stripeToken: token.token.id,
            paymentMethodId: id,
            cardLastNumber: payload.paymentMethod.card.last4,
            description: description,
            amount: amount,
            useDefaultPaymentMethod: useDefaultPaymentMethod,
          };

          const response = await chargeReservationService(params);

          if (response && response.hasOwnProperty("id")) {
            await updateReservationStatusToConfirmed();
            handleCancel();
          } else {
            if (response.hasOwnProperty("errorMessage")) {
              const obj = JSON.parse(response.errorMessage);
              throw new Error(obj.error);
            } else {
              const responseError = new Error(
                "Payment declined, please try again."
              );
              throw responseError;
            }
          }
        }
      } else {
        const fieldsError = new Error("Please fill out all required fields");
        throw fieldsError;
      }
    } catch (error) {
      setError(error);
      setProcessing(false);
    } finally {
      setProcessing(false);
    }
  };

  const updateReservationStatusToConfirmed = async () => {
    const { apiEndpoint } = SERVICES.updateReservationStatusToConfirmed;
    setProcessing(true);

    try {
      const response = await updateReservationStatusToConfirmedService(
        apiEndpoint,
        {
          reservationId: reservationId,
          propertyId: propertyId,
        }
      );
      return response;
    } catch (error) {
      setError(error);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <form className="form" onSubmit={handleSubmit}>
      <fieldset className="form-group">
        <Field
          label="Name"
          id="name"
          type="text"
          placeholder="Name on the card"
          required
          autoComplete="name"
          value={billingDetails.name}
          onChange={(e) => {
            setBillingDetails({ ...billingDetails, name: e.target.value });
          }}
          disabled={processing}
        />

        <CardNumberField
          label="Card #"
          name="number"
          onChange={(e) => {
            setError(e.error);
            setCardComplete(e.complete);
          }}
        />
        <CardExpiryField
          label="Due Date"
          onChange={(e) => {
            setError(e.error);
            setCardComplete(e.complete);
          }}
        />

        <CardCvcField
          label="CVC"
          onChange={(e) => {
            setError(e.error);
            setCardComplete(e.complete);
          }}
        />
        <Field
          label="ZIP Code"
          id="postal_code"
          type="text"
          placeholder="ZIP Code"
          required
          autoComplete="postal_code"
          value={billingDetails.address.postal_code}
          onChange={(e) => {
            setBillingDetails({
              ...billingDetails,
              address: { postal_code: e.target.value },
            });
          }}
          disabled={processing}
        />
      </fieldset>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          color: "#4C4C4C",
          paddingTop: "15px",
        }}
      >
        <span>{`Sub total: $${params.subTotal}`} </span>
        <span style={{ paddingTop: "5px" }}>
          {`City Tax: $${params.cityTaxAmount}`}{" "}
        </span>
        <span style={{ paddingTop: "5px" }}>
          {`Occupancy Tax: $${params.occupancyTaxAmount}`}{" "}
        </span>
        <span style={{ paddingTop: "5px" }}>
          {`Amenity Fee: $${params.amenityFee}`}{" "}
        </span>
        <span style={{ paddingTop: "5px" }}>
          {`Other Fees: $${
            params.otherFeesAmount ? params.otherFeesAmount : 0.0
          }`}{" "}
        </span>
        <span
          style={{
            paddingTop: "10px",
            fontWeight: "600",
            paddingBottom: "5px",
          }}
        >
          {`Grand Total: $${params.grandTotal}`}{" "}
        </span>
      </div>

      {error && <ErrorMessage>{error.message}</ErrorMessage>}

      <div className="btn-container-row">
        <button
          className="cancel-item-btn"
          onClick={handleCancel}
          disabled={processing}
        >
          <span>Cancel</span>
        </button>

        <AppButton
          isLoading={processing}
          label={button}
          error={error}
          isDisabled={!stripe || processing}
        />
      </div>
    </form>
  );
};

const mapDispatchToProps = {};

CheckoutForm.propTypes = {};

export default connect(null, mapDispatchToProps)(CheckoutForm);
