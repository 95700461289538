import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import "./styles.scss";
import readXlsxFile from "read-excel-file";
import DataTable from "react-data-table-component";
import { useDropzone } from "react-dropzone";
import { HiOutlineCheckCircle, HiOutlineCloudArrowUp } from "react-icons/hi2";
import { cloudbedsSchema, defaultSchema } from "./Schemas";
import { DATATABLE_COLUMNS } from "./constants";
import TheBrightHotelLogo from "../../assets/images/bright-logo.svg";
import CloudbedsLogo from "../../assets/images/cloudbeds-logo.svg";
import { AppButton } from "src/app/components/AppButton";

import { SERVICES } from "./constants";
import { createImportReservationsService } from "./service.js";

const ImportReservations = () => {
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [uploadingMessage, setUploadingMessage] = useState("Uploading...");
  const [xlsData, setXlsData] = useState(null);
  const [source, setSource] = useState("DEFAULT");
  const [screen, setScreen] = useState("SOURCES");
  const [fileName, setFileName] = useState("");
  const { selectedProperty } = useSelector((state) => state.reservations);

  const handleSelectSource = (sourceProvider) => {
    setSource(sourceProvider);
    setScreen("UPLOAD");
  };

  const handleCancel = () => {
    setSource("DEFAULT");
    setScreen("SOURCES");
    setFileName("");
    setXlsData(null);
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    setLoading(true);
    try {
      const files = acceptedFiles;
      setFileName(files[0].name);

      //const filesArr = Array.prototype.slice.call(files);

      let schema = null;
      switch (source) {
        case "CLOUDBEDS":
          schema = cloudbedsSchema;
          break;
        case "DEFAULT":
          schema = defaultSchema;
          break;

        default:
          schema = defaultSchema;
          break;
      }

      await readXlsxFile(files[0], { schema }).then(({ rows, errors }) => {
        setXlsData(rows);
        setScreen("DATATABLE");
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);
  // eslint-disable-next-line
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleConfirm = async () => {
    setLoading(true);
    try {
      await createImportReservations();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const createImportReservations = async () => {
    setLoading(true);
    try {
      const { apiEndpoint } = SERVICES.createImportReservations;
      const propertyId = selectedProperty.value;

      const data = {
        propertyId: propertyId,
        reservationsJson: JSON.stringify(xlsData),
        filename: fileName,
      };
      const response = await createImportReservationsService(apiEndpoint, data);
      return response;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (screen === "SOURCES") {
    return (
      <div className="right-wrapper">
        <div className="tabs-wrapper">
          <h1>Import Reservations</h1>
          <p className="subtitle">Select the source provider</p>
        </div>
        <div className="sources-wrapper">
          <div
            className="source-item"
            onClick={() => {
              handleSelectSource("DEFAULT");
            }}
          >
            <img src={TheBrightHotelLogo} alt="The Bright Hotel" />
          </div>
          <div
            className="source-item"
            onClick={() => {
              handleSelectSource("CLOUDBEDS");
            }}
          >
            <img src={CloudbedsLogo} alt="Cloudbeds" />
          </div>
        </div>
      </div>
    );
  }

  if (screen === "UPLOAD") {
    return (
      <div className="right-wrapper">
        <div className="tabs-wrapper">
          <h1>Import Reservations</h1>
          <p className="subtitle">
            Upload a .xlsx file exported from your source provider
          </p>
        </div>

        <div className="upload-wrapper">
          <section className="import-dropzone-container">
            <div {...getRootProps({ className: "import-dropzone" })}>
              <input {...getInputProps()} />

              {!loading ? (
                <>
                  <HiOutlineCloudArrowUp color="#FFD865" size="8em" />
                  <p>Choose a file or drag it here.</p>
                </>
              ) : (
                <div>
                  <p>{uploadingMessage}</p>
                </div>
              )}
            </div>
          </section>
        </div>

        <div className="buttons-wrapper">
          <button
            className="cancel-item-btn"
            onClick={handleCancel}
            disabled={loading}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }

  if (screen === "DATATABLE") {
    return (
      <div className="right-wrapper">
        <div className="tabs-wrapper">
          <h1>Import Reservations</h1>
          <p className="subtitle">
            Please validate and confirm your reservations
          </p>
        </div>

        {xlsData ? (
          <div className="datatable-wrapper">
            <DataTable
              columns={DATATABLE_COLUMNS}
              data={xlsData ? xlsData : []}
              pagination
              paginationPerPage={5}
              paginationComponentOptions={{ noRowsPerPage: true }}
              style={{ border: "1px solid red" }}
            />
          </div>
        ) : null}

        <div className="buttons-wrapper">
          <button
            className="cancel-item-btn"
            onClick={handleCancel}
            disabled={loading}
          >
            Cancel
          </button>

          <AppButton
            className=""
            isLoading={loading}
            label={"Confirm"}
            onClick={handleConfirm}
            isDisabled={loading}
          />
        </div>
      </div>
    );
  }

  if (screen === "SUCCESS") {
    return (
      <div className="right-wrapper">
        <div className="tabs-wrapper">
          <h1>Import Reservations</h1>
          <p className="subtitle">Reservations uploaded successfully</p>
        </div>

        <div className="success-wrapper">
          <HiOutlineCheckCircle color="#69C656" size="8em" />
          <p className="success-text">
            Our system is processing your reservations. <br />
            You will be notified when it's completed.
          </p>
        </div>
      </div>
    );
  }
};

export default ImportReservations;
