const moment = require("moment");
export const parseReservationDetails = (reservation) => {
  try {
    const { checkout_date, checkin_date } = reservation;

    const checkout_data_object = new Date(checkout_date);
    const checkin_data_object = new Date(checkin_date);
    const diffTime = Math.abs(
      checkout_data_object.getTime() - checkin_data_object.getTime()
    );

    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    reservation.no_of_nights = diffDays;
    reservation.checkin_date = moment(checkin_data_object)
      .utcOffset(0)
      .format("MM/DD/YYYY");
    reservation.checkout_date = moment(checkout_data_object)
      .utcOffset(0)
      .format("MM/DD/YYYY");

    if (
      !reservation.checkin_date ||
      reservation.checkin_date === "Invalid date"
    ) {
      reservation.checkin_date = null;
    }

    if (
      !reservation.checkout_date ||
      reservation.checkout_date === "Invalid date"
    ) {
      reservation.checkout_date = null;
    }

    return reservation;
  } catch (error) {
    console.log(error);
  }
};
export const parseAdminPropertiesData = (data) => {
  const adminProperties = data.map((item) => {
    return {
      value: item.property_id,
      label: item.property_name,
    };
  });
  return adminProperties;
};
