import * as types from "./actionTypes";

export const saveReservations = (reservations) => {
  return { type: types.SAVE_RESERVATIONS, payload: reservations };
};

export const setReservationsCount = (status, count) => {
  return { type: types.SET_RESERVATIONS_COUNT, payload: { status, count } };
};

export const setReservationStatus = (status) => {
  return { type: types.SET_RESERVATION_STATUS, payload: { status } };
};

export const setPressedHeaderTab = (pressedHeaderTab) => {
  return { type: types.SET_PRESSED_HEADER_TAB, payload: pressedHeaderTab };
};

export const setWsMessageReceivedStatus = (status) => {
  return { type: types.SET_WS_MESSAGE_RECEIVED_STATUS, payload: { status } };
};

export const setPageOffset = (tab, pageNumber) => {
  return { type: types.SET_PAGE_OFFSET, payload: { tab, pageNumber } };
};

export const setPropertyId = (selectedProperty) => {
  return { type: types.SET_PROPERTY, payload: selectedProperty };
};
