import React from "react";
import "./styles.scss";
import PropTypes from "prop-types";
import SearchIcon from "src/app/assets/images/searchIcon.svg";
import ClearIcon from "src/app/assets/images/clearIcon.svg";

export const SearchField = ({
  placeholder,
  extraStyles,
  type,
  value,
  setValue,
  showError,
  disabled,
  errorMessage,
}) => {
  return (
    <div className={`form-field ${extraStyles}`}>
      <div className="field-wrap">
        <input
          placeholder={placeholder}
          type={type ? type : "text"}
          className="input-field"
          value={value}
          onChange={(event) => setValue(event.target.value)}
          min="0"
          disabled={disabled}
        />
        <div className="search-icon">
          {value !== "" ? (
            <img
              style={{ cursor: "pointer" }}
              src={ClearIcon}
              alt=""
              onClick={() => {
                setValue("");
              }}
            />
          ) : (
            <img src={SearchIcon} alt="" />
          )}
        </div>
      </div>
    </div>
  );
};

SearchField.propTypes = {
  label: PropTypes.string,
  extraStyles: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func,
  showError: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
};
