import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./styles.scss";
import { RESERVATIONS_TABS } from "../constants";
import { setPressedHeaderTab } from "../../../actionCreators";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { AppButton } from "src/app/components/AppButton";
import { ReservationManagement } from "../../ReservationManagement";

export const Header = ({
  setReservationStatus,
  reservationsCount,
  reservationStatus,
}) => {
  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state.reservations);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const {
      pressedHeaderTab: { previousPressedTab, currentPressedTab },
    } = reduxState;
    let prevLeftPos =
      previousPressedTab == null
        ? 0
        : document.getElementById(previousPressedTab).offsetLeft;
    let currLeftPos =
      currentPressedTab == null
        ? 0
        : document.getElementById(currentPressedTab).offsetLeft;
    let targetLi = document.getElementById("activeTab");
    targetLi.style.left = prevLeftPos + "px";
    setTimeout(function () {
      targetLi.style.left = currLeftPos + "px";
    }, 5);
  }, [reduxState]);

  const handleClickTab = (tabStatus) => {
    setReservationStatus(tabStatus);
    dispatch(
      setPressedHeaderTab({
        currentPressedTab: tabStatus,
        previousPressedTab: reservationStatus,
      })
    );
  };

  const handleOpen = async () => {
    setOpen((o) => !o);
  };

  const handleError = (error) => {
    setLoading(false);
    // sending the value to parent to show toast error.
    // setErrorOccured(true);
  };

  return (
    <>
      <ReservationManagement
        open={open}
        setOpen={setOpen}
        setErrorOccured={handleError}
        edit={false}
      />

      <div className="tabs-wrapper">
        <h1>Reservations</h1>

        <div
          style={{
            display: "flex",
            paddingLeft: "40px",
            paddingBottom: "40px",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <AppButton label="Create" onClick={handleOpen} />
        </div>

        <div className="tabbing-wrap">
          <ul className="tabs-menu">
            <li id="activeTab" className="activeOverlay"></li>
            {RESERVATIONS_TABS.map((tab, index) => {
              return (
                <li
                  key={index}
                  id={tab.status}
                  onClick={(e) => {
                    handleClickTab(tab.status);
                  }}
                >
                  <NavLink
                    strict={true}
                    to={`/reservations`}
                    activeClassName="active"
                    isActive={() => {
                      if (tab.status === reservationStatus) {
                        return true;
                      }
                    }}
                  >
                    {tab.label}{" "}
                    {reservationsCount[tab.status]
                      ? `(${reservationsCount[tab.status]})`
                      : ""}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
        <div></div>
      </div>
    </>
  );
};

Header.propTypes = {
  noOfItems: PropTypes.number,
};
