import { compose, createStore } from "redux";
import INITIAL_STATE from "./initialState";
import { rootReducer } from "./rootReducer";

let store;

if (process.env.REACT_APP_ENV === "dev") {
  store = createStore(
    rootReducer,
    INITIAL_STATE,
    compose(
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
        : (f) => f
    )
  );
} else {
  store = createStore(rootReducer, INITIAL_STATE);
}

export default store;
