import sortUp from "src/app/assets/images/sort-up.svg";
import sortDown from "src/app/assets/images/sort-down.svg";

export const ORDER_REVERSE_MAP = {
  asc: "desc",
  desc: "asc",
};

export const SORT_ICONS = {
  asc: sortUp,
  desc: sortDown,
};
