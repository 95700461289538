import React from "react";
import "./styles.scss";

export const CalendarIcon = () => {
  return (
    <span className="calendar-icon">
      <svg
        id="Layer_1"
        enableBackground="new 0 0 510.992 510.992"
        height="512"
        viewBox="0 0 510.992 510.992"
        width="512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="m418.479 48.525h-42.606v-32.525c0-8.837-7.163-16-16-16s-16 7.163-16 16v32.525h-176.752v-32.525c0-8.837-7.163-16-16-16s-16 7.163-16 16v32.525h-42.607c-37.634 0-68.251 30.617-68.251 68.251v325.965c0 37.634 30.617 68.251 68.251 68.251h325.965c37.634 0 68.251-30.617 68.251-68.251v-325.965c-.001-37.633-30.618-68.251-68.251-68.251zm-362.216 68.251c0-19.989 16.262-36.251 36.251-36.251h42.607v32.525c0 8.837 7.163 16 16 16s16-7.163 16-16v-32.525h176.751v32.525c0 8.837 7.163 16 16 16s16-7.163 16-16v-32.525h42.606c19.989 0 36.251 16.262 36.251 36.251v73.198h-398.466zm362.216 362.216h-325.965c-19.989 0-36.251-16.262-36.251-36.251v-220.766h398.467v220.767c-.001 19.988-16.262 36.25-36.251 36.25z" />
      </svg>
    </span>
  );
};
