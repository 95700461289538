import moment from "moment";

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const calcImpressions = (impressions = []) => {
  const min = moment().subtract(1, "years").unix();
  const max = moment().unix();

  const lastYearImpressions = impressions.filter((item) => {
    const created_at = moment(new Date(item.created_at)).unix();
    return created_at >= min && created_at <= max && item;
  });

  const months = [];

  for (let i = 1; i <= 12; i++) {
    const oneYearAgo = moment().subtract(1, "years");
    months.push(oneYearAgo.add(i, "month"));
  }

  const labels = months.map((item) => item.format("MMMM / YYYY"));
  const data = months.map((mItem) => {
    const len = lastYearImpressions.filter(
      (fItem) =>
        moment(fItem.created_at).format("MM-YYYY") === mItem.format("MM-YYYY")
    ).length;
    return len;
  });

  return { data, labels };
};

export const calcSales = (sales = []) => {
  const min = moment().subtract(1, "years").unix();
  const max = moment().unix();

  const lastYearSales = sales.filter((item) => {
    const created_at = moment(new Date(item.created_at)).unix();
    return created_at >= min && created_at <= max && item;
  });

  const months = [];

  for (let i = 1; i <= 12; i++) {
    const oneYearAgo = moment().subtract(1, "years");
    months.push(oneYearAgo.add(i, "month"));
  }

  const labels = months.map((item) => item.format("MMMM / YYYY"));
  const data = months.map((mItem) => {
    const amount = lastYearSales
      .filter(
        (fItem) =>
          moment(fItem.created_at).format("MM-YYYY") === mItem.format("MM-YYYY")
      )
      .reduce((a, b) => {
        const value = parseFloat(b.amount);
        return a + value;
      }, 0);
    return amount;
  });
  return { data, labels };
};
