import React from "react";
import Popup from "reactjs-popup";
import { TextField } from "src/app/components/TextField";
import CloseIcon from "src/app/assets/images/close-icon.svg";
import Select from "react-select";
import { PhoneField } from "src/app/components/PhoneField";
import { AppButton } from "src/app/components/AppButton";

const Upsert = ({ open, setOpen, loading, edit }) => {
  //const [open, setOpen] = useState(false);
  //const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Popup
      className="popup-admin"
      open={open}
      closeOnDocumentClick={false}
      onClose={closeModal}
    >
      <div className="modal">
        <div className="close-button" onClick={!loading && closeModal}>
          <img alt="" src={CloseIcon} width="24px" height="24px" />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "10px",
          }}
        >
          <h2>{edit ? "Edit Property" : "Add Property"}</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <TextField extraStyles="text-field" label="Name" disabled={loading} />
          <TextField extraStyles="text-field" label="Email" />
          <TextField
            extraStyles="text-field"
            label="Address"
            disabled={loading}
          />
          <Select
            isClearable={false}
            isSearchable={false}
            options={[
              {
                label: "Helder Braga",
                value: 1,
              },
              {
                label: "Felix tineo",
                value: 2,
              },
            ]}
            placeholder="Administrator"
            theme={(theme) => ({
              ...theme,
              borderRadius: 4,
              height: "45px",
              colors: {
                ...theme.colors,
                primary25: "#FFD865",
                primary50: "#FFD865",
                primary: "black",
              },
            })}
            styles={{
              container: () => ({
                width: "100%",
                borderRadius: 4,
              }),
              control: (provided, state) => ({
                ...provided,
                height: 45,
                borderRadius: 4,
                border: "1px solid #434343",
              }),
            }}
            isLoading={loading}
            isDisabled={loading}
          />
          <PhoneField
            extraStyles="text-field"
            label="Phone"
            defaultCountry="US"
          />
        </div>

        <div
          style={{
            paddingTop: "15px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              color: "red",
              paddingTop: "15px",
            }}
          ></div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            color: "red",
            paddingTop: "15px",
          }}
        ></div>

        <div className="btn-container-row">
          <button className="cancel-item-btn" disabled={loading}>
            <span>Cancel</span>
          </button>

          <AppButton isLoading={loading} label="Save" isDisabled={loading} />
        </div>
      </div>
    </Popup>
  );
};

export default Upsert;
