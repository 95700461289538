export const SERVICES = {
  getUnreadChatMessages: {
    apiEndpoint: "chat/getUnreadChatMessages",
  },
  getUnreadChatMessagesByProperty: {
    apiEndpoint: "chat/getUnreadChatMessagesByProperty",
  },
  updateReadAllChatMessages: {
    apiEndpoint: "chat/updateReadAllChatMessages",
  },
  createNotification: {
    apiEndpoint: "notifications/createNotification",
  },
  getUnreadNotificationsByProperty: {
    apiEndpoint: "notifications/getUnreadNotificationsByProperty",
  },
  updateReadAllNotifications: {
    apiEndpoint: "notifications/updateReadAllNotifications",
  },
};
