/*
            'guest_name',
						'guest_phone_no',
						'guest_email',
						'room_no',
						'credit_card_no',
						'checkin_date',
						'checkout_date',
						'booking_date',
						'status',
						'updated_at',
						'room_type_name',
						'room_rate_plan',
      */

export const defaultSchema = {
  "Reservation Number": {
    prop: "reservation_id",
    type: String,
    required: true,
  },
  Name: {
    prop: "guest_name",
    type: String,
    required: true,
  },
  "Phone Number": {
    prop: "guest_phone_no",
    type: String,
    required: true,
  },
  Email: {
    prop: "guest_email",
    type: String,
    required: true,
  },
  "Room Number": {
    prop: "room_no",
    type: String,
    required: true,
  },

  "Check in Date": {
    prop: "checkin_date",
    type: String,
    required: true,
  },
  "Check out Date": {
    prop: "checkout_date",
    type: String,
    required: true,
  },
  "Reservation Date": {
    prop: "booking_date",
    type: String,
    required: true,
  },
  Status: {
    prop: "status",
    type: String,
    required: true,
  },
  "Room Type": {
    prop: "room_type_name",
    type: String,
    required: true,
  },
};

export const cloudbedsSchema = {
  "Reservation Number": {
    prop: "reservation_id",
    type: String,
    required: true,
  },
  Name: {
    prop: "guest_name",
    type: String,
    required: true,
  },
  "Phone Number": {
    prop: "guest_phone_no",
    type: String,
    required: true,
  },
  Email: {
    prop: "guest_email",
    type: String,
    required: true,
  },
  "Room Number": {
    prop: "room_no",
    type: String,
    required: true,
  },

  "Check in Date": {
    prop: "checkin_date",
    type: String,
    required: true,
  },
  "Check out Date": {
    prop: "checkout_date",
    type: String,
    required: true,
  },
  "Reservation Date": {
    prop: "booking_date",
    type: String,
    required: true,
  },
  Status: {
    prop: "status",
    type: String,
    required: true,
  },
  "Room Type": {
    prop: "room_type_name",
    type: String,
    required: true,
  },
};
