import React, { useEffect, useState } from "react";
import "./styles.scss";
import { Header } from "./Header";
import { Table } from "./Table";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { SearchField } from "src/app/components/SearchField";
import {
  saveReservations,
  setReservationsCount,
  setReservationStatus,
  setWsMessageReceivedStatus,
  setPageOffset,
} from "../../actionCreators";
import PropTypes from "prop-types";
import { parseReservations } from "./helper";
import {
  RESERVATIONS_TABLE_COLUMNS_CONFIG,
  SERVICES,
  TOAST_MESSAGES,
  LOADING_STATUS,
  PAGINATION_COMPONENT_STRINGS,
  PAGINATION,
} from "./constants";
import {
  getReservationsService,
  sendKeyService,
  expireKeyService,
  sendSmsService,
  getPropertyDetailsService,
} from "./service";
import { useQuery } from "react-query";
import { ReservationDetails } from "../ReservationDetails";
import { updateAdminCheckInStatusService } from "../ReservationDetails/services";
import {
  getFormattedDate,
  getCurrentDateInDateObj,
  getIncrementDateByXinDateObj,
  getTimezoneOffsetRegion,
} from "src/app/common/utils/dateUtils";
import { ResendIcon } from "src/app/components/ResendIcon";
import { ExpireIcon } from "src/app/components/ExpireIcon";
import { Toast } from "src/app/components/Toast";
import { Spinner } from "src/app/components/Spinner";
import { NoRecordsFound } from "src/app/components/NoRecordsFound";
import { GenericError } from "src/app/components/GenericError";
import { RESERVATION_STATUS, CALENDAR_TYPES } from "./constants";
import {
  ADMIN_CHECKIN_STATUS_TYPES_TO_GET,
  ADMIN_CHECKIN_STATUS_TYPES_TO_SEND,
} from "src/app/common/constants/AppConstants";
import { DateRangePicker } from "src/app/components/DatePicker";
import { AppButton } from "src/app/components/AppButton";
import { CalendarIcon } from "src/app/components/CalendarIcon";
import { ReservationManagement } from "../ReservationManagement";

export const Reservations = (props) => {
  const [showReservationDetails, setShowReservationDetails] = useState(false);
  const [selectedReservationId, setSelectedReservationId] = useState("");
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastError, setToastError] = useState(false);
  const [guestName, setGuestName] = useState("");
  const [sortOnKey, setSortOnKey] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState(null);

  const [reservationData, setReservationData] = useState([]);

  const [calendarDates, setCalendarDates] = useState({
    [CALENDAR_TYPES.from]: getCurrentDateInDateObj(),
    [CALENDAR_TYPES.to]: getCurrentDateInDateObj(),
  });

  const [calendarDatesForApi, setCalendarDatesForApi] = useState({
    [CALENDAR_TYPES.from]: null,
    [CALENDAR_TYPES.to]: null,
  });

  const [showCalendarApply, setShowCalendarApply] = useState(false);
  const [showCalendar, setShowCalendar] = useState(true);
  const [loadingStatus, setLoadingStatus] = useState(null);

  const { key, apiEndpoint } = SERVICES.getReservations;
  const fromDateForApi = getFormattedDate(
    calendarDatesForApi[CALENDAR_TYPES.from],
    "YYYY-MM-DD"
  );
  const toDateFromApi = getFormattedDate(
    calendarDatesForApi[CALENDAR_TYPES.to],
    "YYYY-MM-DD"
  );

  const {
    data,
    status,
    error: apiError,
    isFetching,
    refetch,
  } = useQuery(
    [
      key,
      apiEndpoint,
      {
        sortOn: {
          [sortOnKey]: sortOrder,
        },
      },
      props.reservationStatus,
      props.selectedProperty,
      {
        fromToDate: {
          fromDate: fromDateForApi,
          toDate: toDateFromApi,
        },
      },
      props.reservationsPageNumber[props.reservationStatus],
    ],
    () =>
      getReservationsService(apiEndpoint, {
        sortOn: { [sortOnKey]: sortOrder },
        status: props.reservationStatus,
        limit: PAGINATION.limit,
        offset: props.reservationsPageNumber[props.reservationStatus],
        propertyId: props.selectedProperty?.value,
        fromToDate: {
          fromDate: fromDateForApi,
          toDate: toDateFromApi,
        },
        timezoneRegion: getTimezoneOffsetRegion(),
        search: search,
      }),
    { enabled: props.selectedProperty ? true : false }
  );

  useEffect(() => {
    if (props.selectedProperty === undefined) {
      setToastMessage(TOAST_MESSAGES.adminProperty.error);
      setToastError(true);
      setShowToast(true);
    }
  }, [props.selectedProperty]);

  const handlePagination = (pressedPageIndex) => {
    window.scroll({
      top: 0,
      behavior: "auto",
    });
    const { selected } = pressedPageIndex;
    props.setPageOffset(props.reservationStatus, selected * PAGINATION.limit);
  };

  const isArrivalsTab = () => {
    return props.reservationStatus === RESERVATION_STATUS.Confimed;
  };

  const isBookingsTab = () => {
    return props.reservationStatus === RESERVATION_STATUS.Booking;
  };

  const isNoShowTab = () => {
    return props.reservationStatus === RESERVATION_STATUS.NoShow;
  };

  useEffect(() => {
    if (isBookingsTab()) {
      setCalendarDates({
        [CALENDAR_TYPES.from]: getIncrementDateByXinDateObj(1),
        [CALENDAR_TYPES.to]: null,
      });
    }

    if (isNoShowTab()) {
      setCalendarDates({
        [CALENDAR_TYPES.from]: getCurrentDateInDateObj(),
        [CALENDAR_TYPES.to]: getCurrentDateInDateObj(),
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.isWsMessageReceived) {
      refetch();
      props.setWsMessageReceivedStatus(false);
    }
    // eslint-disable-next-line
  }, [props.isWsMessageReceived]);

  useEffect(() => {
    if (apiError) {
      setError(true);
    }
  }, [apiError]);

  useEffect(() => {
    if (status === "loading") {
      setReservationData([]);
    }

    if (status === "success") {
      const { reservations, parsingError } = parseReservations(data);
      if (reservations) {
        props.saveReservations(reservations);

        reservations.length > 0
          ? setReservationData(reservations)
          : setReservationData(null);
      } else if (parsingError) {
        setError(true);
      }
    } else if (status === "error") {
      setError(true);
    }
    // eslint-disable-next-line
  }, [data, status]);

  useEffect(() => {
    if (status === "success") {
      props.setReservationsCount(
        props.reservationStatus,
        data ? data[0]?.totalRowCount : ""
      );
    }
    // eslint-disable-next-line
  }, [props.reservationStatus, status, isFetching]);

  useEffect(() => {
    setTimeout(() => {
      refetch();
      setLoading(false);
      setLoadingStatus(null);
    }, 2000);

    if (search && search !== "") {
      setShowCalendar(false);
    } else {
      setShowCalendar(true);
    }
    // eslint-disable-next-line
  }, [search]);

  const handleSort = (sortOnKey, order) => {
    setSortOrder(order);
    setSortOnKey(sortOnKey);
  };

  const startKeySendingProcess = async (
    reservationId,
    guestName,
    guestPhontNumber
  ) => {
    setLoading(true);
    setLoadingStatus(LOADING_STATUS.sendingKey);
    try {
      await sendKeyService(SERVICES.sendKey.apiEndpoint, {
        reservationId: reservationId,
        propertyId: props.selectedProperty?.value,
      });
      setLoadingStatus(LOADING_STATUS.updatingStatus);
      await updateAdminCheckInStatusService(
        SERVICES.updateAdminCheckInStatus.apiEndpoint,
        {
          reservationId: reservationId,
          adminCheckInStatus: ADMIN_CHECKIN_STATUS_TYPES_TO_SEND.KEY_SENT,
          guestPhoneNo: guestPhontNumber,
          guestName: guestName,
        }
      );
      setLoadingStatus(LOADING_STATUS.refetching);

      setTimeout(() => {
        refetch();
        setLoading(false);
        setLoadingStatus(null);
      }, 1000);
    } catch (error) {
      errorOccured(TOAST_MESSAGES.somethingWentWrong);
    }
  };

  const sendSms = async (data) => {
    setLoading(true);
    const { name, email, no_of_nights, checkin_date, phone_number, id } = data;
    try {
      const apiBody = {
        guest_name: name,
        nights: no_of_nights,
        checkin_date: checkin_date,
        phone_number: phone_number,
        email: email,
        reservation_id: id,
        propertyId: props.selectedProperty?.value,
      };
      await sendSmsService(SERVICES.sendSms.apiEndpoint, apiBody);
      setLoading(false);
      setToastMessage(TOAST_MESSAGES.smsSent.success);
      setShowToast(true);
    } catch (error) {
      errorOccured(TOAST_MESSAGES.smsSent.error);
    }
  };

  const errorOccured = (message) => {
    setLoadingStatus(null);
    setToastError(true);
    setToastMessage(message);
    setLoading(false);
    setShowToast(true);
  };

  const handleStatusClick = async (data) => {
    const {
      id: reservationId,
      admin_checkin_status: adminCheckInStatus,
      status,
      name: guestName,
      phone_number: guestPhoneNumber,
    } = data;
    setShowToast(false);

    if (!adminCheckInStatus && status === RESERVATION_STATUS.Confimed) {
      setSelectedReservationId(reservationId);
      await sendSms(data);
      return;
    }
    if (
      adminCheckInStatus === ADMIN_CHECKIN_STATUS_TYPES_TO_GET.DOC_VERIFIED ||
      adminCheckInStatus === ADMIN_CHECKIN_STATUS_TYPES_TO_GET.KEY_EXPIRED
    ) {
      setSelectedReservationId(reservationId);
      await startKeySendingProcess(reservationId, guestName, guestPhoneNumber);
      return;
    }
    setSelectedReservationId(reservationId);
    setShowReservationDetails(true);
  };
  const handleNameClick = async (data) => {
    const { id: reservationId } = data;
    setSelectedReservationId(reservationId);
    setShowReservationDetails(true);
  };

  const handleMoreOptionsClick = async (reservationId, guestName) => {
    setSelectedReservationId(reservationId);
    setGuestName(guestName);
    setShowMoreOptions(
      (showMoreOptions) => (showMoreOptions = !showMoreOptions)
    );
  };

  const dismissMoreOptions = () => {
    setShowMoreOptions(false);
  };

  const dismissReservationDetails = (whetherToRefetch) => {
    whetherToRefetch && refetch();
    setShowReservationDetails(false);
  };

  const getMoreOptionsConfig = () => {
    const config = [
      {
        name: "RESend KEY",
        icon: <ResendIcon />,
        onClick: async () => {
          dismissMoreOptions();
          await sendKey();
        },
      },
      {
        name: "EXPIRE KEY",
        icon: <ExpireIcon />,
        onClick: async () => {
          dismissMoreOptions();
          await expireKey();
        },
      },
    ];
    return config;
  };

  const sendKey = async () => {
    const { apiEndpoint } = SERVICES.sendKey;
    setLoading(true);
    setLoadingStatus(LOADING_STATUS.sendingKey);
    try {
      await sendKeyService(apiEndpoint, {
        reservationId: selectedReservationId,
        propertyId: props.selectedProperty?.value,
      });
      var message = TOAST_MESSAGES.sendKey.success + guestName;
      setToastError(false);
      setLoadingStatus(null);
    } catch (error) {
      message = TOAST_MESSAGES.sendKey.error;
      setToastError(true);
      setLoadingStatus(null);
    } finally {
      setToastMessage(message);
      setLoading(false);
      setShowToast(true);
    }
  };

  const expireKey = async () => {
    const { apiEndpoint } = SERVICES.expireKey;
    setLoading(true);
    setLoadingStatus(LOADING_STATUS.expiringKey);
    try {
      await expireKeyService(apiEndpoint, {
        reservationId: selectedReservationId,
      });

      setTimeout(() => {
        refetch();
      }, 1000);
      var message = TOAST_MESSAGES.expireKey.success;
      setToastError(false);
    } catch (error) {
      message = TOAST_MESSAGES.expireKey.error;
      setToastError(true);
    } finally {
      setToastMessage(message);
      setLoading(false);
      setShowToast(true);
      setLoadingStatus(null);
    }
  };

  const handleCalendarDate = (calendarType) => (date) => {
    const { [CALENDAR_TYPES.to]: toDate } = calendarDates;
    if (calendarType === CALENDAR_TYPES.from) {
      if (date > toDate) {
        setCalendarDates({
          [CALENDAR_TYPES.from]: date,
          [CALENDAR_TYPES.to]: date,
        });
        setShowCalendarApply(true);
        return;
      }
    }
    setCalendarDates({ ...calendarDates, [calendarType]: date });
    setShowCalendarApply(true);
  };

  const handlePressApply = () => {
    setCalendarDatesForApi(calendarDates);
    props.setPageOffset(props.reservationStatus, 0);
  };

  const getMinDate = () => {
    if (isArrivalsTab()) {
      return getCurrentDateInDateObj();
    }
    return null;
  };

  const handleReservationDetailsError = () => {
    setShowReservationDetails(false);
    setToastMessage(TOAST_MESSAGES.somethingWentWrong);
    setToastError(true);
    setShowToast(true);
  };

  const handleReservationManagementError = () => {
    setOpen(false);
    setToastMessage(TOAST_MESSAGES.somethingWentWrong);
    setToastError(true);
    setShowToast(true);
  };

  const getPropertyDetails = async () => {
    try {
      const { apiEndpoint } = SERVICES.getPropertyDetails;
      const propertyId = props.selectedProperty?.value;

      const res = await getPropertyDetailsService(apiEndpoint, propertyId);
      setPropertyDetails(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      !propertyDetails &&
      props.selectedProperty !== "undefined" &&
      props.selectedProperty?.value
    ) {
      (async () => {
        await getPropertyDetails();
      })();
    }
    // eslint-disable-next-line
  }, [props.selectedProperty]);

  const totalPages = reservationData
    ? reservationData[0]?.totalRowCount / PAGINATION.limit
    : null;

  return (
    <div className="right-wrapper">
      <Header
        setReservationStatus={props.setReservationStatus}
        reservationsCount={props.reservationsCount}
        reservationStatus={props.reservationStatus}
      />

      <div className="dateRange-picker-wrapper">
        <div className="search">
          <SearchField
            extraStyles="text-field-description"
            placeholder="Search"
            value={search}
            setValue={setSearch}
            disabled={loading}
            showError={showToast}
            errorMessage={toastMessage}
          />
        </div>
        {showCalendar && (
          <React.Fragment>
            <div className="date-wrapper from-date">
              <CalendarIcon />
              <DateRangePicker
                format={"MM/dd/y"}
                calendarDate={calendarDates[CALENDAR_TYPES.from]}
                getCalendarDate={handleCalendarDate(CALENDAR_TYPES.from)}
                minDate={getMinDate()}
              />
            </div>
            <span className="separator"></span>
            <div className="date-wrapper to-date">
              <CalendarIcon />
              <DateRangePicker
                format={"MM/dd/y"}
                calendarDate={calendarDates[CALENDAR_TYPES.to]}
                getCalendarDate={handleCalendarDate(CALENDAR_TYPES.to)}
                minDate={calendarDates[CALENDAR_TYPES.from]}
              />
            </div>

            {showCalendarApply && (
              <AppButton
                label="Apply"
                onClick={handlePressApply}
                className={"active"}
              />
            )}
          </React.Fragment>
        )}
      </div>

      <div className="table-wrapper">
        {!error && reservationData && propertyDetails && (
          <Table
            data={reservationData}
            columnsConfig={RESERVATIONS_TABLE_COLUMNS_CONFIG}
            loading={isFetching}
            onStatusClick={handleStatusClick}
            onNameClick={handleNameClick}
            onMoreOptionsClick={handleMoreOptionsClick}
            showMoreOptions={showMoreOptions}
            moreOptionsConfig={getMoreOptionsConfig()}
            selectedReservationId={selectedReservationId}
            dismissMoreOptions={dismissMoreOptions}
            onSort={handleSort}
            sortOrder={sortOrder}
            isArrivalsTab={isArrivalsTab}
            isBookingsTab={isBookingsTab}
            reservationStatus={props.reservationStatus}
            keyEnabled={propertyDetails.key_enabled ? true : false}
          />
        )}

        {!error && !reservationData && <NoRecordsFound />}

        {error && <GenericError />}

        {!error && reservationData && (
          <div className="pagination-wrapper">
            <ReactPaginate
              forcePage={
                props.reservationsPageNumber[props.reservationStatus] /
                PAGINATION.limit
              }
              pageCount={Math.ceil(totalPages)}
              pageRangeDisplayed={PAGINATION.pageRangeDisplayed}
              marginPagesDisplayed={PAGINATION.marginPagesDisplayed}
              previousLabel={PAGINATION_COMPONENT_STRINGS.previous}
              nextLabel={PAGINATION_COMPONENT_STRINGS.next}
              breakLabel={PAGINATION_COMPONENT_STRINGS.break}
              onPageChange={handlePagination}
              breakClassName={"break-me"}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </div>
        )}

        {showReservationDetails && (
          <ReservationDetails
            activeClassName={showReservationDetails && "active"}
            onDismiss={dismissReservationDetails}
            selectedResId={selectedReservationId}
            setErrorOccured={handleReservationDetailsError}
            chatIsOpen={false}
          />
        )}

        <ReservationManagement
          open={open}
          setOpen={setOpen}
          setErrorOccured={handleReservationManagementError}
          edit={true}
          reservationId={selectedReservationId}
        />

        <Toast
          message={toastMessage}
          activeClassName={showToast ? "active" : ""}
          onDismiss={() => setShowToast(false)}
          isError={toastError}
        />

        {loading && <Spinner loaderStatus={loadingStatus} />}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    reservationsCount,
    reservationStatus,
    isWsMessageReceived,
    reservationsPageNumber,
    selectedProperty,
  } = state.reservations;
  return {
    reservationsCount,
    reservationStatus,
    isWsMessageReceived,
    reservationsPageNumber,
    selectedProperty,
  };
};

const mapDispatchToProps = {
  saveReservations,
  setReservationsCount,
  setReservationStatus,
  setWsMessageReceivedStatus,
  setPageOffset,
};

Reservations.propTypes = {
  saveReservations: PropTypes.func.isRequired,
  setWsMessageReceivedStatus: PropTypes.func.isRequired,
  reservationsCount: PropTypes.object.isRequired,
  reservationStatus: PropTypes.string,
  isWsMessageReceived: PropTypes.bool,
  setReservationsCount: PropTypes.func,
  selectedProperty: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reservations);
