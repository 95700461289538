import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import "./styles.scss";
import { isValidSession } from "src/app/common/utils/sessionUtils";

export const ProtectedRoute = ({ component }) => {
  const ComponentWithRouterProps = withRouter(component);

  return (
    <React.Fragment>
      {isValidSession() ? (
        <Redirect to="/reservations" />
      ) : (
        <ComponentWithRouterProps />
      )}
    </React.Fragment>
  );
};
