import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const DEFAULT_HEADERS = {
  "Content-Type": "application/json",
};
const DEFAULT_HTTP_METHOD = "GET";
const DEFAULT_TIMEOUT = 0;
const DEFAULT_RESPONSE_TYPE = "json";
const DEFAULT_RESPONSE_ENCODING = "utf8";
const DEFAULT_XSRF_COOKIE_NAME = "";
const DEFAULT_XSRF_HEADER_NAME = "";
const DEFAULT_MAX_CONTENT_LENGTH = 2000;
const DEFAULT_PARAMS = {};
const DEFAULT_MEX_REDIRECTS = 0;

export default class AxiosBuilder {
  constructor(url, options = {}) {
    this.url = url;

    this.options = {
      url: this.url,
      baseURL: BASE_URL,
      method: DEFAULT_HTTP_METHOD,
      params: DEFAULT_PARAMS,
      timeout: options.timeout || DEFAULT_TIMEOUT,
      responseType: options.responseType || DEFAULT_RESPONSE_TYPE,
      responseEncoding: options.responseEncoding || DEFAULT_RESPONSE_ENCODING,
      xsrfCookieName: options.xsrfCookieName || DEFAULT_XSRF_COOKIE_NAME,
      xsrfHeaderName: options.xsrfHeaderName || DEFAULT_XSRF_HEADER_NAME,
      maxContentLength: options.maxContentLength || DEFAULT_MAX_CONTENT_LENGTH,
      maxRedirects: DEFAULT_MEX_REDIRECTS,
      headers: options.headers || DEFAULT_HEADERS,
      data: options.data || {},
      transformRequest: [
        (data, headers) =>
          options.transformRequest
            ? options.transformRequest(data, headers)
            : data,
      ],
      transformResponse: [
        (data) =>
          options.transformResponse ? options.transformResponse(data) : data,
      ],
      validateStatus(status) {
        return status >= 200 && status < 300;
      },
      onUploadProgress(progressEvent) {
        if (options.onUploadProgress) options.onUploadProgress(progressEvent);
      },
      onDownloadProgress(progressEvent) {
        if (options.onDownloadProgress)
          options.onDownloadProgress(progressEvent);
      },
    };
  }

  withParams = (params) => {
    this.options.params = params;
    return this;
  };

  withAuth = (IdToken) => {
    if (IdToken) {
      this.options.headers.Authorization = IdToken;
    }
    return this;
  };

  withCancelToken = (requestMap) => {
    this.options.cancelToken = new axios.CancelToken((cancel) => {
      Object.assign(requestMap, {
        [this.url]: cancel,
      });
    });
    return this;
  };

  PUT = () => {
    this.options.method = "PUT";
    return axios(this.options);
  };

  POST = () => {
    this.options.method = "POST";
    return axios(this.options);
  };

  DELETE = () => {
    this.options.method = "DELETE";
    return axios(this.options);
  };

  PATCH = () => {
    this.options.method = "PATCH";
    return axios(this.options);
  };

  GET() {
    return axios(this.options);
  }

  HEAD = () => {
    this.options.method = "HEAD";
    return axios(this.options);
  };
}

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject({
      statusCode: error.response.status,
      message: error.response.data.errorMessage.split(":"),
    });
  }
);
