import React, { useEffect, useState, useCallback } from "react";
import { Auth } from "aws-amplify";
import { useDropzone } from "react-dropzone";
import "./styles.scss";
import { useSelector } from "react-redux";
import { SERVICES } from "./constants";
import {
  getReservationAndIncidentalsDetails,
  getReservationDetailsService,
  createAttachmentService,
  getAttachmentsService,
  deleteAttachmentService,
  shareAttachmentService,
  createAdminLogService,
} from "./services";
import { parseReservationDetails } from "./helper";
import DeleteIcon from "src/app/assets/images/incidentals-delete-icon.svg";
import ShareIcon from "src/app/assets/images/share-icon.svg";
import IncidentalsIcon from "src/app/assets/images/zero-incidentals.svg";
import Loader from "react-loader-spinner";
import { getTimezoneOffsetRegion } from "src/app/common/utils/dateUtils";
import { Toast } from "src/app/components/Toast";
import { Storage } from "aws-amplify";
const crypto = require("crypto");

export const Attachments = ({ onDismiss, selectedResId, setErrorOccured }) => {
  const { selectedProperty } = useSelector((state) => state.reservations);
  const [reservationDetails, setReservationDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [attachments, setAttachments] = useState(null);
  const [toastMessage, setToastMessage] = useState("");
  const [toastError, setToastError] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadingMessage, setUploadingMessage] = useState("Uploading...");

  useEffect(() => {
    getReservationAndIncidentals();
    getAttachments();
    // eslint-disable-next-line
  }, []);

  const onDrop = useCallback(async (acceptedFiles) => {
    try {
      setUploading(true);
      const cognitoId = await getCognitoId();
      for (let index = 0; index < acceptedFiles.length; index++) {
        const file = acceptedFiles[index];
        await uploadAttachmentToS3(file, cognitoId, "protected");
      }
    } catch (err) {
      handleError(err);
    } finally {
      await getAttachments();
      setUploading(false);
    }
    // eslint-disable-next-line
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const getCognitoId = async () => {
    const { apiEndpoint } = SERVICES.getReservationDetails;
    try {
      const response = await getReservationDetailsService(apiEndpoint, {
        reservationId: selectedResId,
        propertyId: selectedProperty.value,
      });
      const { cognito_identity_id } = response;
      if (cognito_identity_id) {
        return cognito_identity_id;
      }
    } catch (error) {
      handleError();
    }
  };

  const handleGetAttachment = async (id, index) => {
    try {
      const attachmentS3Key = attachments[index].attachmentS3Key;
      await getAttachmentFromS3(attachmentS3Key);
    } catch (error) {
      handleError();
    }
  };

  const getAttachmentFromS3 = async (attachmentS3Key) => {
    try {
      const cognitoId = await getCognitoId();
      const level = "protected";
      const response = await Storage.get(attachmentS3Key, {
        level,
        download: true,
        identityId: cognitoId,
      });
      downloadBlob(response.Body, attachmentS3Key);
      return response;
    } catch (err) {
      handleError(err);
    }
  };

  const downloadBlob = (blob, filename) => {
    try {
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename || "download";
      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(url);
          a.removeEventListener("click", clickHandler);
        }, 150);
      };
      a.addEventListener("click", clickHandler, false);
      a.click();
      return a;
    } catch (error) {
      handleError();
    }
  };

  const uploadAttachmentToS3 = async (file, cognitoIdentityId, level) => {
    try {
      setUploadingMessage("Uploading...");

      const hash = crypto.randomBytes(20).toString("hex");
      const filenameSplit = file.name.split(".");
      let filename = [filenameSplit[0], "_", hash, ".", filenameSplit[1]].join(
        ""
      );

      const result = await Storage.put(filename, file, {
        level,
        identityId: cognitoIdentityId,
        progressCallback(progress) {
          const remainingPart = (progress.loaded / progress.total) * 100;
          setUploadingMessage(
            `Uploading ${file.name} - ${remainingPart.toFixed(2)}%`
          );
        },
      });

      const { apiEndpoint } = SERVICES.createAttachment;

      const attachmentUrl = await getAttachmentUrl(result.key);

      const response = await createAttachmentService(apiEndpoint, {
        propertyId: selectedProperty.value,
        reservationId: selectedResId,
        originalName: file.name,
        attachmentS3Key: result.key,
        attachmentUrl: attachmentUrl,
      });

      const data = {
        action: "Create",
        message: "Create attachment",
        reservationId: selectedResId,
        data: {
          propertyId: selectedProperty.value,
          reservationId: selectedResId,
          originalName: file.name,
          attachmentS3Key: result.key,
          attachmentUrl: attachmentUrl,
        },
      };
      await createAdminLog("Attachments", JSON.stringify(data));

      return response;
    } catch (err) {
      handleError(err);
    }
  };

  const getAttachments = async () => {
    const { apiEndpoint } = SERVICES.getAttachments;
    try {
      setAttachments(null);
      setLoading(true);
      const response = await getAttachmentsService(apiEndpoint, {
        propertyId: selectedProperty.value,
        reservationId: selectedResId,
        timezoneRegion: getTimezoneOffsetRegion(),
      });

      response && setAttachments(response);
    } catch (error) {
      setToastMessage("Error to get attachments");
      setToastError(true);
      setShowToast(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAttachment = async (id, index) => {
    setShowToast(false);
    const attachmentS3Key = attachments[index].attachmentS3Key;

    const filteredItems = attachments
      .slice(0, index)
      .concat(attachments.slice(index + 1, attachments.length));
    setAttachments(filteredItems);
    await removeAttachment(id, attachmentS3Key);
  };

  const removeAttachment = async (id, attachmentS3Key) => {
    const { apiEndpoint } = SERVICES.deleteAttachment;
    const { property_id } = reservationDetails;

    try {
      // const cognitoId = await getCognitoId();

      await deleteAttachmentService(apiEndpoint, {
        propertyId: property_id,
        reservationId: selectedResId,
        id: id,
      });

      // Create Log
      const data = {
        action: "Delete",
        message: "Delete attachment",
        reservationId: selectedResId,
        data: {
          propertyId: property_id,
          reservationId: selectedResId,
          id: id,
        },
      };
      await createAdminLog("Attachments", JSON.stringify(data));

      await getAttachments();
    } catch (error) {
      setToastMessage("Error to delete an attachment");
      setToastError(true);
      setShowToast(true);
    }
  };

  const handleShareAttachment = async (id, index) => {
    try {
      setLoading(true);
      const { apiEndpoint } = SERVICES.shareAttachment;
      const { guest_email, guest_phone_no, property_id } = reservationDetails;
      setShowToast(false);
      const attachmentS3Key = attachments[index].attachmentS3Key;
      const attachmentUrl = await getAttachmentUrl(attachmentS3Key);

      await shareAttachmentService(apiEndpoint, {
        email: guest_email,
        phoneNumber: guest_phone_no,
        reservationId: selectedResId,
        propertyId: property_id,
        attachmentId: id,
        attachmentUrl: attachmentUrl,
      });

      const data = {
        action: "Share",
        message: "Share attachment",
        reservationId: selectedResId,
        data: {
          email: guest_email,
          phoneNumber: guest_phone_no,
          reservationId: selectedResId,
          propertyId: property_id,
          attachmentId: id,
          attachmentUrl: attachmentUrl,
        },
      };
      await createAdminLog("Attachments", JSON.stringify(data));

      setToastMessage("Attachment sent successfully");
      setToastError(false);
      setShowToast(true);
    } catch (e) {
      setToastMessage("Error to send the attachment");
      setToastError(true);
      setShowToast(true);
    } finally {
      setLoading(false);
    }
  };

  const getAttachmentUrl = async (attachmentS3Key) => {
    try {
      const cognitoId = await getCognitoId();
      const level = "protected";
      const response = await Storage.get(attachmentS3Key, {
        level,
        download: false,
        identityId: cognitoId,
        expires: 604800,
      });
      return response;
    } catch (err) {
      handleError(err);
    }
  };

  const getReservationAndIncidentals = async () => {
    const { apiEndpoint } = SERVICES.getReservationAndIncidentalsDetails;
    try {
      const response = await getReservationAndIncidentalsDetails(apiEndpoint, {
        reservationId: selectedResId,
        propertyId: selectedProperty.value,
        timezoneRegion: getTimezoneOffsetRegion(),
      });

      response &&
        setReservationDetails(
          parseReservationDetails({
            ...response,
          })
        );
    } catch (error) {
      handleError();
    } finally {
      setLoading(false);
    }
  };

  const handleError = (error) => {
    setLoading(false);
    // sending the value to parent to show toast error.
    // setErrorOccured(true);
  };

  const createAdminLog = async (type, data) => {
    setLoading(true);
    const { apiEndpoint } = SERVICES.createAdminLog;
    try {
      const user = await Auth.currentUserInfo();

      const params = {
        propertyId: selectedProperty.value,
        adminCognitoId: user.attributes.sub,
        type: type,
        data: data,
      };
      await createAdminLogService(apiEndpoint, params);
    } catch (error) {
      handleError();
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <React.Fragment>
        {loading ? (
          <div className="incidentals-loader">
            <Loader type="Oval" color="#030303" height={50} width={50} />
          </div>
        ) : (
          <React.Fragment>
            <div className="incidentals">
              <div className="attachment-container">
                {reservationDetails ? (
                  <div>
                    <section className="attachment-dropzone-container">
                      <div
                        {...getRootProps({ className: "attachment-dropzone" })}
                      >
                        <input {...getInputProps()} />

                        {!uploading ? (
                          <p>Choose a file or drag it here.</p>
                        ) : (
                          <p>{uploadingMessage}</p>
                        )}
                      </div>
                    </section>
                  </div>
                ) : null}

                <div className="attachment-table">
                  {!attachments || attachments.length === 0 ? (
                    <div className="no-incidentals">
                      <div className="no-incidentals-icon">
                        <img
                          alt=""
                          src={IncidentalsIcon}
                          width="64px"
                          height="64px"
                        />
                      </div>
                      <div className="no-incidentals-message">
                        No attachments found
                      </div>
                    </div>
                  ) : (
                    <div className="incidentals-table-wrapper">
                      <table className="incidentals-table">
                        <tbody>
                          <tr>
                            <th className="incidentals-description">
                              Attachment
                            </th>

                            <th className="incidentals-date">Date</th>
                            <th className="incidentals-action"></th>
                            <th className="incidentals-action"></th>
                          </tr>

                          {attachments.map((attachment, i) => {
                            return (
                              <tr
                                key={i}
                                className={(i + 1) % 2 === 0 ? "even" : "odd"}
                              >
                                <td className="incidentals-description">
                                  <span
                                    style={{ cursor: "pointer" }}
                                    className="description-text"
                                    onClick={(e) => {
                                      handleGetAttachment(attachment.id, i);
                                    }}
                                  >
                                    {attachment.originalName}
                                  </span>
                                </td>

                                <td className="incidentals-date">
                                  {attachment.createdAt}
                                </td>

                                <td className="incidentals-action">
                                  <span
                                    className="incidentals-delete"
                                    onClick={(e) => {
                                      handleShareAttachment(attachment.id, i);
                                    }}
                                  >
                                    <img
                                      alt=""
                                      src={ShareIcon}
                                      width="21px"
                                      height="21px"
                                    />
                                  </span>
                                </td>
                                {reservationDetails &&
                                !reservationDetails.date_release ? (
                                  <td className="incidentals-action">
                                    <span
                                      className="incidentals-delete"
                                      onClick={(e) => {
                                        handleDeleteAttachment(
                                          attachment.id,
                                          i
                                        );
                                      }}
                                    >
                                      <img
                                        alt=""
                                        src={DeleteIcon}
                                        width="21px"
                                        height="21px"
                                      />
                                    </span>
                                  </td>
                                ) : (
                                  <td></td>
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </React.Fragment>
        )}

        <Toast
          message={toastMessage}
          activeClassName={showToast ? "active" : ""}
          onDismiss={() => setShowToast(false)}
          isError={toastError}
        />
      </React.Fragment>
    </React.Fragment>
  );
};
