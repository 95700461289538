import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import "./styles.scss";
import { AppButton } from "src/app/components/AppButton";
import { useSelector } from "react-redux";
import { SERVICES } from "./constants";
import {
  createIncidental,
  getIncidentals,
  deleteIncidental,
  getReservationAndIncidentalsDetails,
  createAdminLogService,
} from "./services";
import { parseReservationDetails } from "./helper";
import { TextField } from "src/app/components/TextField";
import { SuccessIcon } from "src/app/components/SuccessIcon";
import { WarningIcon } from "src/app/components/WarningIcon";
import DeleteIcon from "src/app/assets/images/incidentals-delete-icon.svg";
import IncidentalsIcon from "src/app/assets/images/zero-incidentals.svg";
import Loader from "react-loader-spinner";
import { SkeletonLoader } from "src/app/components/SkeletonLoader";
import { getTimezoneOffsetRegion } from "src/app/common/utils/dateUtils";
import { Toast } from "src/app/components/Toast";
import { CurrencyField } from "src/app/components/CurrencyField";

export const Incidentals = ({ onDismiss, selectedResId, setErrorOccured }) => {
  const { selectedProperty } = useSelector((state) => state.reservations);
  const [reservationDetails, setReservationDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("");
  const [incidentals, setIncidentals] = useState([]);
  const [toastMessage, setToastMessage] = useState("");
  const [toastError, setToastError] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showDescriptionError, setShowDescriptionError] = useState(false);
  const [showAmountError, setShowAmountError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    getReservationAndIncidentals();
    getIncidentalsByReservation();
    // eslint-disable-next-line
  }, []);

  const handleAddIncidentalClick = async () => {
    setShowToast(false);
    await createNewIncidental();
  };

  const getReservationAndIncidentals = async () => {
    const { apiEndpoint } = SERVICES.getReservationAndIncidentalsDetails;
    try {
      const response = await getReservationAndIncidentalsDetails(apiEndpoint, {
        reservationId: selectedResId,
        propertyId: selectedProperty.value,
        timezoneRegion: getTimezoneOffsetRegion(),
      });

      response &&
        setReservationDetails(
          parseReservationDetails({
            ...response,
          })
        );
    } catch (error) {
      handleError();
    } finally {
      setLoading(false);
    }
  };

  const createNewIncidental = async () => {
    setProcessing(true);
    setShowDescriptionError(false);
    setShowAmountError(false);
    let fieldsValidated = true;
    const { apiEndpoint } = SERVICES.createIncidental;
    const {
      property_id,
      pms_property_id,
      hold_transaction_amount,
      incidentals_amount,
    } = reservationDetails;
    const remainingBalance = (
      hold_transaction_amount - incidentals_amount
    ).toFixed(2);

    try {
      if (!description || description.trim() === "") {
        setErrorMessage("Description is required");
        setShowDescriptionError(true);
        fieldsValidated = false;
      } else if (!amount || amount.trim() === "") {
        setErrorMessage("Amount is required");
        setShowAmountError(true);
        fieldsValidated = false;
      } else if (parseFloat(amount) > parseFloat(remainingBalance)) {
        setErrorMessage("Remaining balance lower than amount");
        setShowAmountError(true);
        fieldsValidated = false;
      } else if (parseFloat(amount) <= 0) {
        setErrorMessage("Amount must be greater than $0.00");
        setShowAmountError(true);
        fieldsValidated = false;
      }

      if (fieldsValidated) {
        await createIncidental(apiEndpoint, {
          propertyId: property_id,
          pmsPropertyId: pms_property_id,
          reservationId: selectedResId,
          description: description,
          amount: amount,
        });

        const data = {
          action: "Create",
          message: "Create incidental",
          reservationId: selectedResId,
          data: {
            propertyId: property_id,
            pmsPropertyId: pms_property_id,
            reservationId: selectedResId,
            description: description,
            amount: amount,
          },
        };
        await createAdminLog("Incidentals", JSON.stringify(data));

        await getReservationAndIncidentals();
        await getIncidentalsByReservation();

        setDescription("");
        setAmount("");
      }
    } catch (error) {
      setToastMessage(error.message);
      setToastError(true);
      setShowToast(true);
    } finally {
      setProcessing(false);
    }
  };

  const getIncidentalsByReservation = async () => {
    const { apiEndpoint } = SERVICES.getIncidentals;
    try {
      const response = await getIncidentals(apiEndpoint, {
        propertyId: selectedProperty.value,
        reservationId: selectedResId,
        timezoneRegion: getTimezoneOffsetRegion(),
      });

      response && setIncidentals(response);
    } catch (error) {
      setToastMessage("Error to get incidentals");
      setToastError(true);
      setShowToast(true);
    }
  };

  const handleDeleteIncidental = async (id, index) => {
    setShowToast(false);
    const filteredItems = incidentals
      .slice(0, index)
      .concat(incidentals.slice(index + 1, incidentals.length));
    setIncidentals(filteredItems);
    await removeIncidental(id);
  };

  const removeIncidental = async (id) => {
    setDeleting(true);
    const { apiEndpoint } = SERVICES.deleteIncidental;
    const { property_id } = reservationDetails;

    try {
      await deleteIncidental(apiEndpoint, {
        propertyId: property_id,
        reservationId: selectedResId,
        id: id,
      });

      const data = {
        action: "Delete",
        message: "Delete incidental",
        reservationId: selectedResId,
        data: {
          propertyId: property_id,
          reservationId: selectedResId,
          id: id,
        },
      };
      await createAdminLog("Incidentals", JSON.stringify(data));

      await getReservationAndIncidentals();
      await getIncidentalsByReservation();
    } catch (error) {
      setToastMessage("Error to delete the incidental");
      setToastError(true);
      setShowToast(true);
    } finally {
      setDeleting(false);
    }
  };

  const handleError = (error) => {
    setLoading(false);
    // sending the value to parent to show toast error.
    // setErrorOccured(true);
  };

  const getValueToShow = (data) => {
    return data ? data : "-";
  };

  const createAdminLog = async (type, data) => {
    setLoading(true);
    const { apiEndpoint } = SERVICES.createAdminLog;
    try {
      const user = await Auth.currentUserInfo();

      const params = {
        propertyId: selectedProperty.value,
        adminCognitoId: user.attributes.sub,
        type: type,
        data: data,
      };
      await createAdminLogService(apiEndpoint, params);
    } catch (error) {
      handleError();
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <React.Fragment>
        {loading ? (
          <div className="incidentals-loader">
            <Loader type="Oval" color="#030303" height={50} width={50} />
          </div>
        ) : (
          <React.Fragment>
            <div className="incidentals">
              <div className="incidentals-card-details">
                <div className="incidentals-details">
                  <span className="incidentals-title">INCIDENTALS TOTAL</span>
                  {processing || deleting ? (
                    <span className="incidentals-text">
                      <SkeletonLoader width={100} height={10} />
                    </span>
                  ) : (
                    <span className="incidentals-text">
                      $
                      {getValueToShow(
                        reservationDetails &&
                          reservationDetails.incidentals_amount
                          ? parseFloat(
                              reservationDetails.incidentals_amount
                            ).toFixed(2)
                          : "-"
                      )}
                    </span>
                  )}
                </div>

                <div className="incidentals-details">
                  <div className="incidentals-title">
                    {reservationDetails && reservationDetails.date_release ? (
                      <span className="incidentals-title">RELEASED AMOUNT</span>
                    ) : (
                      <span className="incidentals-title">
                        REMAINING BALANCE
                      </span>
                    )}

                    {processing || deleting ? (
                      <span className="incidentals-text">
                        <SkeletonLoader width={100} height={10} />
                      </span>
                    ) : (
                      <span className="incidentals-text">
                        $
                        {getValueToShow(
                          reservationDetails &&
                            reservationDetails.incidentals_amount &&
                            reservationDetails.hold_transaction_amount
                            ? parseFloat(
                                reservationDetails.hold_transaction_amount -
                                  reservationDetails.incidentals_amount
                              ).toFixed(2)
                            : "-"
                        )}
                      </span>
                    )}
                  </div>
                </div>

                {reservationDetails &&
                reservationDetails.hold_transaction_lastfour ? (
                  <div className="card-details">
                    <span className="incidentals-title">
                      Last four digits of card <SuccessIcon />
                    </span>
                    <span className="incidentals-text">
                      {getValueToShow(
                        reservationDetails &&
                          reservationDetails.hold_transaction_lastfour
                      )}
                    </span>
                  </div>
                ) : (
                  <div className="noCredit-found">
                    <WarningIcon /> No credit card details available
                  </div>
                )}
              </div>

              {reservationDetails && !reservationDetails.date_release ? (
                reservationDetails.hold_transaction_lastfour && (
                  <div className="new-incidental">
                    <div className="fields-group">
                      <TextField
                        extraStyles="text-field-description"
                        label="Description"
                        value={description}
                        setValue={setDescription}
                        disabled={processing}
                        showError={showDescriptionError}
                        errorMessage={errorMessage}
                      />

                      <CurrencyField
                        inputId="amount"
                        inputName="amount"
                        extraStyles="text-field-amount"
                        label="Amount"
                        value={amount}
                        setValue={setAmount}
                        disabled={processing}
                        showError={showAmountError}
                        errorMessage={errorMessage}
                        allowNegativeValue={false}
                        prefix={"$"}
                        intlConfig={{ locale: "en-US", currency: "USD" }}
                      />
                    </div>
                    <div className="btn-container">
                      <AppButton
                        className="btn-add"
                        isLoading={processing}
                        label={"Add"}
                        onClick={handleAddIncidentalClick}
                        isDisabled={processing}
                      />
                    </div>
                  </div>
                )
              ) : (
                <div className="release-completed">
                  <span className="release-completed-text">
                    Release completed at{" "}
                    {reservationDetails && reservationDetails.release_date}{" "}
                  </span>
                  <SuccessIcon width="100px" height="100px" />
                </div>
              )}
              {!incidentals || incidentals.length === 0 ? (
                <div className="no-incidentals">
                  <div className="no-incidentals-icon">
                    <img
                      alt=""
                      src={IncidentalsIcon}
                      width="64px"
                      height="64px"
                    />
                  </div>
                  <div className="no-incidentals-message">
                    No incidentals found
                  </div>
                </div>
              ) : (
                <div className="incidentals-table-wrapper">
                  <table className="incidentals-table">
                    <tbody>
                      <tr>
                        <th className="incidentals-description">Description</th>
                        <th className="incidentals-amount">Amount</th>
                        <th className="incidentals-date">Date</th>
                        <th className="incidentals-action"></th>
                      </tr>

                      {incidentals.map((incidental, i) => {
                        return (
                          <tr
                            key={i}
                            className={(i + 1) % 2 === 0 ? "even" : "odd"}
                          >
                            <td className="incidentals-description">
                              <span className="description-text">
                                {incidental.description}
                              </span>
                            </td>
                            <td className="incidentals-amount">
                              $ {incidental.amount}
                            </td>
                            <td className="incidentals-date">
                              {incidental.createdAt}
                            </td>
                            {reservationDetails &&
                            !reservationDetails.date_release &&
                            incidental.description !== "Late Check-out Fee" ? (
                              <td className="incidentals-action">
                                <span
                                  className="incidentals-delete"
                                  onClick={(e) => {
                                    handleDeleteIncidental(incidental.id, i);
                                  }}
                                >
                                  <img
                                    alt=""
                                    src={DeleteIcon}
                                    width="21px"
                                    height="21px"
                                  />
                                </span>
                              </td>
                            ) : (
                              <td></td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </React.Fragment>
        )}

        <Toast
          message={toastMessage}
          activeClassName={showToast ? "active" : ""}
          onDismiss={() => setShowToast(false)}
          isError={toastError}
        />
      </React.Fragment>
    </React.Fragment>
  );
};
