import React from "react";
import PropTypes from "prop-types";
import Select from "react-select/async";
import "react-dropdown/style.css";
import "./styles.scss";

const CustomerSearch = ({ items, onChange, disabled }) => {
  const stringSearch = async (toSearch) => {
    try {
      const options = items
        .filter((item) => item.name.toLowerCase().includes(toSearch))
        .map((item) => ({ label: item.name, value: item }));
      return options;
    } catch (e) {
      console.log(e);
    }
  };

  const loadOptions = (inputValue, callback) => {
    return new Promise((resolve) => {
      resolve(stringSearch(inputValue));
    });
  };
  const handleChange = async ({ value }) => {
    onChange(value);
  };

  return (
    <div className="hotel-selectbox-wrap">
      <Select
        cacheOptions
        loadOptions={loadOptions}
        defaultOptions
        onChange={handleChange}
        isDisabled={disabled}
      />
    </div>
  );
};

CustomerSearch.propTypes = {
  disabled: PropTypes.bool,
};

export default CustomerSearch;
