import React from "react";
import { Text, StyleSheet } from "@react-pdf/renderer";
import { getTimezoneOffsetRegion } from "src/app/common/utils/dateUtils";

var moment = require("moment-timezone");

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
    height: 24,
    fontSize: 12,
    fontStyle: "bold",
  },
  column: {
    flexDirection: "column",
    alignItems: "center",
  },
  text: {
    fontSize: 12,
    fontStyle: "bold",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  generatedDate: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 20,
    right: 0,
    textAlign: "left",
    color: "grey",
  },
});

const FolioFooter = ({ items }) => {
  return (
    <>
      <Text style={styles.generatedDate} fixed>
        Report generated on{" "}
        {moment().tz(getTimezoneOffsetRegion()).format("MM/DD/YYYY hh:mm")}
      </Text>
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) =>
          `${pageNumber} of ${totalPages}`
        }
        fixed
      />
    </>
  );
};

export default FolioFooter;
