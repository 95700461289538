import React from "react";
import { CancelIcon } from "../CancelIcon";
import "./styles.scss";
import PropTypes from "prop-types";

export const Toast = ({ message, activeClassName, onDismiss, isError }) => {
  return (
    <div
      className={`toast-wrapper ${isError && "error-state"} ${activeClassName}`}
    >
      <div className="toast-bg"></div>
      <span className="notification-text">{message || "Success!"}</span>
      <div className="cancel-icon-wrapper" onClick={onDismiss && onDismiss}>
        <CancelIcon />
      </div>
    </div>
  );
};

Toast.propTypes = {
  message: PropTypes.string,
  activeClassName: PropTypes.string,
  onDismiss: PropTypes.func,
  isError: PropTypes.bool,
};
