import React, { useState, useEffect } from "react";
import "./styles.scss";
import { faker } from "@faker-js/faker";
import moment from "moment";
import DataTable from "react-data-table-component";
import { DateRangePicker } from "src/app/components/DatePicker";
import searchIcon from "src/app/assets/images/searchIcon.svg";
import { Auth } from "aws-amplify";

const columns = [
  {
    name: "Date",
    selector: (row) => row.date,
    sortable: true,
  },
  {
    name: "Guest",
    selector: (row) => row.fullName,
    sortable: true,
  },
  {
    name: "Store",
    selector: (row) => row.store,
    sortable: true,
  },
  {
    name: "Promotion",
    selector: (row) => row.promotion,
    sortable: true,
  },
  {
    name: "Card",
    selector: (row) => row.card_number,
    sortable: true,
  },
  {
    name: <span className="data-table-total">Total</span>,
    selector: (row) => (
      <span className="data-table-total">
        {new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(row.payment)}
      </span>
    ),
    sortable: true,
  },
];

const CustomerList = React.memo(({ customersHistory }) => {
  const [user, setUser] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [restoreVisible, setRestoreVisible] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(0);
  const [selectedStore, setSelectedStore] = useState("All");

  const getUser = async () => {
    const user = await Auth.currentUserInfo();
    if (user && user.hasOwnProperty("id")) {
      setUser(user);
    } /*else {
      handleLogout();
    }*/
  };

  useEffect(() => {
    getUser();
    return () => {};
  }, []);

  useEffect(() => {
    if (customersHistory) {
      const dataSource = customersHistory.map((item, index) => ({
        avatar: faker.image.avatar(),
        promotion: item.description,
        date: moment(item.created_at).format("MM-DD-YYYY"),
        fullName: item.guest.guest_name,
        store: "Starbucks",
        hotel_id: 2,
        payment: item.amount,
        card_number:
          "●●●●●●●●●●●● " + item.guest.hold_transaction_lastfour + index,
      }));

      setDataSource(dataSource);
    }
  }, [customersHistory]);

  const onFinish = () => {
    const min = moment(startDate).unix();
    const max = moment(endDate).unix();

    let newDatasource = customersHistory.map((item, index) => ({
      avatar: faker.image.avatar(),
      promotion: item.description,
      date: moment(item.created_at).format("MM-DD-YYYY"),
      fullName: item.guest.guest_name,
      store: "Starbucks",
      hotel_id: 2,
      payment: item.amount,
      card_number:
        "●●●●●●●●●●●● " + item.guest.hold_transaction_lastfour + index,
    }));

    if (selectedProperty !== 0) {
      newDatasource = newDatasource.filter(
        (item) => item.hotel_id === selectedProperty
      );
    }

    if (selectedStore !== "All") {
      newDatasource = newDatasource.filter(
        (item) => item.store === selectedStore
      );
    }

    if (startDate && endDate) {
      newDatasource = newDatasource.filter((item) => {
        const itemDate = moment(item.date).unix();
        return itemDate >= min && itemDate <= max;
      });
    }

    setDataSource(newDatasource);
    setRestoreVisible(true);
  };

  const onRestore = () => {
    const dataSource = customersHistory.map((item, index) => ({
      avatar: faker.image.avatar(),
      promotion: item.description,
      date: moment(item.created_at).format("MM-DD-YYYY"),
      fullName: item.guest.guest_name,
      store: "Starbucks",
      hotel_id: 2,
      payment: item.amount,
      card_number:
        "●●●●●●●●●●●● " + item.guest.hold_transaction_lastfour + index,
    }));
    setSelectedProperty("All");
    setSelectedStore("All");
    setStartDate("");
    setEndDate("");
    setDataSource(dataSource);
    setRestoreVisible(false);
  };

  const onSearhGuest = (value) => {
    let newDatasource = customersHistory
      .map((item, index) => ({
        avatar: faker.image.avatar(),
        promotion: item.description,
        date: moment(item.created_at).format("MM-DD-YYYY"),
        fullName: item.guest.guest_name,
        store: "Starbucks",
        hotel_id: 2,
        payment: item.amount,
        card_number:
          "●●●●●●●●●●●● " + item.guest.hold_transaction_lastfour + index,
      }))
      .filter(
        (item) =>
          item.fullName.toUpperCase().indexOf(value.toUpperCase()) !== -1 ||
          item.card_number.split(" ")[1] === value
      );

    setDataSource(newDatasource);
  };

  return (
    <div className="app-dataset-wrapper">
      <h2 style={{ marginBottom: 8 }}>Sales History</h2>
      <p>
        Total Sales{" "}
        {new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(
          dataSource.reduce(
            (a, b) => a + parseFloat(b.payment.split("$").pop()),
            0
          )
        )}
      </p>
      <header className="app-dataset-header">
        <ul>
          <li>
            <span>Guest</span>
            <label className="app-dataset-searchinput" htmlFor="guest">
              <input
                //value={guest}
                name="guest"
                id="guest"
                onChange={(e) => onSearhGuest(e.target.value)}
              />
              <img src={searchIcon} alt="" />
            </label>
          </li>
          {user && user.attributes["custom:role"] === "root" ? (
            <li>
              <span>Property</span>
              <select
                value={selectedProperty}
                onChange={(e) => setSelectedProperty(e.target.value)}
              >
                <option value={0}>All</option>
                <option value={2}>Hollywood Hills Hotel</option>
                <option value={1}>Tuck Ventures</option>
              </select>
            </li>
          ) : null}
          <li>
            <span>Store</span>
            <select
              value={selectedStore}
              onChange={(e) => setSelectedStore(e.target.value)}
            >
              <option>All</option>
              <option>Apple</option>
              <option>McDonalds</option>
              <option>Starbucks</option>
            </select>
          </li>
          <li>
            <span>Start Date</span>
            <DateRangePicker
              format={"MM/dd/y"}
              calendarDate={startDate}
              getCalendarDate={(value) => setStartDate(new Date(value))}
              //minDate={new Date()}
            />
          </li>
          <li>
            <span>End Date</span>
            <DateRangePicker
              format={"MM/dd/y"}
              calendarDate={endDate}
              getCalendarDate={(value) => setEndDate(new Date(value))}
              //minDate={new Date()}
            />
          </li>
          <li>
            <button className="app-dataset-header-button" onClick={onFinish}>
              Apply
            </button>
          </li>
          {restoreVisible && (
            <li>
              <button
                className="app-dataset-header-restore-button"
                onClick={onRestore}
              >
                Restore
              </button>
            </li>
          )}
        </ul>
      </header>
      <DataTable
        columns={columns}
        data={dataSource}
        //onRowClicked={(row, e) => history.push("/marketing-booster/customer/" + row.id)}
        //progressPending={loading}
        /*         progressComponent={(
          <div className="customers-loader-cont">
            <Loader type="Oval" color="black" height={40} width={40} />
          </div>
        )} */
        pointerOnHover
        pagination
        paginationPerPage={10}
        paginationComponentOptions={{ noRowsPerPage: true }}
        style={{ border: "1px solid red" }}
        //highlightOnHover
        //striped
      />
    </div>
  );
});

export default CustomerList;
