import React, { useState, useReducer, useEffect, useRef } from "react";
import "./styles.scss";
import OverlayLoader from "./overlay-loader";
import General from "./general.section";
import Check from "./check.section";
import KeyHandling from "./key-handling";
import Cloudbeds from "./cloudbeds.section";
import Payments from "./payments.section";
import { AppButton } from "src/app/components/AppButton";
import { getCognitoUser } from "src/app/common/utils/sessionUtils";
import { useHistory } from "react-router-dom";

const mockData = {
  address: "4455 Paradise Rd. Las Vegas, NV 89169. Eastside.",
  amenity_fee: "5",
  api_user_key: "pk_test_51JS8QIHvwWJjbx2BBJp0zMbTtFC4JZM1Q",
  api_user_pwd: "pk_test_51JS8QIHvwWJjbx2BB",
  building_no: "5",
  checkin_time: "4:00pm",
  checkout_time: "4:00pm",
  city: "Las Vegas",
  city_tax: "5",
  cloudbeds: false,
  country: "United States",
  email: "hrch@mail.com",
  hold_amount: "5",
  late_checkout_amount: "5",
  late_checkout_notification_time: "5",
  late_checkout_time: "4:00pm",
  occupancy_tax: "5",
  phone: "+15551234555",
  property_name: "Hard Rock Cafe Hotel",
  release_days: "5",
  secret_key: "pk_test_51JS8QIHvwWJjbx2BB",
  send_key_automatically: false,
  send_key_time: "4:00pm",
  state: "Nevada",
  stripe_public_token: "pk_test_51JS8QIHvwWJjbx2BBJp0zMbT",
  stripe_secret_token: "pv0MkcoGCczx9AWYhFuZlOCrClPq1FzBQHvi",
  terms_and_conditions:
    "Aute nostrud qui cillum dolore nostrud do officia qui ipsum qui Lorem tempor qui proident.",
  zip_code: "01550",
  qr_master_account_id: "pk_test_51JS8QIHvwWJjbx2BB",
  qr_master_auth_token: "pv0MkcoGCczx9AWYhFuZlOCrC",
  qr_master_community_no: "052",
};

const initialValues = {
  property_name: "",
  email: "",
  phone: "",
  country: "",
  state: "",
  city: "",
  address: "",
  terms_and_conditions: "",
  checkin_time: "",
  checkout_time: "",
  late_checkout_time: "",
  cloudbeds: false,
  api_user_key: "",
  api_user_pwd: "",
  building_no: "",
  send_key_automatically: false,
  send_key_time: "",
  stripe_public_token: "",
  stripe_secret_token: "",
  hold_amount: "",
  release_days: "",
  late_checkout_amount: "",
  late_checkout_notification_time: "",
  city_tax: "",
  occupancy_tax: "",
  amenity_fee: "",
  zip_code: "",
  secret_key: "",
  qr_master_account_id: "",
  qr_master_auth_token: "",
  qr_master_community_no: "",
};

const valuesFormated = {
  hold_amount: "",
  late_checkout_amount: "",
  city_tax: "",
  occupancy_tax: "",
  amenity_fee: "",
};

const hourToNumber = (hour) => {
  const meridian = hour.match(/am|pm/i)[0];
  const removeMeridian = hour.split(meridian).shift();
  let result = parseFloat(removeMeridian.replace(":", "."));
  if (meridian === "pm") result += 12;
  return result;
};

const CreateProperty = ({ params }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [values, setValues] = useReducer(
    (current, next) => ({ ...current, ...next }),
    { ...initialValues, ...valuesFormated, ...mockData }
  );
  const [errors, setErrors] = useReducer(
    (current, next) => ({ ...current, ...next }),
    initialValues
  );
  const formRef = useRef(null);
  const history = useHistory();
  const [editable, setEditable] = useState(null);
  const { id } = params;

  useEffect(() => {
    if (id) {
      onInitialValues();
    }
    // eslint-disable-next-line
  }, [id]);

  const onInitialValues = async () => {
    try {
      setLoading(true);
      const cognitoUserInfo = await getCognitoUser();
      const rootEmail = cognitoUserInfo?.attributes?.email;

      const options = {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ rootEmail, property_id: id }),
        cors: true,
      };

      const url =
        "https://qlsy48lvrg.execute-api.us-east-1.amazonaws.com/dev/getPropertyById";
      const data = await fetch(url, options);
      const result = await data.json();

      setValues({
        ...result,
        cloudbeds: result.cloudbeds > 0 ? true : false,
        send_key_automatically:
          result.send_key_automatically > 0 ? true : false,
        stripe_secret_token: "",
        api_user_pwd: "",
        qr_master_auth_token: "",
      });
      setEditable(result);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const onChange = (e) => {
    const type = e.target.type;
    if (type === "checkbox") {
      setValues({ [e.target.id]: e.target.checked });
    } else {
      setValues({ [e.target.id]: e.target.value });
    }
  };

  const onFocus = (e) => {
    setErrors({ [e.target.id]: false });
  };

  const onValidate = () => {
    const requiredElements = formRef.current.querySelectorAll(
      "[data-required=true]"
    );
    const requiredValues = [];

    for (const el of requiredElements) requiredValues.push(el.id);

    for (const item of requiredValues) {
      if (!values[item]) {
        if (id) {
          if (
            item === "stripe_secret_token" ||
            item === "api_user_pwd" ||
            item === "qr_master_auth_token"
          ) {
            break;
          }
        }
        if (item) {
          setErrors({ [item]: "This field is required." });

          document
            .getElementById(item)
            .scrollIntoView({ block: "nearest", behavior: "smooth" });
          throw new Error("This field is required.");
        }
        break;
      }
      if (item === "email") {
        if (
          // eslint-disable-next-line
          !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values[item])
        ) {
          setErrors({ [item]: "Wrong email." });
          document
            .getElementById(item)
            .scrollIntoView({ block: "nearest", behavior: "smooth" });
          throw new Error("Wrong email.");
          // eslint-disable-next-line
          break;
        }
      }
      if (item === "phone") {
        if (
          // eslint-disable-next-line
          !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
            values[item]
          )
        ) {
          setErrors({ [item]: "Wrong phone" });
          document
            .getElementById(item)
            .scrollIntoView({ block: "nearest", behavior: "smooth" });
          throw new Error("Wrong phone");
          // eslint-disable-next-line
          break;
        }
      }
      if (
        item === "checkin_time" ||
        item === "checkout_time" ||
        item === "late_checkout_time"
      ) {
        if (
          // eslint-disable-next-line
          !/((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/.test(values[item])
        ) {
          setErrors({ [item]: "Wrong hour format (Eg.: 4:00 pm" });
          document
            .getElementById(item)
            .scrollIntoView({ block: "nearest", behavior: "smooth" });
          throw new Error("Wrong hour format (Eg.: 4:00 pm)");
        }
        const outTime = hourToNumber(values.checkout_time);
        const inTime = hourToNumber(values.checkin_time);
        const lateTime = hourToNumber(values.late_checkout_time);
        if (lateTime <= outTime) {
          setErrors({
            late_checkout_time:
              "Late check-out time must be greater than check-out time",
          });
          document
            .getElementById(item)
            .scrollIntoView({ block: "nearest", behavior: "smooth" });
          throw new Error(
            "Late check-out time must be greater than check-out time"
          );
        }
        if (inTime <= lateTime) {
          setErrors({
            checkin_time:
              "check-in time must be greater than late check-out time",
          });
          document
            .getElementById(item)
            .scrollIntoView({ block: "nearest", behavior: "smooth" });
          throw new Error(
            "check-in time must be greater than late check-out time"
          );
        }
        if (inTime <= outTime) {
          setErrors({
            checkin_time: "check-in time must be greater than check-out time",
          });
          document
            .getElementById(item)
            .scrollIntoView({ block: "nearest", behavior: "smooth" });
          throw new Error("check-in time must be greater than check-out time");
        }
      }
    }
  };

  const onCreate = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      onValidate();
      const cognitoUserInfo = await getCognitoUser();
      const rootEmail = cognitoUserInfo?.attributes?.email;
      let body = {};
      for (const key in values) if (values[key] !== "") body[key] = values[key];
      body = {
        ...body,
        send_key_automatically: body.send_key_automatically ? 1 : 0,
        send_key_time: body.send_key_automatically
          ? body.send_key_time
          : "4:00 pm",
        cloudbeds: body.cloudbeds ? 1 : 0,
        rootEmail,
      };
      // eslint-disable-next-line
      const options = {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(body),
      };
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const onUpdate = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      onValidate();
      const cognitoUserInfo = await getCognitoUser();
      const rootEmail = cognitoUserInfo?.attributes?.email;
      let body = {};
      for (const key in values) if (values[key] !== "") body[key] = values[key];
      body = {
        ...body,
        send_key_automatically: body.send_key_automatically ? 1 : 0,
        send_key_time: body.send_key_automatically
          ? body.send_key_time
          : "4:00 pm",
        cloudbeds: body.cloudbeds ? 1 : 0,
        stripe_secret_token: values.stripe_secret_token
          ? values.stripe_secret_token
          : editable.stripe_secret_token,
        api_user_pwd: values.api_user_pwd
          ? values.api_user_pwd
          : editable.api_user_pwd,
        qr_master_auth_token: values.qr_master_auth_token
          ? values.qr_master_auth_token
          : editable.qr_master_auth_token,
      };
      const options = {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          property_id: id,
          property: body,
          rootEmail,
        }),
      };

      const url =
        "https://qlsy48lvrg.execute-api.us-east-1.amazonaws.com/dev/updateProperty";
      const data = await fetch(url, options);
      // eslint-disable-next-line
      const result = await data.json();
      if (data.status === 200) {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          setValues({ ...initialValues, ...valuesFormated });
          setErrors(initialValues);
          history.push("/properties");
        }, 3000);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="right-wrapper">
      <div className="tabs-wrapper property-creation-cont">
        <OverlayLoader loading={loading} />
        <h1>Property creation</h1>
        <form
          className="property-creation-form"
          onSubmit={id ? onUpdate : onCreate}
          ref={formRef}
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "flex-start",
          }}
        >
          <General
            onChange={onChange}
            onFocus={onFocus}
            values={values}
            errors={errors}
          />
          <div style={{ width: "50%" }}>
            <Check
              onChange={onChange}
              onFocus={onFocus}
              values={values}
              errors={errors}
            />
            <Cloudbeds
              onChange={onChange}
              onFocus={onFocus}
              values={values}
              errors={errors}
              required={id ? false : true}
            />
          </div>
          <KeyHandling
            onChange={onChange}
            onFocus={onFocus}
            values={values}
            errors={errors}
          />
          <Payments
            onChange={onChange}
            onFocus={onFocus}
            values={values}
            errors={errors}
            required={id ? false : true}
          />
          <footer
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <AppButton type="submit" label={id ? "Edit" : "Add"} />
          </footer>
        </form>
      </div>
      <p data-visible={success} className="create-property-signup-success">
        Your property was successfully {id ? "edited." : "created."}
      </p>
    </div>
  );
};

export default CreateProperty;
