import React from "react";
import CurrencyInput from "react-currency-input-field";

const Check = ({ onChange, values, errors, onFocus }) => {
  return (
    <div
      className="property-creation-form-section"
      style={{ borderBottom: 0, marginBottom: 0 }}
    >
      <h2>Check-in/Check-out</h2>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            width: "100%",
            paddingRight: 32,
            display: "flex",
          }}
        >
          <div
            style={{
              width: "50%",
              paddingRight: 32,
            }}
          >
            <label
              htmlFor="checkout_time"
              className="property-creation-form-item"
            >
              <span data-required={true} id="checkout_time">
                Check-out time
              </span>
              <input
                type="text"
                id="checkout_time"
                name="checkout_time"
                placeholder="E.g.: 4:00 pm"
                onFocus={onFocus}
                onChange={onChange}
                value={values["checkout_time"]}
              />
              <p data-error={errors["checkout_time"] ? true : false}>
                {errors["checkout_time"]}
              </p>
            </label>
            <label
              htmlFor="late_checkout_time"
              className="property-creation-form-item"
            >
              <span data-required={true} id="late_checkout_time">
                Late check-out time
              </span>
              <input
                type="text"
                id="late_checkout_time"
                name="late_checkout_time"
                placeholder="E.g.: 4:00 pm"
                onFocus={onFocus}
                onChange={onChange}
                value={values["late_checkout_time"]}
              />
              <p data-error={errors["late_checkout_time"] ? true : false}>
                {errors["late_checkout_time"]}
              </p>
            </label>
          </div>
          <div
            style={{
              width: "50%",
              paddingLeft: 32,
            }}
          >
            <label
              htmlFor="checkin_time"
              className="property-creation-form-item"
            >
              <span data-required={true} id="checkin_time">
                Check-in time
              </span>
              <input
                type="text"
                id="checkin_time"
                name="checkin_time"
                placeholder="E.g.: 4:00 pm"
                onFocus={onFocus}
                onChange={onChange}
                value={values["checkin_time"]}
              />
              <p data-error={errors["checkin_time"] ? true : false}>
                {errors["checkin_time"]}
              </p>
            </label>
            <label
              htmlFor="late_checkout_amount"
              className="property-creation-form-item"
            >
              <span>Late check-out amount</span>
              <CurrencyInput
                id="late_checkout_amount"
                name="late_checkout_amount"
                className="property-creation-input-number"
                placeholder="$0.00"
                prefix="$"
                decimalSeparator="."
                groupSeparator=","
                value={values["late_checkout_amount"]}
                decimalsLimit={2}
                onValueChange={(value, name) =>
                  onChange({ target: { id: name, value } })
                }
              />

              <p data-error={errors["late_checkout_amount"] ? true : false}>
                {errors["late_checkout_amount"]}
              </p>
            </label>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            paddingRight: 96,
            display: "flex",
          }}
        >
          <label
            htmlFor="late_checkout_notification_time"
            className="property-creation-form-item"
            style={{ width: "60%" }}
          >
            <span data-required={true} id="late_checkout_notification_time">
              Late check-out reminder hours
            </span>
            <input
              type="number"
              className="property-creation-input-number"
              id="late_checkout_notification_time"
              name="late_checkout_notification_time"
              placeholder="E.g.: 5"
              onFocus={onFocus}
              onChange={onChange}
              value={values["late_checkout_notification_time"]}
            />
            <p
              data-error={
                errors["late_checkout_notification_time"] ? true : false
              }
            >
              {errors["late_checkout_notification_time"]}
            </p>
          </label>
        </div>
      </div>
    </div>
  );
};

export default Check;
